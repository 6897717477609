import {Fetcher} from "../utils/fetcher.ts";
import {initGemeente} from "../config/dexieLoader.ts";

export class GemeenteStore {
    private static _gmcode: string = "";

    /**
     * Get Gemeente code
     *
     * @return {string} Gemeente code
     */
    public static get gmcode(): string {
        return GemeenteStore._gmcode;
    }

    /**
     * Init Gemeente geo data
     *
     * @return {Promise<void>} Promise, reload page if new data was added
     */
    public static async initGemeenteGeoData(): Promise<void> {
        //init: true. New data was added
        //init: false. Data already exists
        initGemeente(GemeenteStore.gmcode).then(
            (init) => {
                if (init) {
                    //go to /gemeente
                    GemeenteStore.redirectToSelectGemeente();
                }
            }
        );
    }

    /**
     * Set Gemeente code, if invalid redirect to select gemeente page
     *
     * @param {string} value Gemeente code
     */
    public static setGmcode(value: string) {
        if (!GemeenteStore.validateGmcode(value)) {
            GemeenteStore.redirectToSelectGemeente();
            return;
        }
        GemeenteStore._gmcode = value;
    }

    /**
     * Unset Gemeente code
     */
    public static unsetGmcode() {
        GemeenteStore._gmcode = '';
    }

    /**
     * Get Gemeente naam, from API
     *
     * @return {Promise<string>} Gemeente naam
     */
    public static async getGemeenteNaam(): Promise<string> {
        // TODO document why this static getter 'getGemeenteNaam' is empty

        const fetcher = new Fetcher(true);
        ///utils/gemeentenaam?gmcode=GM0518
        const response = await fetcher.get("/utils/gemeentenaam?gmcode=" + GemeenteStore.gmcode);

        if (response.ok) {
            //@ts-ignore
            return response.data.name;
        } else {
            console.error("Error fetching gemeentenaam");
            return "Gemeente";
        }

    }

    /**
     * Validate Gemeente code
     *
     * @param gmcode Gemeente code
     * @private
     */
    private static validateGmcode(gmcode: string): boolean {
        if(!gmcode) return false;
        //string must be 6 characters long
        if(gmcode.length !== 6) return false;
        //string must start with GM
        if(!gmcode.startsWith('GM')) return false;
        //string must end with 4 digits
        if(isNaN(Number(gmcode.substring(2,4)))) return false;

        return true;
    }

    /**
     * Redirect to select gemeente page
     *
     * @private
     */
    private static redirectToSelectGemeente() {
        window.location.href = '/gemeente';
    }
}