<script setup lang="ts">
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'

import {useRouter, useRoute} from 'vue-router';
import {onMounted, ref, watch} from "vue";
import {AccountStore} from "../../../stores/accountStore.ts";

type Tab = {
  name: string,
  path: string,
  active: boolean,
  hasParam: boolean,
}

const router = useRouter();
const route = useRoute();

const username = ref(AccountStore.username);

const isClosed = ref(false);

const props = defineProps({
  tabs: {
    type: Array as () => Tab[],
    required: true
  },
  param: {
    type: String,
    required: false,
  },
});

watch(route, () => {
  props.tabs.forEach(tab => {
    tab.active = tab.path === route.path || tab.path + '/' + props.param === route.path;
  });
});

onMounted(() => {
  props.tabs.forEach(tab => {
    tab.active = tab.path === route.path || tab.path + '/' + props.param === route.path;
  });
});

const logout = () => {
  AccountStore.logout();
}

const openAccount = () => {
  router.push('/account');
}

const openTab = (tab: Tab) => {
  if (tab.hasParam) {
    router.push(tab.path + '/' + props.param);
    return;
  }
  router.push(tab.path);
}

</script>

<template>
  <div class="flex h-screen">
    <div class="flex-1">
      <Disclosure as="nav" class="bg-white shadow fixed top-0 inset-x-0 z-40 h-16" v-slot="{ open }">
        <div class="mx-auto print:hidden px-2 sm:px-6 lg:px-8">
          <div class="relative flex h-16 justify-between">
            <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton
                  class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                <span class="absolute -inset-0.5"/>
                <span class="sr-only">Open main menu</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true"/>
              </DisclosureButton>
            </div>
            <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <router-link to="/" class="flex flex-shrink-0 items-center">
                <img class="h-10 w-auto" src="../../../assets/woningbouwers_logo.svg" alt="Logo"/>
              </router-link>
              <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
                <div>
                  <slot name="extra-title"></slot>
                </div>
                <a
                    v-for="tab in props.tabs"
                    :key="tab.name"
                    :class="tab.active ? 'border-amber-500 text-gray-900 hover:cursor-pointer' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer'"
                    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                    @click="openTab(tab)"
                >
                  {{ tab.name }}
                </a>
              </div>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3">
                <div class="flex items-center ">

                  <MenuButton
                      class="relative flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">
                    <span class="absolute -inset-1.5"/>
                    <span class="sr-only">Open user menu</span>
                    <span class="ml-1 mr-3 hidden md:block sentry-mask">{{ username }}</span>
                    <div
                        class="h-8 w-8 rounded-full overflow-hidden flex justify-center items-end bg-stone-300 border border-1 border-stone-400">
                      <svg class="h-7 w-7 fill-white" xmlns="http://www.w3.org/2000/svg" height="1em"
                           viewBox="0 0 448 512">
                        <path
                            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                      </svg>
                    </div>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-200"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                      class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem @click="openAccount" v-slot="{ active }">
                      <button class="w-full text-start"
                              :class="[active ? 'bg-gray-100 hover:cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer']">
                        Mijn account
                      </button>
                    </MenuItem>
                    <hr>
                    <MenuItem @click="logout" v-slot="{ active }">
                      <button class="w-full text-start"
                              :class="[active ? 'bg-gray-100 hover:cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer']">
                        Log uit
                      </button>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
        <div class="screen-warning print:hidden" v-show="!isClosed">
          Deze pagina werkt niet goed op mobiele apparaten en kleine schermen. <br> Maak je venster groter of bekijk de
          pagina op een desktop.
          <div class="close" @click="isClosed = true">✕</div>
        </div>

        <DisclosurePanel class="print:hidden sm:hidden">
          <div class="space-y-1 pb-4 pt-2 bg-white shadow">
            <DisclosureButton
                v-for="tab in tabs"
                :key="tab.name"
                :class="tab.active ? 'border-amber-500 bg-amber-50 text-amber-700 hover:cursor-pointer' : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 hover:cursor-pointer'"
                class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                as="a"
                @click="router.push(tab.path)"
            >
              {{ tab.name }}
            </DisclosureButton>
          </div>
        </DisclosurePanel>

      </Disclosure>

      <div class="pt-16 h-full flex-1">
        <slot></slot>
      </div>
    </div>
    <footer class="bg-gray-900 text-white px-8 fixed bottom-0 inset-x-0 z-40 h-16 flex items-center print:hidden">
      <p>© {{ new Date().getFullYear() }} - WoningbouwersNL Data Dashboard | Xitres Data</p>
    </footer>
  </div>

</template>


<style lang="scss" scoped>
@media screen and (min-width: 1100px) {
  .screen-warning {
    display: none;
  }

}

.screen-warning {
  position: relative;
  z-index: 100;
  //make it sop it looks like an alert on top of the page, the user is allowed to use the page
  background-color: #e13838;
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 0.8rem;

  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
</style>