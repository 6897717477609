const component = {
    type: 'Highcharts',
    cell: 'nm-nieuwbouwverkopen-naar-woningtype',
    connector: {
        id: 'verkopen_naar_woningtype_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        appartementen: 'appartementen',
        eengezinswoningen: 'eengezinswoningen',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Nieuwbouwverkopen naar woningtype'
    },
    chartOptions: {
        chart: {
            //type: 'bar',
            type: 'column',
        },
        plotOptions: {
            series: {
                //pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 60*60*24*365, // jaar?

            labels: {
                format: '{value:%Y}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: '%Y',
            shared: true
        }
    }
};

export default component;