import {Parsers} from "../../utils/parsers.ts";
import {baseUri} from "../../config/config.ts";

export default [
    {
        type: 'JSONAuth',
        id: 'quickfacts_1_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts?fact=1')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_2_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts?fact=2')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_3_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts?fact=3')
        }
    }, {
        type: 'JSONAuth',
        id: 'quickfacts_4_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts?fact=4')
        }
    }, {
        type: 'JSONAuth',
        id: 'quickfacts_nbo_1_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts-nbo?fact=1')
        }
    }, {
        type: 'JSONAuth',
        id: 'quickfacts_nbo_2_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/quickfacts-nbo?fact=2')
        }
    }, {
        type: 'JSONAuth',
        id: 'projecten_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/projecten')
        }
    }, {
        type: 'JSONAuth',
        id: 'verkopen_per_provincie_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/verkopen-per-provincie'),
            beforeParse: function (dataset: any) {
                let columnHeaders = dataset[0];
                columnHeaders = columnHeaders.map((columnHeader:string) => {
                    return columnHeader.replace('jaar_','');
                });
                dataset[0] = columnHeaders;
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'woningtype',
                    minValue: 'Appartement',
                    maxValue: 'Appartement'
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'verkopen_naar_woningtype_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/verkopen-naar-woningtype'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'bouwvergunningen_pool',
        options: {
            dataUrl: (baseUri + '/charts/nm/bouwvergunningen'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'huidig_aanbod_naar_woningtype',
        options: {
            dataUrl: (baseUri + '/charts/nm/huidig-aanbod-naar-woningtype'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'huidig_aanbod_naar_projectgrootte',
        options: {
            dataUrl: (baseUri + '/charts/nm/huidig-aanbod-naar-projectgrootte'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'huidig_aanbod_naar_prijscategorie',
        options: {
            dataUrl: (baseUri + '/charts/nm/huidig-aanbod-naar-prijscategorie'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'verkooptijden_verkochte_appartementen',
        options: {
            dataUrl: (baseUri + '/charts/nm/verkooptijden-verkochte-appartementen'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'verkooptijden_verkochte_eengezinswoningen',
        options: {
            dataUrl: (baseUri + '/charts/nm/verkooptijden-verkochte-eengezinswoningen'),
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    }
];