<script setup lang="ts">
import {datapoolToObjectKeyValueArray} from "../utils/mapHelper.ts";
import {useTooltipStore} from "../stores/pinia/tooltipStore.ts";
import {onMounted, reactive, ref} from "vue";

const tooltipStore = useTooltipStore()

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    connectors: {
        type: Object,
        required: true
    },
    tableOptions: {
        type: Object,
        required: true,
        default: () => {
            return {
                title: "KPI",
                th: 'datum_tekst',
                td: 'waarde',
                reverse: false,
                valueFormatter: (value: any) => {
                    return value
                },
                size: 'base',
                align: 'end'
            }
        }
    }
});

const keyFormatter = (key: any) => {
    if (props.tableOptions.keyFormatter !== undefined) {
        return props.tableOptions.keyFormatter(key)
    } else {
        return key
    }
}

const valueFormatter = (value: any) => {
    if (props.tableOptions.valueFormatter) {
        return props.tableOptions.valueFormatter(value)
    } else {
        return value.toLocaleString('nl-NL')
    }
}

const dataRaw = datapoolToObjectKeyValueArray(props.connectors.table.columns);

if (props.tableOptions.reverse) {
    dataRaw.reverse()
}

const data = structuredClone(dataRaw);

data.forEach((item: any) => {
    item[props.tableOptions.td] = valueFormatter(item[props.tableOptions.td])
})

const componentTitle = ref(null)


const state = reactive({
    counts: data.map(() => 0),
    // @ts-ignore
    targetValues: data.map(item => item[props.tableOptions.td])
})


const startAnimation = () => {
    const duration = 100; // Niet in milliseconden
    const interval = 3; // Interval in milliseconden
    const steps = duration / interval; // Aantal stappen

    // Sla de originele Nederlandse eindwaarden op
    const originalTargetValues = [...state.targetValues];

    // Converteer Nederlandse getallen naar internationale getallen voor de berekening
    const targetValuesInternational = state.targetValues.map(val =>
        parseFloat(val.toString().replace(/\./g, '').replace(/,/g, '.'))
    );

    const stepSize = targetValuesInternational.map(target => (target / steps)); // Stapgrootte voor elke count

    //set size must be at least 1
    stepSize.forEach((item, index) => {
        if (item < 1) {
            stepSize[index] = 1
        }
    })



    const intervalId = setInterval(() => {
        let allReached = true;

        for (let i = 0; i < state.counts.length; i++) {
            if (state.counts[i] < targetValuesInternational[i]) {
                state.counts[i] += stepSize[i]; // Verhoog de count met de stapgrootte

                // Afronden, afhankelijk van of de eindwaarde een decimaal heeft
                if (targetValuesInternational[i] % 1 !== 0) {
                    state.counts[i] = parseFloat(state.counts[i].toFixed(2)); // Rond af op twee decimalen
                } else {
                    state.counts[i] = Math.round(state.counts[i]); // Rond af op hele getallen
                }

                if (state.counts[i] < targetValuesInternational[i]) {
                    allReached = false;
                }
            }
        }

        if (allReached) {
            clearInterval(intervalId);
            // Zet de final values terug naar de originele Nederlandse targetValues
            state.counts = originalTargetValues;
        }
    }, interval);
}


onMounted(() => {
    if (componentTitle.value !== null) {
        tooltipStore.setupTooltipDom(componentTitle.value, props.id)
    }

    startAnimation()
})


</script>

<template>
    <div class="flex flex-0 flex-col justify-between h-full">
        <h2 class="highcharts-dashboards-component-title flex justify-between items-center mt-4" ref="componentTitle">
            {{ props.tableOptions.title }}
        </h2>
        <div class="quickfact-container" :class="'text-' + (props.tableOptions.size ?? 'base') + ' justify-' + (props.tableOptions.align ?? 'end')">
            <div v-for="(item, index) in data" class="quickfact-row">
                <span class="quickfact-key">{{ keyFormatter(item[props.tableOptions.th]) }}</span>
                <span class="quickfact-value">{{ state.counts[index] }}</span>
            </div>
        </div>

    </div>

</template>

<style lang="scss" scoped>

.quickfact-key {
    font-weight: 500;
    font-size: .8em;
    opacity: .8;
}

.quickfact-value {
    line-height: 1;
    font-size: 1.6em;
    font-weight: 500;
    //mono font
    font-family: 'Roboto Mono', monospace;
}

.quickfact-row {
    display: flex;
    flex-flow: column;
    &:nth-of-type(1) {
        color: darken(#89c7cf, 30%);
    }
    &:nth-of-type(2) {
        color: darken(#ed6f36, 15%);
    }
}

.quickfact-container {
    flex-grow: 1;

    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin: 0 1rem 1rem;
}

</style>