import {createVNode, render} from "vue";
import QuickfactVueComponent from "../components/QuickFact.vue";

const {
    Component,
    ComponentRegistry,
} = Dashboards;

class QuickfactComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        this.initConnector().then(() => {
            this.load();
        });
        this.type = 'Quickfact';
        this.qfElement = document.createElement('div');

        this.options = options;
        this.isDataReady = false;

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
        });

        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(QuickfactVueComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.qfElement.setAttribute('style', 'height: ' + (height - 10) + 'px; width: ' + (width - 10) + 'px;');
    }

    load() {
        return new Promise((resolve, reject) => {
            this.cell.setLoadingState(true);
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {
                super.load()
                    .then(() => {
                        this.renderComponent(this.qfElement, {
                            id: this.options.cell,
                            connectors: this.connector,
                            tableOptions: this.options.tableOptions,
                        });


                        this.contentElement.appendChild(this.qfElement);
                        this.parentElement.appendChild(this.element);

                        this.cell.setLoadingState(false);
                    })

                resolve(this);
            } catch (error) {
                reject(error);
            }
        })
    }
}

ComponentRegistry.registerComponent('Quickfact', QuickfactComponent);