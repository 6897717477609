import { createVNode, render } from 'vue';
import VueRangeComponent from '../components/controls/Range.vue';

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class DateRangeFilterComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        this.type = 'DateRangeFilter';
        this.filterElement = document.createElement('div');
        this.filterElement.setAttribute('class', 'print:hidden');

        this.isDataReady = false;

        if (options.filterOptions === undefined) {
            console.error('Filter component has no filterOptions')
        }

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueRangeComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }



    load() {
        return new Promise((resolve, reject) => {
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {
                super.load()
                    .then(() => {

                        const filterColumn = this.options.filterOptions.column;

                        const setModifierOptions = (dataPool,
                                                    min,
                                                    max,
                                                    column = filterColumn,
                                                    type = 'Range') => {
                            dataPool.setModifierOptions({
                                type: type,
                                ranges: [{
                                    column: column,
                                    minValue: min,
                                    maxValue: max
                                }]
                            });
                        }

                        let loadFailed = false

                        const values = [];
                        this.options.filterOptions.dataPools.forEach(name => {
                            const dataPool = this.board.dataPool.connectors[name];
                            if(dataPool) {
                                values.push(...dataPool.table.columns[filterColumn])
                            } else {
                                loadFailed = true
                                console.error('datapool: ' + name + ' niet geladen!')
                            }
                        })


                        if(!loadFailed) {
                            const min = Math.min(...values)
                            const max = Math.max(...values)

                            let selected = [min, max]

                            if (this.options.filterOptions.minSelected !== undefined) {
                                const minSelectedDate = new Date(this.options.filterOptions.minSelected);
                                selected[0] = minSelectedDate.getTime()
                            }

                            if (this.options.filterOptions.maxSelected !== undefined) {
                                const maxSelectedDate = new Date(this.options.filterOptions.maxSelected);
                                selected[1] = maxSelectedDate.getTime()
                            }

                            if (selected[0] < min) selected[0] = min
                            if (selected[1] > max) selected[1] = max


                            this.renderComponent(this.filterElement, {
                                min: min,
                                max: max,
                                selected: selected,
                                merge: ((max - min) / 4),
                                step: -1, // vrije range
                                format: (v) => {
                                    return new Date(v).toISOString().split('T')[0]
                                }
                            });

                            if (selected[0] !== min || selected[1] !== max) {
                                this.options.filterOptions.dataPools.forEach(name => {
                                    setModifierOptions(
                                        this.board.dataPool.connectors[name],
                                        selected[0],
                                        selected[1]
                                    )
                                })
                            }

                            this.filterElement._vnode.props.onUpdate = (newValue) => {
                                this.options.filterOptions.dataPools.forEach(name => {
                                    setModifierOptions(
                                        this.board.dataPool.connectors[name],
                                        newValue.min,
                                        newValue.max
                                    )
                                })
                            };

                            this.contentElement.appendChild(this.filterElement);
                            this.parentElement.appendChild(this.element);
                        }

                    })

                resolve(this);
            } catch (error) {
                reject(error);
            }
        })

    }
}

ComponentRegistry.registerComponent('DateRangeFilter', DateRangeFilterComponent);