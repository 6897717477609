import quickfact1Chart from './components/quickfact-1.chart.ts';
import quickfact2Chart from './components/quickfact-2.chart.ts';
import quickfact3Chart from './components/quickfact-3.chart.ts';
import quickfact4Chart from './components/quickfact-4.chart.ts';
import quickfactNbo1Chart from './components/quickfact-nbo-1.chart.ts';
import quickfactNbo2Chart from './components/quickfact-nbo-2.chart.ts';
import verkopenPerProvincie from './components/verkopen-per-provincie.chart.ts';
import verkopenPerProvincieFilter from './components/verkopen-per-provincie.filter.ts';
import nieuwbouwVerkopenNaarWoningtype from './components/nieuwbouwverkopen-naar-woningtype.chart.ts';
import kaartHuidigAanbod from './components/kaart-huidig-aanbod.chart.ts';
import bouwvergunningen from './components/bouwvergunningen.chart.ts';
import bouwvergunningenFilter from './components/bouwvergunningen.filter.ts';
import huidigAanbodNaarWoningtype from './components/huidig-aanbod-naar-woningtype.ts';
import huidigAanbodNaarProjectgrotte from './components/huidig-aanbod-naar-projectgrootte.ts';
import huidigAanbodNaarPrijscategorie from './components/huidig-aanbod-naar-prijscategorie.ts';
import verkooptijdenVerkochteAppartementen from './components/verkooptijden-verkochte-appartementen.ts';
import verkooptijdenVerkochteEengezinswoningen from './components/verkooptijden-verkochte-eengezinswoningen.ts';

/**
 * This function will return all charts, so you don't need to import them on the page itself.
 * Its just one step less work.
 *
 * @returns {object} All charts
 */
export const importAll = () => {
    return [
        quickfact1Chart,
        quickfact2Chart,
        quickfact3Chart,
        quickfact4Chart,
        quickfactNbo1Chart,
        quickfactNbo2Chart,
        verkopenPerProvincie,
        verkopenPerProvincieFilter,
        nieuwbouwVerkopenNaarWoningtype,
        kaartHuidigAanbod,
        bouwvergunningen,
        bouwvergunningenFilter,
        huidigAanbodNaarWoningtype,
        huidigAanbodNaarProjectgrotte,
        huidigAanbodNaarPrijscategorie,
        verkooptijdenVerkochteAppartementen,
        verkooptijdenVerkochteEengezinswoningen
    ]
}