const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woz-waarde-buurt',
    connector: {
        id: 'woz_naar_buurten_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'woz': 'y',
        'nederland gemiddelde': 'y',
        'datum': 'x'
    },
    title: {
        useHTML: true,
        text: 'WOZ-waarde buurt'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime',
            //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', "test", "test", "test"]
        },
        yAxis: {
            stackLabels: {
                enabled: false
            }
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'percent',
                dataLabels: {
                    enabled: false
                }
            }
        },

    }
};

export default component;