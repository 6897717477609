import { createVNode, render } from 'vue';
import VueRangeComponent from '../components/controls/Range.vue';
// utils
import { DateHelpers } from '../utils/dateHelpers.ts'

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class YearRangeFilterComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        this.type = 'YearRangeFilter';
        this.filterElement = document.createElement('div');
        this.filterElement.setAttribute('class', 'print:hidden');

        this.isDataReady = false;

        if (options.filterOptions === undefined) {
            console.error('Filter component has no filterOptions')
        }

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueRangeComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }



    load() {
        return new Promise((resolve, reject) => {
            try {
                super.load();

                if (!this.isDataReady) {
                    return;
                }

                const filterColumn = this.options.filterOptions.column;

                const setModifierOptions = (dataPool,
                                            min,
                                            max,
                                            column = filterColumn,
                                            type = 'Range') => {
                    dataPool.setModifierOptions({
                        type: type,
                        ranges: [{
                            column: column,
                            minValue: min,
                            maxValue: max
                        }]
                    });
                }


                const values = [];
                this.options.filterOptions.dataPools.forEach(name => {
                    const dataPool = this.board.dataPool.connectors[name];
                    if(dataPool) {
                        values.push(...(dataPool.table.columns[filterColumn].map(
                            (timestamp) => new Date(timestamp).getFullYear()
                        )))
                    } else {
                        console.error('datapool: ' + name + ' niet geladen!')
                    }
                })

                const min = Math.min(...values)
                const max = Math.max(...values)

                this.renderComponent(this.filterElement, {
                    min: min,
                    max: max,
                    selected: [min, max],
                });

                this.filterElement._vnode.props.onUpdate = (newValue) => {
                    this.options.filterOptions.dataPools.forEach(name => {
                        setModifierOptions(
                            this.board.dataPool.connectors[name],
                            DateHelpers.getDateFromYMD(newValue.min).getTime(),
                            DateHelpers.getDateFromYMD(newValue.max, 11, 31).getTime()
                        )
                    })
                };

                this.contentElement.appendChild(this.filterElement);
                this.parentElement.appendChild(this.element);


                resolve(this);
            } catch (error) {
                reject(error);
            }
        });

    }
}

ComponentRegistry.registerComponent('YearRangeFilter', YearRangeFilterComponent);