const component = {
    cell: 'wm-landkaart-tekort-overschot-filter',
    className: 'test2',
    type: 'SelectFilter',
    label: 'Jaar:',
    filterOptions: {
        filters: [
            {
                key: 2023,
                label: '2023'
            },
            {
                key: 2025,
                label: '2025'
            }
        ],
        dataPools: ['woningtekort_overschot_pool'],
        column: 'jaar'
    }
};

export default component;