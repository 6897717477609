import quickfact1Chart from './components/quickfact-1.chart.ts';
import quickfact2Chart from './components/quickfact-2.chart.ts';
import quickfact3Chart from './components/quickfact-3.chart.ts';
import bbpWerkloosheid from './components/bbp-werkloosheid.chart.ts';
import bbpWerkloosheidFilter from './components/bbp-werkloosheid.filter.ts';
import consumentenWoningmarktVertrouwen from './components/consumenten-woningmarkt-vertrouwen.chart.ts';
import consumentenWoningmarktVertrouwenFilter from './components/consumenten-woningmarkt-vertrouwen.filter.ts';
import woninghypothekenStaatslening from './components/woninghypotheken-staatslening.chart.ts';
import woninghypothekenStaatsleningFilter from './components/woninghypotheken-staatslening.filter.ts';

export const importAll = () => {
    return [
        quickfact1Chart,
        quickfact2Chart,
        quickfact3Chart,
        bbpWerkloosheid,
        bbpWerkloosheidFilter,
        consumentenWoningmarktVertrouwen,
        consumentenWoningmarktVertrouwenFilter,
        woninghypothekenStaatslening,
        woninghypothekenStaatsleningFilter
    ]
}
