import { createVNode, render } from 'vue';
import VueMultiSelectComponent from '../components/controls/MultiSelect.vue';

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class SelectFilterComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        this.type = 'SelectFilter';
        this.filterElement = document.createElement('div');
        this.filterElement.setAttribute('class', 'print:hidden');

        this.isDataReady = false;

        if (options.filterOptions === undefined) {
            console.error('Filter component has no filterOptions')
        }

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueMultiSelectComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }



    load() {
        return new Promise((resolve, reject) => {
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {
                super.load()
                    .then(() => {
                        this.renderComponent(this.filterElement, {
                            label: this.options.label,
                            options: this.options.filterOptions.filters,
                            enableApi: this.options.filterOptions.enableApi,
                            apiOptions: this.options.filterOptions.apiOptions,
                        });

                        this.filterElement._vnode.props.onUpdate = async (newValue) => {
                            for (const dataPool of this.options.filterOptions.dataPools) {
                                const connector = this.board.dataPool.connectors[dataPool];


                                if (newValue === 'SHOWALL') {
                                    connector.setModifierOptions(); // empty
                                    return;
                                }

                                let modifiers = [];
                                // TODO:
                                //  For all selected items, add modifier to modifiers
                                modifiers.push({
                                    type: 'Range',
                                    ranges: [{
                                        column: this.options.filterOptions.column,
                                        minValue: newValue,
                                        maxValue: newValue
                                    }]
                                });

                                // Set all modifiers
                                connector.setModifierOptions(...modifiers);
                            }
                        };

                        this.contentElement.appendChild(this.filterElement);
                        this.parentElement.appendChild(this.element);

                    })
                resolve(this);
            } catch (error) {
                reject(error);
            }
        })

    }
}

ComponentRegistry.registerComponent('MultiSelectFilter', SelectFilterComponent);