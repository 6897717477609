export default {
    layouts: [{
        id: 'layout-1',
        rows: [
            //1e rij (4 KPI's)
            {
                id: 'row-1',
                cells: [
                    {
                        id: 'wm-quickfact-1',
                    }, {
                        id: 'wm-quickfact-2',
                    }, {
                        id: 'wm-quickfact-3',
                    }, {
                        id: 'wm-quickfact-4',
                    }]
            },
            //2e rij (2 grote charts)
            {
                id: 'row-2',
                cells: [
                    {
                        id: 'wm-verkochte-woningen-filter',
                        width: '100%', //100% Omdat deze dan de volledige breedte van de pagina inneemt (anders komt 2e chart er naast)
                        height: '70px'
                    },
                    {
                        id: 'wm-verkochte-woningen',
                        width: '50%',
                        height: '560px'
                    },
                    {
                        id: 'wm-gemiddelde-verkoopprijs',
                        width: '50%',
                        height: '560px'
                    }
                ]
            },
            //3e rij (3x landkaart nederland)
            {
                id: 'row-3',
                cells: [
                    {
                        id: 'wm-landkaart-tekort-overschot-filter',
                        width: '100%',
                        height: '70px'
                    },
                    {
                        id: 'wm-landkaart-tekort-overschot',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-landkaart-bevolkingsgroei',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-landkaart-nieuwbouw-cumulatief',
                        width: '33.3%',
                        height: '560px'
                    }
                ]
            },
            //4e rij (3x linechart)
            {
                id: 'row-4',
                cells: [
                    {
                        id: 'wm-vraag-en-aanbod',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-modaal-inkomen',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-hoog-inkomen',
                        width: '33.3%',
                        height: '560px'
                    }
                ]
            },
            //6e rij (2x linechart, 1x bar chart)
            {
                id: 'row-5',
                cells: [

                        {width:'66.6%'}, // Empty cells. Push east
                        {
                            id: 'wm-bevolkingsgroei-ytd-filter',
                            width: '33.3%',
                            height: '70px'
                        },
                    {
                        id: 'wm-krapte-indicator',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-verkooptijd',
                        width: '33.3%',
                        height: '560px'
                    },
                    {
                        id: 'wm-bevolkingsgroei-ytd',
                        width: '33.3%',
                        height: '560px'
                    }
                ]
            },
        ]
    }]
}
