<script setup lang="ts">
import {onMounted, Ref, ref} from "vue";
import { AccountStore } from "../../../stores/accountStore.ts";
import { useRouter } from "vue-router";
import {Fetcher, FetcherResponse} from "../../../utils/fetcher.ts";
//@ts-ignore
import Loader from 'vue-spinner/src/DotLoader.vue';

const router = useRouter();
const fetcher = new Fetcher(true);

const rawMails = ref('');

const importDone = ref(false);
const success = ref(false);
const sucessMessage = ref('');

const loading = ref(false);

const form: Ref<{
  organisation_id: number;
  emails: string[];
}> = ref({
  organisation_id: 2, //Is woningbouwersNL
  emails: [],
});

onMounted(async () => {
  const access: number = await AccountStore.getAccessLevel();
  if (access < 100) {
    await router.push('/404');
  }
});

const updateEmails = () => {
  form.value.emails = rawMails.value.split('\n').map((email) => {
    return email.trim();
  })

  //split also on comma
  form.value.emails = form.value.emails.flatMap((email) => {
    return email.split(',');
  });

  //Remove empty lines
  form.value.emails = form.value.emails.filter((email:string) => {
    return email.length > 0;
  });

  //filter duplicates
  form.value.emails = [...new Set(form.value.emails)];

  if (rawMails.value.length < 1) {
    form.value.emails = [];
  }
}

const isEmail = (email: string) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}


const doImport = async () => {
  loading.value = true;
  //filter out invalid emails
  form.value.emails = form.value.emails.filter((email) => {
    return isEmail(email);
  });

  const response: FetcherResponse<{
    message: string;
  }> = await fetcher.post('/auth/import', form.value);

  loading.value = false;
  if (response.ok) {
    importDone.value = true;
    success.value = true;
    sucessMessage.value = response.data.message;
  } else {
    importDone.value = true;
    success.value = false;
    sucessMessage.value = 'Er is iets fout gegaan';
  }

  rawMails.value = '';
  form.value.emails = [];

}

</script>

<template>
  <div class="flex flex-col flex-1">
    <h1 class="text-3xl font-semibold text-slate-800 ">
      Importeer een CSV lijst
    </h1>
    <span class="mt-4 mb-2 text-sm text-slate-600">
      Alle gebruikers die in de lijst staan worden toegevoegd aan de organisatie <span class="font-bold">WoningbouwersNL</span>
    </span>

    <template v-if="importDone">
      <template v-if="success">
        <div class="flex flex-col mt-4">
          <div class="px-4 py-2 mb-2 text-emerald-700 bg-emerald-200 rounded-md border-l-4 border-emerald-500">
            <span class="font-semibold">
              {{ sucessMessage }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col mt-4">
          <div class="px-4 py-2 mb-2 text-red-700 bg-red-200 rounded-md border-l-4 border-red-500">
            <span class="font-semibold">
              {{ sucessMessage }}
            </span>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <button type="button" class="inline-block px-2 py-2 my-3 text-white rounded-md text-sm bg-slate-400 hover:bg-slate-500" :disabled="loading" :class="{'opacity-50 cursor-not-allowed': loading}" @click="doImport">Importeer</button>
    </template>


    <!-- 2 column (left multi line) right result-->
    <div class="flex" v-if="!loading">
      <div class="w-1/2">
        <textarea class="w-full h-96 p-4 text-sm text-slate-800 bg-white border border-slate-200 rounded-md resize-none" placeholder="Voer hier de CSV lijst in" v-model="rawMails" @input.prevent="updateEmails"></textarea>
      </div>
      <div class="w-1/2">
        <!-- table -->
        <div class="px-2 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-20">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Email</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <template v-if="form.emails.length > 0">
                  <tr v-for="email in form.emails" :key="email">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {{ email }}
                    </td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <template v-if="isEmail(email)">
                        <span class="px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">Toegevoegd</span>
                      </template>
                      <template v-else>
                        <span class="px-2 py-1 text-xs font-semibold text-red-800 bg-red-100 rounded-full">Ongeldig</span>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="2" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Geen emails ingevoerd
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="flex justify-center mt-10">
        <Loader color="#314361" size="100px" margin="50px" radius="100px" loading="loading"></Loader>
      </div>
    </div>


  </div>
</template>

<style scoped>
/* Stijlen voor de wachtwoordmeter */
.password-meter {
  height: 10px;
  width: 100%;
  background-color: #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
}

.password-meter-fill {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}
</style>