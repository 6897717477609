const component = {
    cell: 'nm-quickfact-nbo-1',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_nbo_1_pool',
    },
    className: 'highcharts-dashboards-component quickfact',
    title: false,
    tableOptions: {
        title: 'Verkocht / Te koop - Huidig aanbod',
        alternateColor: false,
        th: 'tekst',
        td: 'waarde',
        reverse: true,
        align: 'center',
        size: 'xl',
        valueFormatter: (value: number) => {return value.toLocaleString('nl-NL')},
    }
};

export default component;