const component = {
    cell: 'wm-hoog-inkomen',
    connector: {
        id: 'kopen_hoog_inkomen_pool'
    },
    columnAssignment: {
        datum: 'x',
        'leencapaciteit bij 2 x modaal': 'y',
        'leencapaciteit bij 3 x modaal': 'y',
        'gemiddelde verkoopprijs 2 onder 1 kapwoning': 'y',
        'gemiddelde verkoopprijs vrijstaande woning': 'y'
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Wat kan je kopen met een hoog inkomen?'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            labels: {
                formatter: function (): string {
                    // @ts-ignore
                    return '€ ' + this.axis.defaultLabelFormatter.call(this);
                }
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true,
            valuePrefix: '€ ',
        }
    }
};

export default component;