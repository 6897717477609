const component = {
    cell: 'ec-consumenten-woningmarkt-vertrouwen-filter',
    className: '',
    type: 'MonthRangeFilter',
    filterOptions: {
        dataPools: ['consumenten_woningmarkt_vertrouwen_pool'],
        minSelected: '2010-01-01',
        column: 'datum'
    }
};

export default component;