export const DateHelpers = {
    /**
     * get date object from ymd params
     * @param year
     * @param month
     * @param day
     */
    getDateFromYMD(year: number, month: number = 0, day: number = 1): any {
        return new Date(year, month, day);
    },

    getMonthName(index: number) {
        const months = ["januari", "februari", "maart", "april", "mei", "juni",
            "juli", "augustus", "september", "oktober", "november", "december"];

        return months[index]
    },

    getDateObjectByMonthFilter(value: number): any {
        const year = Math.floor(value / 12);
        const month = value % 12;

        return this.getDateFromYMD(year, month);
    },

    getDateObjectByQuarterFilter(value: number): any {
        const year = Math.floor(value / 4);
        const quarter = value % 4;

        return this.getDateFromYMD(year, (quarter * 3));
    },

    getWeekFromTimestamp(value: number): any {
        const date = new Date(value),
            day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();
        date.setDate(date.getUTCDate() + 4 - day);

        return (date: Date): number => {
            const dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6).getTime()) / 86400000);
            return 1 + Math.floor(dayNumber / 7);
        }
    },
    getQuarterFromTimestamp(value: number): any {
        const date = new Date(value);
        const month = date.getMonth() + 1; // getMonth() returns 0-indexed months (0-Jan, 1-Feb, ...)
        return Math.ceil(month / 3);
    }
}

export const generatePassword = (length: number = 12, mustUpper: boolean = true, mustLower: boolean = true, mustNumber: boolean = true, mustSpecial: boolean = true): string => {
    let result = '';

    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lower = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const special = '!@#$&?';

    const maxSpecial = 2;

    while (result.length < length) {
        const random = Math.floor(Math.random() * 4);
        if (random === 0 && mustUpper) {
            result += upper[Math.floor(Math.random() * upper.length)];
        } else if (random === 1 && mustLower) {
            result += lower[Math.floor(Math.random() * lower.length)];
        } else if (random === 2 && mustNumber) {
            result += numbers[Math.floor(Math.random() * numbers.length)];
        } else if (random === 3 && mustSpecial && result.length < length - maxSpecial) {
            result += special[Math.floor(Math.random() * special.length)];
        }
    }

    //Check if all requirements are met
    if (mustUpper && !result.match(/[A-Z]/)) {
        return generatePassword(length, mustUpper, mustLower, mustNumber, mustSpecial);
    }
    if (mustLower && !result.match(/[a-z]/)) {
        return generatePassword(length, mustUpper, mustLower, mustNumber, mustSpecial);
    }
    if (mustNumber && !result.match(/[0-9]/)) {
        return generatePassword(length, mustUpper, mustLower, mustNumber, mustSpecial);
    }
    if (mustSpecial && !result.match(/[!@#$&?]/)) {
        return generatePassword(length, mustUpper, mustLower, mustNumber, mustSpecial);
    }

    //randomize order
    for (let i = 0; i < 10; i++) {
        const random = Math.floor(Math.random() * result.length);
        const random2 = Math.floor(Math.random() * result.length);
        const temp = result[random];
        result = result.slice(0, random) + result[random2] + result.slice(random + 1);
        result = result.slice(0, random2) + temp + result.slice(random2 + 1);
    }

    return result;
}