export const Parsers = {
    /**
     * Parses date strings to epoch timestamp
     * @param dataset
     */
    dateParser(dataset: any) {
        const fields = dataset[0]; // 1e row zijn altijd fields/columnheaders
        const datumIndex = fields.indexOf('datum');
        if (datumIndex !== -1) {
            let counter = 0;
            dataset = dataset.map((row: any) => {
                counter++;
                if (counter === 1) {
                    return row; // skip fields/columnheaders
                }

                let foundDate = new Date(row[datumIndex]);
                row[datumIndex] = foundDate.getTime();
                return row;
            });
        }
        return dataset;
    },
    /**
     * Removes underscores from column title
     * @param dataset
     */
    nameParser(columnHeaders: any) {
        const parsed = columnHeaders.map((field: any) => {
            //Replace global (g) underscores with spaces
            return field.replace(/_/g, ' ');
        });
        return parsed;
    }
}