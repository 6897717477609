<script setup lang="ts">
import DashboardEnvironment from "../components/layouts/DashboardEnvironment.vue";
import {onMounted, Ref, ref} from "vue";
import {Fetcher} from "../utils/fetcher.ts";
import {useRouter} from "vue-router";
import {useMainStore} from "../stores/pinia/mainStore.ts";
import {initGemeente} from "../config/dexieLoader.ts";

const fetcher = new Fetcher(true);
const router = useRouter();
const mainStore = useMainStore();

interface Gemeente {
  code: string,
  name: string
}

const search: Ref<String> = ref("");
const gemeenten: Ref<Gemeente[]> = ref([]);
const gemeentenResults: Ref<Gemeente[]> = ref([]);
const lastVisited: Ref<Gemeente[]> = ref([]);

const filterGemeenten = () => {
  if (search.value === "") {
    gemeentenResults.value = [];
    return;
  }

  //Point system
  // 1. Gemeente name is exact search
  // 2. Gemeente name starts with search
  // 3. Gemeente name contains search

  //show top 8 results

  //@ts-ignore (Mike Lammers fix dit) //FIXME
  const results = gemeenten.value.filter(gemeente => {
    if (gemeente.name.toLowerCase() === search.value.toLowerCase()) {
      return true;
    }
    if (gemeente.name.toLowerCase().startsWith(search.value.toLowerCase())) {
      return true;
    }
    if (gemeente.name.toLowerCase().includes(search.value.toLowerCase())) {
      return true;
    }
    return false;
  });

  gemeentenResults.value = results.slice(0, 8);

}


onMounted(() => {
  fetchGemeenten();
  fetchLastVisited();
});

const fetchGemeenten = async () => {
  const response = await fetcher.get("/utils/gemeenten");
  if (response.ok) {
    // @ts-ignore
    gemeenten.value = await response.data;
  }
}

const fetchLastVisited = () => {
  const lastVisitedString = localStorage.getItem("lastVisited");
  if (lastVisitedString) {
    lastVisited.value = JSON.parse(lastVisitedString);
  } else {
    localStorage.setItem("lastVisited", JSON.stringify([]));
    lastVisited.value = [];
  }
}

const openGemeente = (code: string) => {
  fetchLastVisited();
  const newItem = {
    code: code,
    // @ts-ignore
    name: gemeenten.value.find(gemeente => gemeente.code === code).name
  }

  //if item already exists, remove it
  const index = lastVisited.value.findIndex(item => item.code === code);
  if (index !== -1) {
    lastVisited.value.splice(index, 1);
  }

  //add new item to front of array
  lastVisited.value.unshift(newItem);

  //remove last item if array is longer than 5
  if (lastVisited.value.length > 5) {
    lastVisited.value.pop();
  }

  localStorage.setItem("lastVisited", JSON.stringify(lastVisited.value));

  mainStore.setLoaded('geo', false);

  initGemeente(code).then(() => {
    mainStore.setLoaded('geo', true);
    router.push(`/gemeente/nieuwbouwmarkt/${code}`);
  });
}

const clearHistory = () => {
  localStorage.setItem("lastVisited", JSON.stringify([]));
  lastVisited.value = [];
}

</script>

<template>
  <DashboardEnvironment>

    <div class="w-full  flex flex-col justify-center items-center mt-8">
      <div class="container">
        <div class="mb-4">
          <h2 class="text-3xl font-semibold">
            Zoek een gemeente
          </h2>
          <span class="text-gray-500">Bekijk de woningmarkt van een gemeente naar keuze.</span>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full ">
            <input type="text" class="w-full p-4 border rounded-md" placeholder="Zoek gemeente" v-model="search"
                   @input="filterGemeenten">
          </div>
          <!-- resultaten -->

          <div class="w-full mt-4">
            <div v-if="gemeentenResults.length > 0" class="bg-white shadow overflow-hidden rounded-md">
              <ul v-for="gemeente in gemeentenResults">
                <li class="px-6 py-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:border-gray-300"
                    @click="openGemeente(gemeente.code)">
                  {{ gemeente.name }}
                </li>
              </ul>
            </div>
            <div v-else>
              <template v-if="lastVisited.length > 0">
                <ul class="bg-white shadow overflow-hidden rounded-md">
                  <li class="px-6 py-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:border-gray-300 flex flex-row items-center"
                      v-for="gemeente in lastVisited" @click="openGemeente(gemeente.code)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"
                         class="mr-2 history-icon">
                      <!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->
                      <path
                          d="M48 106.7V56c0-13.3-10.7-24-24-24S0 42.7 0 56V168c0 13.3 10.7 24 24 24H136c13.3 0 24-10.7 24-24s-10.7-24-24-24H80.7c37-57.8 101.7-96 175.3-96c114.9 0 208 93.1 208 208s-93.1 208-208 208c-42.5 0-81.9-12.7-114.7-34.5c-11-7.3-25.9-4.3-33.3 6.7s-4.3 25.9 6.7 33.3C155.2 496.4 203.8 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C170.3 0 94.4 42.1 48 106.7zM256 128c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/>
                    </svg>
                    {{ gemeente.name }}
                  </li>
                </ul>
                <div class="mt-2 mb-8 w-full flex justify-end">
                  <div
                      class="inline-flex text-xs text-gray-900 items-center p-2 bg-white shadow overflow-hidden rounded-md hover:bg-gray-100 hover:cursor-pointer"
                      v-if="search == '' && lastVisited.length > 0" @click="clearHistory">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"
                         class="me-3 trash-icon">
                      <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                      <path
                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                    </svg>
                    Geschiedenis wissen
                  </div>
                </div>
              </template>
              <ul v-if="gemeenten.length > 0" class="bg-white shadow overflow-hidden rounded-md">
                <li class="px-6 py-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:border-gray-300 flex flex-row items-center"
                    v-for="gemeente in gemeenten" @click="openGemeente(gemeente.code)">
                  {{ gemeente.name }}
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </div>


  </DashboardEnvironment>
</template>

<style scoped>
.history-icon {
  fill: #6B7280;
}

.trash-icon {
  fill: #980909;
}
</style>