const component = {
    type: 'Highcharts',
    cell: 'nm-huidig-aanbod-naar-projectgrootte',
    connector: {
        id: 'huidig_aanbod_naar_projectgrootte'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        categorie: 'x',
        aantal: 'y'
    },
    title: {
        useHTML: true,
        text: 'Huidige aanbod naar projectgrootte'
    },
    chartOptions: {
        chart: {
            type: 'bar',
        },
        xAxis: {
            type: 'category'
        },
    }
};
export default component;