const component = {
    type: 'Highcharts',
    cell: 'ec-woninghypotheken-staats-lening',
    connector: {
        id: 'hypotheekrente_staatslening_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'hypotheekrente op nieuwe leningen': 'y',
        'rente obligaties 10 jaar': 'y',
        'woninghypotheekrente': 'y',
        datum: 'x'
    },
    title: {
        text: 'Woninghypotheken & Nederland 10-jaars rente'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            labels: {
                formatter: function (): string {
                    // @ts-ignore
                    return this.axis.defaultLabelFormatter.call(this) + '%';
                }
            }
        },
        tooltip: {
            shared: true,
            valueSuffix: '%',
        }
    }
};

export default component;