export default {
    layouts: [{
        id: 'layout-1',
        rows: [
            //1e rij (4 KPI's)
            {
                id: 'row-1',
                cells: [
                    {
                        id: 'nm-quickfact-1',
                    }, {
                        id: 'nm-quickfact-2',
                    }, {
                        id: 'nm-quickfact-3',
                    }, {
                        id: 'nm-quickfact-4',
                    }]
            },
            //2e rij (2 filters)
            //3e rij (2 grafieken)
            {
                id: 'row-3',
                cells: [
                    {
                        id: 'nm-verkopen-per-provincie-filter',
                        width: '50%',
                        height: '70px'
                    }, {
                        id: 'nm-nieuwbouwverkopen-naar-woningtype-filter',
                        width: '50%'
                    },
                    {
                        id: 'nm-verkopen-per-provincie',
                        width: '50%',
                        height: '560px'
                    }, {
                        id: 'nm-nieuwbouwverkopen-naar-woningtype',
                        width: '50%',
                        height: '560px'
                    }]
            },
            //4e rij - filters
            //5e rij (3 items)
            {
                id: 'row-5',
                cells: [
                    {width: '2/3'},
                    {
                        id: 'nm-bouwvergunningen-woonruimten-filter',
                        width: '1/3',
                        height: '55px'
                    },
                    {
                        id: 'nm-kaart-huidig-aanbod',
                        width: '1/3',
                        height: '500px'
                    },
                    {
                        id: 'layout-row-4-splitted',
                        layout: {
                            rows: [
                                {
                                    cells: [
                                        {
                                            id: 'nm-quickfact-nbo-1',
                                            height: '248px',

                                        }
                                    ]
                                },
                                {
                                    cells: [
                                        {
                                            id: 'nm-quickfact-nbo-2',
                                            height: '248px',
                                        }
                                    ]
                                }
                            ]
                        }
                    },
                    {
                        id: 'nm-bouwvergunningen-woonruimten',
                        width: '1/3',
                        height: '500px'
                    }]
            },
            //6e rij (3 items)
            {
                id: 'row-6',
                cells: [
                    {
                        id: 'nm-huidig-aanbod-naar-woningtype',
                        width: '1/3',
                        height: '560px'
                    }, {
                        id: 'nm-huidig-aanbod-naar-projectgrootte',
                        width: '1/3',
                        height: '560px'
                    }, {
                        id: 'nm-huidig-aanbod-naar-prijscategorie',
                        width: '1/3',
                        height: '560px'
                    }]
            },
            //7e rij (2 grafieken)
            {
                id: 'row-7',
                cells: [
                    {
                        id: 'nm-verkooptijden-verkochte-appartementen',
                        width: '50%',
                        height: '560px'
                    }, {
                        id: 'nm-verkooptijden-verkochte-eengezinswoningen',
                        width: '50%',
                        height: '560px'
                    }]
            }
        ]
    }]
}
