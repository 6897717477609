<script setup lang="ts">
import {onMounted, Ref, ref} from "vue";
import { AccountStore } from "../../../stores/accountStore.ts";
import { useRouter } from "vue-router";
import {Fetcher, FetcherResponse} from "../../../utils/fetcher.ts";

const router = useRouter();
const fetcher = new Fetcher(true);

const errors: Ref<string[]> = ref([]);
const success = ref(false);

const form = ref({
  email: '',
  password: '',
  password_confirmation: '',
});

onMounted(async () => {
  const access: number = await AccountStore.getAccessLevel();
  if (access < 50) {
    await router.push('/404');
  }
});



const handleSubmit = async () => {
  //Do Submit

errors.value = [];

  if (form.value.email.length === 0) {
    errors.value.push('Email is verplicht');
  }

  //Check for basic email format
  if (!form.value.email.includes('@')) {
    errors.value.push('Email is ongeldig');
  }


  const res: FetcherResponse<{ msg: string }> = await fetcher.post('/auth/create', {
    organisation_id: 2, //Is woningbouwersNL
    email: form.value.email,
  })

  if (res.ok) {

    //Show success message
    success.value = true;
    errors.value = [];
    form.value.email = '';
    form.value.password = '';
    form.value.password_confirmation = '';
  } else {
    errors.value.push(res.data.msg);
  }
}

</script>

<template>
  <div class="flex flex-col flex-1">
    <h1 class="text-3xl font-semibold text-slate-800 ">
      Nieuw dashboard account aanmaken
    </h1>
    <span class="mt-4 mb-2 text-md text-slate-600">
      Maak een nieuw dashboard account aan voor een gebruiker. <br>
      Deze gebruiker kan alle dashboards zien van organisatie <span class="font-bold">WoningbouwersNL</span>
    </span>

    <div class="mt-8">
      <h3 class="text-xl font-semibold text-slate-800">Account aanmaken</h3>
      <p class="mt-1 text-sm text-slate-600">
        Vul hieronder het email adres in van de gebruiker. <br>
        De gebruiker ontvangt een mail met daarin een wachtwoord.
      </p>
      <form class="flex flex-col w-full md:w-2/3 lg:w-1/2 xl:w-1/4 min-w-[400px] h-full">
        <div class="mt-4">
          <label for="username" class="block text-sm font-medium text-slate-700">Email</label>
          <input type="email" id="username" v-model="form.email" class="mt-1 p-2 w-full border rounded-md">
        </div>

        <!-- Error Box -->
        <div class="flex flex-col mt-4" v-if="errors.length > 0">
          <div class="px-4 py-2 mb-2 text-red-700 bg-red-200 rounded-md border-l-4 border-red-500">
            <span class="font-semibold">Er zijn fouten opgetreden:</span>
            <ul class="list-disc list-inside">
              <li v-for="error in errors">{{error}}</li>
            </ul>
          </div>
        </div>

        <!-- Success Box -->
        <div class="flex flex-col mt-4" v-if="success">
          <div class="px-4 py-2 mb-2 text-emerald-700 bg-emerald-200 rounded-md border-l-4 border-emerald-500">
            <span class="font-semibold">
              Account is succesvol aangemaakt! <br>
            </span>
            <span class="text-xs">
              Inloggen kan na het instellen van een wachtwoord.
            </span>
          </div>
        </div>

        <div class="flex flex-col">
          <button type="submit" class="inline-block px-4 py-2 mt-4 text-white bg-slate-800 hover:bg-slate-950 rounded-md" @click.prevent="handleSubmit()">Genereer account</button>
        </div>

      </form>
    </div>
  </div>
</template>
