const component = {
    cell: 'wm-bevolkingsgroei-ytd-filter',
    className: 'test2',
    type: 'SelectFilter',
    label: 'Provincie:',
    filterOptions: {
        filters: [
            // {key: 'SHOWALL', label: 'Alles'},
            // {key: 'GEM', label: 'Gemiddelde'},// Bestaat niet! Moet nog gemaakt worden.
            {key: 'Drenthe (PV)', label: 'Drenthe'},
            {key: 'Flevoland (PV)', label: 'Flevoland'},
            {key: 'Friesland (PV)', label: 'Friesland'},
            {key: 'Gelderland (PV)', label: 'Gelderland'},
            {key: 'Groningen (PV)', label: 'Groningen'},
            {key: 'Limburg (PV)', label: 'Limburg'},
            {key: 'Noord-Brabant (PV)', label: 'Noord-Brabant'},
            {key: 'Noord-Holland (PV)', label: 'Noord-Holland'},
            {key: 'Overijssel (PV)', label: 'Overijssel'},
            {key: 'Utrecht (PV)', label: 'Utrecht'},
            {key: 'Zeeland (PV)', label: 'Zeeland'},
            {key: 'Zuid-Holland (PV)', label: 'Zuid-Holland'}
        ],
        dataPools: ['bevolkingsgroei_pool'],
        column: 'regio_titel'
    }
};

export default component;