const component = {
    cell: 'gm-nm-quickfact',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_pool',
    },
    className: 'highcharts-dashboards-component quickfact',
    tableOptions: {
        title: '%gemeente%',
        th: 'measure',
        td: 'measure_value',
        keyFormatter: (key: string = "Aantal_projecten") => key.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()).replace(/_/g, ' '),
        valueFormatter: (value: any = 0) => {
            return parseInt(value)
        },
        reverse: false,
        size: 'xl',
        align: 'center'
    }
};

export default component;