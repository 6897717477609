const component = {
    cell: 'wm-quickfact-1',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_1_pool',
    },
    className: 'highcharts-dashboards-component h-auto quickfact',
    tableOptions: {
        title: 'Verkochte nieuwbouw koopwoningen',
        th: 'datum_tekst',
        td: 'waarde',
        reverse: false,
    },
    tooltip: true
};

export default component;