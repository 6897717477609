import { createVNode, render } from 'vue';
import VueRangeComponent from '../components/controls/Range.vue';
// utils
import { DateHelpers } from '../utils/dateHelpers.ts'

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class QuarterRangeFilterComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        this.type = 'QuarterRangeFilter';
        this.filterElement = document.createElement('div');
        this.filterElement.setAttribute('class', 'print:hidden');

        this.isDataReady = false;

        if (options.filterOptions === undefined) {
            console.error('Filter component has no filterOptions')
        }

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueRangeComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }



    load() {


        return new Promise((resolve, reject) => {
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {
                super.load()
                    .then(() => {

                        const filterColumn = this.options.filterOptions.column;

                        const setModifierOptions = (dataPool,
                                                    min,
                                                    max,
                                                    column = filterColumn,
                                                    type = 'Range') => {
                            dataPool.setModifierOptions({
                                type: type,
                                ranges: [{
                                    column: column,
                                    minValue: min,
                                    maxValue: max
                                }]
                            });
                        }

                        const updateDatapool = (min, max) => {
                            const minDate = DateHelpers.getDateObjectByQuarterFilter(min)
                            const maxDate = DateHelpers.getDateObjectByQuarterFilter(max)
                            // naar laatste dag & maand in de quarter
                            maxDate.setMonth(maxDate.getMonth() + 3)
                            maxDate.setDate(0);

                            this.options.filterOptions.dataPools.forEach(name => {
                                setModifierOptions(
                                    this.board.dataPool.connectors[name],
                                    minDate.getTime(),
                                    maxDate.getTime()
                                )
                            })
                        }

                        let loadFailed = false

                        const values = [];
                        this.options.filterOptions.dataPools.forEach(name => {
                            const dataPool = this.board.dataPool.connectors[name];
                            if(dataPool) {
                                dataPool.table.columns[filterColumn].forEach(timestamp => {
                                    const date = new Date(timestamp);
                                    values.push((date.getFullYear() * 4) +  + Math.floor((date.getMonth() + 3) / 3))
                                })
                            } else {
                                loadFailed = true
                                console.error('datapool: ' + name + ' niet geladen!')
                            }
                        })

                        if(!loadFailed) {
                            const min = Math.min(...values)
                            const max = Math.max(...values)


                            let selected = [min, max]

                            if (this.options.filterOptions.minSelected !== undefined) {
                                const minSelectedDate = new Date(this.options.filterOptions.minSelected);
                                selected[0] = ((minSelectedDate.getFullYear() * 4) + +Math.floor((minSelectedDate.getMonth() + 3) / 3))
                            }

                            if (this.options.filterOptions.maxSelected !== undefined) {
                                const maxSelectedDate = new Date(this.options.filterOptions.maxSelected);
                                selected[1] = ((maxSelectedDate.getFullYear() * 4) + +Math.floor((maxSelectedDate.getMonth() + 3) / 3))
                            }

                            if (selected[0] < min) selected[0] = min
                            if (selected[1] > max) selected[1] = max

                            if (selected[0] !== min || selected[1] !== max) {
                                updateDatapool(selected[0], selected[1])
                            }


                            this.renderComponent(this.filterElement, {
                                min: min,
                                max: max,
                                selected: selected,
                                merge: ((max - min) / 5),
                                step: -1, // vrije range
                                format: (v) => {
                                    const date = DateHelpers.getDateObjectByQuarterFilter(v)
                                    return `Q${Math.floor((date.getMonth() + 3) / 3)} ${date.getFullYear()}`;
                                }
                            });


                            this.filterElement._vnode.props.onUpdate = (newValue) => {
                                updateDatapool(newValue.min, newValue.max)
                            };

                            this.contentElement.appendChild(this.filterElement);
                            this.parentElement.appendChild(this.element);
                        }
                    })

                resolve(this);
            } catch (error) {
                reject(error);
            }
        })

    }
}

ComponentRegistry.registerComponent('QuarterRangeFilter', QuarterRangeFilterComponent);