const component = {
    cell: 'wm-landkaart-tekort-overschot',
    type: 'MapChoropleth',
    connector: {
        id: 'woningtekort_overschot_pool',
    },
    mapOptions: {
        title: 'Woningtekort en overschot (%modifier%)',
        dataName: 'Aantal woningen',
        matchSettings: {
            poolKey: "coropnaam",
            topoKey: "coropnaam",
            valueRow: "value", // The datapool column that contains the values (needed for the legend and min/max values)
            locationRow: "coropnaam" // The datapool column that contains the location names
        },
        dexieKey: 'corop',
        colorScale: ['#ef895b', '#ec9670', '#e9a383', '#e7b096', '#e4bca9', '#e7c8b9', '#f1d3c2'],
        showLegend: true,
        useMinMaxColorScale: false,
        smoothLegend: true,
        legendFormatter: (value: number) => {
            if (value >= 1000) {
                return `${value / 1000}k`;
            } else if (value <= -1000) {
                return `${value / 1000}k`;
            } else {
                return value;
            }
        },
        mapZoom: 5.7, //Optioneel maar handig
    }
};

export default component;
