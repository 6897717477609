export default {
    layouts: [{
        id: 'layout-1',
        rows: [
            //1e rij - 2 grafieken
            {
                id: 'row-1',
                cells: [
                    {
                        id: 'gm-vn-werkloosheid',
                        height: '560px',
                        width: '1/2'
                    },
                    {
                        id: 'gm-vn-bevolkingsdichtheid-per-buurt',
                        height: '560px',
                        width: '1/2',
                    }]
            },
            // 2e rij - filters voor 2.5
            {
                id: 'row-2',
                cells: [
                    {
                        width: '1/2'
                    }, {
                        id: 'gm-vn-verdeling-opleidingsniveau-filter',
                        height: '70px',
                        width: '1/2'
                    },
                    {
                        id: 'gm-vn-opleiding-naar-buurt-kaart',
                        height: '560px',
                        width: '1/2'
                    }, {
                        id: 'gm-vn-verdeling-opleidingsniveau',
                        height: '560px',
                        width: '1/2'
                    }]
            },
            //3e - 2 grafieken
            /*{
                id: 'row-3',
                cells: [
                    {
                        id: 'gm-vn-huishoudensgroei-prognose',
                        width: '1/4',
                        height: '560px'
                    },
                ]
            },*/
            //4e rij - leeg na edits
            {
                id: 'row-4',
                cells: [

                ]
            },
            //5e rij - 2 grafieken
            {
                id: 'row-5',
                cells: [
                    {
                        id: 'gm-vn-inschrijvers-per-prijsklasse',
                        width: '1/2',
                        height: '560px'
                    },
                    {
                        id: 'gm-vn-inschrijvers-per-woningtype',
                        width: '1/2',
                        height: '560px'
                    }
                ]
            },
            //6e rij - 2 grafieken
            {
                id: 'row-5',
                cells: [
                    {
                        id: 'gm-vn-huur-of-koop',
                        width: '1/2',
                        height: '560px'
                    },
                    {
                        id: 'gm-vn-huishouden-woningzoekende',
                        width: '1/2',
                        height: '560px'
                    }
                ]
            }
        ]
    }]
}
