<script setup lang="ts">
import {computed, ref} from "vue";
import {Fetcher, FetcherResponse} from "../../../utils/fetcher.ts";

const fetcher = new Fetcher(true);

const errors = ref([]);
const success = ref(false);

const form = ref({
  old_password: '',
  new_password: '',
  new_password_confirmation: '',
});

const passwordStrength = ref(1);//0-100

//@ts-ignore
const removeSpaces = (e) => {
  //remove space from all fields
  //@ts-ignore
  Object.keys(form.value).forEach((key) => {
    //@ts-ignore
    form.value[key] = form.value[key].replace(/\s/g, '');
  });
  calculateStrength(e);
}


//@ts-ignore
const calculateStrength = (e) => {
  const password = form.value.new_password;
  let strength = 1;

  //input is empty, return 1
  if (password.length === 0) {
    passwordStrength.value = 1;
    return;
  }

  //if password is less than 6, return
  if (password.length < 6) {
    passwordStrength.value = 20;
    return;
  }

  //every character is 5 points to a max of 50
  strength += password.length * 5 > 50 ? 50 : password.length * 5;

  if (password.match(/[a-z]/)) {
    strength += 15;
  }
  if (password.match(/[A-Z]/)) {
    strength += 10;
  }
  if (password.match(/[0-9]/)) {
    strength += 15;
  }
  if (password.match(/[^a-zA-Z0-9]/)) {
    strength += 10;
  }
  passwordStrength.value = strength;
}

const passwordStrengthColor = computed(() => {
  if (passwordStrength.value < 50) {
    return 'bg-red-500';
  } else if (passwordStrength.value < 75) {
    return 'bg-yellow-500';
  } else {
    return 'bg-green-500';
  }
});

const passwordStrengthText = computed(() => {
  if (passwordStrength.value < 50) {
    return 'Onveilig';
  } else if (passwordStrength.value < 75) {
    return 'Matig';
  } else {
    return 'Veilig';
  }
});

const handleSubmit = async () => {
  errors.value = [];
  if (passwordStrength.value < 45) {
    //@ts-ignore
    errors.value.push("Uw wachtwoord is te zwak.");
    return;
  }
  if (form.value.new_password !== form.value.new_password_confirmation) {
    //@ts-ignore
    errors.value.push("De wachtwoorden komen niet overeen.");
    return;
  }
  if (form.value.new_password === form.value.old_password) {
    //@ts-ignore
    errors.value.push("Uw nieuwe wachtwoord mag niet hetzelfde zijn als uw oude wachtwoord.");
    return;
  }

  //Send request to API
  const result: FetcherResponse<any> = await fetcher.post('/auth/change-password', {
    currentPassword: form.value.old_password,
    newPassword: form.value.new_password,
  })

  if (result.ok) {
    success.value = true;
    form.value.old_password = '';
    form.value.new_password = '';
    form.value.new_password_confirmation = '';
    passwordStrength.value = 0;
  } else {
    //@ts-ignore
    errors.value.push(result.data.msg);
  }
}

</script>

<template>
<h1 class="text-3xl font-semibold text-slate-800 ">
  Uw wachtwoord wijzigen
</h1>
  <form class="flex flex-col w-full md:w-2/3 lg:w-1/2 xl:w-1/4 min-w-[400px] h-full pe-8 py-8" >
    <div class="flex flex-col mb-4">
      <label class="mb-2 font-semibold text-slate-800" for="old_password">Oud wachtwoord</label>
      <span class="mb-2 text-xs text-slate-600">Vul hier uw oude wachtwoord in.</span>
      <input v-model="form.old_password" @input="removeSpaces" class="px-4 py-2 border rounded-md" type="password" id="old_password" name="old_password" placeholder="Oud wachtwoord">
    </div>
    <hr class="mb-4">
    <div class="flex flex-col mb-4">
      <label class="mb-2 font-semibold text-slate-800" for="new_password">Nieuw wachtwoord</label>
      <span class="mb-2 text-xs text-slate-600">Vul hier uw nieuwe wachtwoord in.</span>
      <input autocomplete="new-password" @input="removeSpaces" v-model="form.new_password" class="px-4 py-2 border rounded-md" type="password" id="new_password" name="new_password" placeholder="Nieuw wachtwoord">
    </div>
    <div class="password-meter" v-show="passwordStrength > 0">
      <div class="password-meter-fill" :style="`width: ${passwordStrength}%`" :class="passwordStrengthColor"></div>
    </div>
    <span class="mb-3 mt-1 text-xs text-slate-600" v-show="passwordStrength > 0">
      Wachtwoord sterkte: <span class="font-semibold">{{passwordStrengthText}}</span>
    </span>
    <div class="flex flex-col mb-4">
      <label class="mb-2 font-semibold text-slate-800" for="new_password_confirmation">Nieuw wachtwoord bevestigen</label>
      <span class="mb-2 text-xs text-slate-600">Herhaal hier uw nieuwe wachtwoord ter bevestiging.</span>
      <input autocomplete="new-password" @input="removeSpaces" v-model="form.new_password_confirmation" class="px-4 py-2 border rounded-md" type="password" id="new_password_confirmation" name="new_password_confirmation" placeholder="Herhaal nieuwe wachtwoord">
    </div>
    <!-- Error Box -->
    <div class="flex flex-col mb-4" v-if="errors.length > 0">
      <div class="px-4 py-2 mb-2 text-red-700 bg-red-200 rounded-md border-l-4 border-red-500">
        <span class="font-semibold">Er zijn fouten opgetreden:</span>
        <ul class="list-disc list-inside">
          <li v-for="error in errors">{{error}}</li>
        </ul>
      </div>
    </div>

    <!-- Success Box -->
    <div class="flex flex-col mb-4" v-if="success">
      <div class="px-4 py-2 mb-2 text-emerald-700 bg-emerald-200 rounded-md border-l-4 border-emerald-500">
        <span class="font-semibold">Wachtwoord is succesvol gewijzigd.</span>
      </div>
    </div>

    <div class="flex justify-between">
      <button type="submit" class="inline-block px-4 py-2 mt-4 text-white bg-slate-800 hover:bg-slate-950 rounded-md" @click.prevent="handleSubmit">Wachtwoord wijzigen</button>
    </div>
  </form>
</template>

<style scoped>
.password-meter {
  height: 10px;
  width: 100%;
  background-color: #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
}

.password-meter-fill {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}
</style>