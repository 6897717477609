/* *
 *
 *  (c) 2009-2023 Highsoft AS
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 *  Authors:
 *  - Pawel Lysy
 *  - Cas Boomkamp (Xitres - www.xitres.nl)
 *
 * */

'use strict';

/* *
 *
 *  Imports
 *
 * */


import JSONAuthConnectorOptions from "./JSONAuthConnectorOptions";

import U from "@highcharts/dashboards/es-modules/Core/Utilities.js";
import JSONConverter from "@highcharts/dashboards/es-modules/Data/Converters/JSONConverter";
import DataEvent from "@highcharts/dashboards/es-modules/Data/DataEvent";
import Types from "@highcharts/dashboards/es-modules/Shared/Types";
import {DataConnector} from "@highcharts/dashboards";
import {AccountStore} from "../../stores/accountStore.ts";
import {GemeenteStore} from "../../stores/gemeenteStore.ts";

const {merge} = U;

/* *
 *
 *  Class
 *
 * */

/**
 * Class that handles creating a DataConnector from JSON structure
 *
 * @private
 */
//@ts-ignore (DataConnector works, stupid hightcharts)
class JSONAuthConnector extends DataConnector {
    /* *
     *
     *  Static Properties
     *
     * */

    protected static readonly defaultOptions: JSONAuthConnectorOptions = {
        data: [],
        enablePolling: false,
        dataRefreshRate: 0,
        firstRowAsNames: true,
        orientation: 'rows'
    };

    /* *
     *
     *  Constructor
     *
     * */

    /**
     * Constructs an instance of JSONConnector.
     *
     * @param {JSONConnector.UserOptions} [options]
     * Options for the connector and converter.
     */
    public constructor(
        options?: JSONConnector.UserOptions
    ) {
        const mergedOptions = merge(JSONAuthConnector.defaultOptions, options);

        super(mergedOptions);

        this.converter = new JSONConverter(mergedOptions);
        this.options = mergedOptions;

        if (mergedOptions.enablePolling) {
            // @ts-ignore
            this.startPolling(
                Math.max(mergedOptions.dataRefreshRate ?? 0, 1) * 1000
            );
        }
    }

    /* *
     *
     *  Properties
     *
     * */

    /**
     * Options related to the handling of the JSON DataConnector,
     * i.e. source, fetching, polling
     */
    public readonly options: JSONAuthConnectorOptions;

    /**
     * The attached parser that converts the data format to the table.
     */
    public readonly converter: JSONConverter;

    /* *
     *
     *  Functions
     *
     * */

    /**
     * Initiates the loading of the JSON source to the connector
     *
     * @param {DataEvent.Detail} [eventDetail]
     * Custom information for pending events.
     *
     * @emits JSONConnector#load
     * @emits JSONConnector#afterLoad
     */
    public load(eventDetail?: DataEvent.Detail): Promise<this> {
        const connector = this,
            converter = connector.converter,
            // @ts-ignore
            table = connector.table,
            {data, dataUrl, dataModifier} = connector.options;

        // @ts-ignore
        connector.emit<JSONConnector.Event>({
            type: 'load',
            data,
            detail: eventDetail,
            table
        });

        // If already loaded, clear the current rows
        table.deleteRows();

        if (!dataUrl) {
            return Promise.reject(console.error("No dataUrl is set"));
        }

        if (!AccountStore.isTokenSet) {
            return Promise.reject(console.error("Auth Token is not set"));
        }

        if (!AccountStore.isTokenValid) {
            return Promise.reject(console.error("Auth Token is not valid"));
        }

        let formattedDataUrl = dataUrl;


        if (formattedDataUrl.includes('%gmcode%')) {
            // Add gmcode to dataUrl
            const gmcode = GemeenteStore.gmcode;
            if (gmcode) {
                formattedDataUrl = formattedDataUrl.replace('%gmcode%', gmcode);
            } else {
                return Promise.reject(console.error("gmcode is not set"));
            }
        }



        return Promise
            .resolve(
                dataUrl ?
                    fetch(formattedDataUrl, {
                        headers: {
                            Authorization: 'Bearer ' + AccountStore.token,
                        }
                    }).then(
                        (json): Promise<any> => json.json()) : data ?? []
            )
            .then((data): Promise<Array<Array<number | string>>> => {
                if (data) {
                    converter.parse({data});
                    table.setColumns(converter.getTable().getColumns());
                }


                return connector
                    // @ts-ignore
                    .setModifierOptions(dataModifier)
                    .then((): Array<Array<number | string>> => data);
            })
            .then((data): this => {
                // @ts-ignore
                connector.emit<JSONConnector.Event>({
                    type: 'afterLoad',
                    data,
                    detail: eventDetail,
                    table
                });
                return connector;
            })['catch']((error): never => {
            // @ts-ignore
            connector.emit<JSONConnector.Event>({
                type: 'loadError',
                detail: eventDetail,
                error,
                table
            });
           throw error;
        });
    }

}

/* *
 *
 *  Class Namespace
 *
 * */

/**
 * Types for class-specific options and events.
 */
namespace JSONConnector {

    /* *
     *
     *  Declarations
     *
     * */

    /**
     * Event objects fired from JSONConnector events.
     */
    export type Event = (ErrorEvent | LoadEvent);

    /**
     * Parse the data before passing it to the JSON parser.
     */
    export interface DataBeforeParseCallbackFunction {
        (data: JSONConverter.Data): JSONConverter.Data;
    }

    /**
     * The event object that is provided on errors within JSONConnector.
     */
        // @ts-ignorepre
    export interface ErrorEvent extends DataConnector.ErrorEvent {
        data?: JSONConverter.Data;
    }

    /**
     * The event object that is provided on load events within JSONConnector.
     */
        // @ts-ignore
    export interface LoadEvent extends DataConnector.LoadEvent {
        data?: JSONConverter.Data
    }

    /**
     * Available options for constructor and converter of the JSONConnector.
     */
    export type UserOptions = (
        Types.DeepPartial<JSONAuthConnectorOptions> &
        JSONConverter.UserOptions
        );

}

/* *
 *
 *  Registry
 *
 * */

declare module '@highcharts/dashboards/es-modules/Data/Connectors/DataConnectorType' {
    interface DataConnectorTypes {
        JSONAuth: typeof JSONAuthConnector;
    }
}

// @ts-ignore
DataConnector.registerType('JSONAuth', JSONAuthConnector);

/* *
 *
 *  Default Export
 *
 * */

export default JSONAuthConnector;