<script setup lang="ts">
// Vue Components
import DashboardEnvironment from "../components/layouts/DashboardEnvironment.vue";
import HighchartsDashboard from "../components/highcharts/HighchartsDashboard.vue";

// Data and GUI for charts
import ecDatapools from "../charts/ec/ec.datapools.ts";
import ecGui from "../charts/ec/ec.gui.ts";
import * as Charts from '../charts/ec/charts';

// Tooltips and Store
import Tooltips from '../charts/ec/ec.tooltips.ts'
//@ts-ignore
import { useTooltipStore } from '/src/stores/pinia/tooltipStore'
//@ts-ignore
import { useSubtitleStore } from '/src/stores/pinia/subtitleStore.ts';

// Configuration
const dashboardConfig = {
  dataPool: {
    connectors: ecDatapools,
  },
  gui: ecGui,
  components: Charts.importAll(),
}

// Tooltip Store Initialization
const tooltipStore = useTooltipStore()
tooltipStore.setTooltips(Tooltips)

// Subtitle Store init
const subtitleStore = useSubtitleStore()
subtitleStore.setSubtitles('economie')

</script>

<template>
    <DashboardEnvironment>
        <HighchartsDashboard page-id="economie" :dashboard-options="dashboardConfig"/>
    </DashboardEnvironment>
</template>

<style scoped>

</style>