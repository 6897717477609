<script setup lang="ts">
import { ref, watch, nextTick, PropType } from 'vue';

// Definieer props
const props = defineProps({
    referenceElement: Object as PropType<Element | null>,
    data: Object as PropType<{ title?: string; credit?: string; html?: string }>,
});

const tooltip = ref<HTMLElement | null>(null);
const isVisible = ref(false);

// Positieer de tooltip ten opzichte van het referenceElement
const positionTooltip = () => {
    nextTick(() => {
        if (props.referenceElement && tooltip.value) {
            const rect = props.referenceElement.getBoundingClientRect();
            const tooltipRect = tooltip.value.getBoundingClientRect();
            tooltip.value.style.top = `${rect.bottom + window.scrollY}px`;
            tooltip.value.style.left = `${rect.left + window.scrollX}px`;
            // Pas de positie aan afhankelijk van de locatie van het referenceElement
            if (rect.top + tooltipRect.height > window.innerHeight) {
                tooltip.value.style.top = `${rect.top + window.scrollY - tooltipRect.height}px`;
            }
            if (rect.left + tooltipRect.width > window.innerWidth) {
                tooltip.value.style.left = `${rect.left + rect.width + window.scrollX - tooltipRect.width}px`;
                if((rect.left + rect.width + window.scrollX - tooltipRect.width) < 0) tooltip.value.style.left = '10px';
            }
        }
    });
};

// Watch voor veranderingen in referenceElement
watch(() => props.referenceElement, (newVal) => {
    if (newVal) {
        isVisible.value = true;
        positionTooltip();
    } else {
        isVisible.value = false;
    }
}, {
    immediate: true
});

</script>

<template>
    <div ref="tooltip" class="tooltip-container overflow-hidden bg-white rounded-lg shadow-lg border border-gray-300 print:hidden">
        <div v-if="data && (data.title || data.credit)" class="bg-white px-4 py-5 sm:px-6">
            <div class="flex space-x-3">
                <div class="min-w-0 flex-1">
                    <p v-if="data.title" class="text-sm font-semibold text-gray-900">{{ data.title }}</p>
                    <p v-if="data.credit" class="text-sm text-gray-900">Bron: {{ data.credit }}</p>
                </div>
            </div>
        </div>

        <div v-if="data && data.html" class="px-4 pb-5 sm:p-6 sm:pt-0 text-sm text-gray-500" v-html="data.html"></div>
    </div>
</template>

<style scoped>
.tooltip-container {
    position: absolute;
    z-index: 1000;
    width: 720px;
    max-width: 80vw;
}

</style>

<style>
.tooltip-container a {
    color: var(--primary);
    text-decoration: underline;
}
</style>