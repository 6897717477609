const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woningvoorraad',
    connector: {
        id: 'woningvoorraad_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        oppervlakteklasse: 'x',
        aantal: 'y'
    },
    title: {
        useHTML: true,
        text: 'Woningvoorraad naar oppervlakteklasse - 2023'
    },
    chartOptions: {
        chart: {
            type: 'bar',
        },
        xAxis: {
            type: 'category'
        },
    }
};

export default component;