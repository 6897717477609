export default {
    "wm-quickfact-1": {
        title: "Aantal verkochte nieuwbouw koopwoningen (YTD)",
        credit: "Bouwgarant, SWK en Woningborg",
        html: "<p>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>"
    },
    "wm-quickfact-2": {
        title: "Aantal verkochte bestaande koopwoningen (YTD)",
        credit: "CBS, Kadaster",
        html: "<p>Het CBS baseert zich hierbij op het Kadaster zodat dit een zeer nauwkeurig beeld geeft van het aantal verkochte bestaande koopwoningen. Overigens zijn dit het aantal geregistreerde transacties bij het Kadaster van een woning gekocht door een particulier. Het zijn dus alle individuele woning transacties inclusief (toekomstige) huurwoningen.</p>" +
            "<p>Voor verdere uitleg over de CBS data zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83906NED/table?ts=1618991718835' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83906NED/table?ts=1618991718835</a></p>"
    },
    "wm-quickfact-3": {
        title: "Krapte indicator, nieuwbouw koopwoningen",
        credit: "TU Delft, Monitor Nieuwe Woningen",
        html: "<p>Op basis van de afbouwgaranties van SWK en Woningborg stelt de TU Delft elk kwartaal de Monitor Nieuwe Woningen samen. Hiervoor worden veel (na)berekeningen uitgevoerd waardoor de kwartaal update meestal 2 a 3 maanden na het sluiten van het kwartaal volgt.</p>" +
            "<p>De krapte indicator geeft aan hoeveel keuze de consument heeft. Bij een waarde onder de 5 is sprake van een krappe markt waarbij een consument weinig te kiezen heeft. Bij een waarde boven de 5 is sprake van een ruime markt waarbij de consument veel te kiezen heeft.</p>"
    },
    "wm-quickfact-4": {
        title: "Krapte indicator, bestaande koopwoningen",
        credit: "NVM",
        html: "<p>De krapte indicator geeft aan hoeveel keuze de consument heeft. Bij een waarde onder de 5 is sprake van een krappe markt waarbij een consument weinig te kiezen heeft. Bij een waarde boven de 5 is sprake van een ruime markt waarbij de consument veel te kiezen heeft.</p>" +
            "<p>Voor meer (markt)informatie vanuit de NVM over de bestaande markt zie <a href='https://www.nvm.nl/wonen/marktinformatie/' target='_blank'>https://www.nvm.nl/wonen/marktinformatie/</a></p>"
    },

    "wm-verkochte-woningen": {
        title: "Aantal verkochte koopwoningen",
        credit: "Bouwgarant, CBS, Kadaster, SWK en Woningborg",
        html: "<p><strong>Aantal verkochte nieuwbouwwoningen</strong><br/>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>" +
            "<br/><p><strong>Aantal verkochte bestaande woningen</strong><br/>Het CBS baseert zich hierbij op het Kadaster zodat dit een zeer nauwkeurig beeld geeft van het aantal verkochte bestaande koopwoningen. Overigens zijn dit het aantal geregistreerde transacties bij het Kadaster van een woning gekocht door een particulier. Het zijn dus alle individuele woning transacties inclusief (toekomstige) huurwoningen. Voor verdere uitleg over de CBS data zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83906NED/table?ts=1618991718835' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83906NED/table?ts=1618991718835</a></p>"
    },
    "wm-gemiddelde-verkoopprijs": {
        title: "Gemiddelde verkoopprijs",
        credit: "CBS, Kadaster, TU Delft (Monitor Nieuwe Woningen)",
        html: "<p><strong>Gemiddelde prijs nieuwbouw koopwoning</strong><br/>Op basis van de afbouwgaranties van SWK en Woningborg stelt de TU Delft elk kwartaal de Monitor Nieuwe Woningen samen. Hiervoor worden veel (na)berekeningen uitgevoerd waardoor de kwartaal update meestal 2 a 3 maanden na het sluiten van het kwartaal volgt.</p>" +
            "<br/><p><strong>Gemiddelde prijs bestaande koopwoning</strong><br/>Dit is een ongewogen gemiddelde: alle prijzen voor alle woningtypen worden bij elkaar opgeteld en vervolgens gedeeld door het aantal transacties. Voor een beter gevoel bij het prijsniveau kan dus beter gekeken worden naar het regionale, gemiddelde prijsniveau per woningtype. Deze data zal in de toekomst aan het dashboard worden toegevoegd via Calcasa en is ook te vinden in de Thermometer.</p>"
    },

    "wm-landkaart-tekort-overschot": {
        title: "Woningtekort of -overschot ",
        credit: "ABF Research",
        html: "<p>Elk jaar inventariseert het ABF Research de plancapaciteit door heel Nederland. Op basis hiervan maken ze prognoses voor de ontwikkeling van de nieuwbouw. Echter, verder in de tijd zijn deze prognoses steeds meer gebaseerd op een evenwichtsmodel waarbij de productie van nieuwbouwwoningen zich aanpast aan de vraag. Uit de praktijk weten we dat dit helaas niet zo werkt, er zijn teveel belemmeringen om de nieuwbouwproductie op te schroeven. Ook kan de vraag naar (koop)woningen snel veranderen door veranderingen in de migratie. Daarom tonen we in deze grafiek alleen het (verwachte) woningtekort tot en met 2030.</p>" +
            "<p>Dit kwantitatieve woningtekort/overschot wordt door ABF Research berekend door een analyse van de bevolkingsregisters. Ben je 28 en heb je een baan maar woon je nog bij je ouders: dan ziet ABF Research je als woningzoekenden. Ben je 30 en woon je met 3 anderen samen in een appartement en hebben die allemaal een full time baan? Dan telt ABF Research dit als drie woningzoekenden. Een gedetailleerde uitleg over de berekening van het woningtekort is hier te vinden: <a href='https://www.abfresearch.nl/publicaties/woningtekort-een-nieuwe-benadering-van-een-actueel-probleem/' target='_blank'>https://www.abfresearch.nl/publicaties/woningtekort-een-nieuwe-benadering-van-een-actueel-probleem/</a></p>" +
            "<p>We hebben hier gekozen om de data op het schaalniveau van de woningmarktregio te laten zien. Immers, dat is het meest relevante schaalniveau aangezien daar ook de meeste verhuisbewegingen plaats vinden en het is het terrein waarbinnen corporaties actief mogen zijn. Via <a href='https://primos.abfresearch.nl/jive' target='_blank'>https://primos.abfresearch.nl/jive</a> kan je je eigen analyses maken, ook op andere schaalniveaus.</p>"
    },
    "wm-landkaart-bevolkingsgroei": {
        title: "Bevolkingsontwikkeling",
        credit: "CBS",
        html: "<p>Data over de ontwikkeling van de bevolking publiceert het CBS per maand, de groei van het aantal huishoudens slechts één keer per jaar. Door de bevolkingsgroei van het laatste jaar weer te geven komt duidelijk in beeld welke gemeenten snel groeien en welke gemeenten minder snel groeien of zelfs krimpen. Overigens is een zeer hoge groei of krimp bijna altijd te verklaren doordat de desbetreffende gemeente(n) zijn gefuseerd. Op Gemeente niveau is een uitgebreide analyse van de bevolkingsontwikkeling van een gemeente te vinden.</p>"
    },
    "wm-landkaart-nieuwbouw-cumulatief": {
        title: "Prognose woningbouwproductie",
        credit: "ABF Research",
        html: "<p>Elk jaar inventariseert het ABF Research de plancapaciteit door heel Nederland. Op basis hiervan maken ze prognoses voor de ontwikkeling van de nieuwbouw. Echter, verder in de tijd zijn deze prognoses steeds meer gebaseerd op een evenwichtsmodel waarbij de productie van nieuwbouwwoningen zich aanpast aan de vraag. Uit de praktijk weten we dat dit helaas niet zo werkt, er zijn teveel belemmeringen om de nieuwbouwproductie op te schroeven. Daarom tonen we in deze grafiek alleen de perioden 2020 en 2025.</p>" +
            "<p>We hebben hier gekozen om de data op het schaalniveau van de woningmarktregio te laten zien. Immers, dat is het meest relevante schaalniveau aangezien daar ook de meeste verhuisbewegingen plaats vinden en het is het terrein waarbinnen corporaties actief mogen zijn. Via <a href='https://primos.abfresearch.nl/jive' target='_blank'>https://primos.abfresearch.nl/jive</a> kan je je eigen analyses maken, ook op andere schaalniveaus.</p>"
    },

    "wm-vraag-en-aanbod": {
        title: "Woningen: ontwikkeling vraag, aanbod en tekort",
        credit: "ABF Research, CBS",
        html: "<p>Elk jaar inventariseert het ABF Research de plancapaciteit door heel Nederland. Op basis hiervan maken ze prognoses voor de ontwikkeling van de nieuwbouw. Echter, verder in de tijd zijn deze prognoses steeds meer gebaseerd op een evenwichtsmodel waarbij de productie van nieuwbouwwoningen zich aanpast aan de vraag. Uit de praktijk weten we dat dit helaas niet zo werkt, er zijn teveel belemmeringen om de nieuwbouwproductie op te schroeven. Ook kan de vraag naar (koop)woningen snel veranderen door veranderingen in de migratie. Daarom tonen we in deze grafiek alleen de nieuwbouwproductie, ontwikkeling van de woningvoorraad en het (verwachte) woningtekort tot en met 2030.<br/>Dit kwantitatieve woningtekort/overschot wordt door ABF Research berekend door een analyse van de bevolkingsregisters. Ben je 28 en heb je een baan maar woon je nog bij je ouders: dan ziet ABF Research je als woningzoekenden. Ben je 30 en woon je met 3 anderen samen in een appartement en hebben die allemaal een full time baan? Dan telt ABF Research dit als drie woningzoekenden. Een gedetailleerde uitleg over de berekening van het woningtekort is hier te vinden: <a href='https://www.abfresearch.nl/publicaties/woningtekort-een-nieuwe-benadering-van-een-actueel-probleem/' target='_blank'>https://www.abfresearch.nl/publicaties/woningtekort-een-nieuwe-benadering-van-een-actueel-probleem/</a></p>" +
            "<p>De prognose van de huishoudensontwikkeling is gebaseerd op de bevolkingsprognoses van het CBS. Migratie speelt daarbij een zeer belangrijke rol en is, met open Europese grenzen, moeilijk in te schatten.</p>"
    },
    "wm-modaal-inkomen": {
        title: "Wat kan je kopen met een modaal inkomen?",
        credit: "CBS, Kadaster, TU Delft",
        html: "<p>De maximale leencapaciteit wordt elk kwartaal berekend op basis van de leennormen van het NIBUD, rekening houdend met de huidige rentestanden.<br/>De gemiddelde verkoopprijzen is een ongewogen gemiddelde op basis van alle transacties in dat woningtype geregistreerd door het Kadaster. Er wordt dus niet gecorrigeerd voor bijvoorbeeld regio of grootte.</p>" +
            "<p>Het figuur geeft vooral een goede indicatie van de betaalbaarheid van woningen over de tijd heen. In de praktijk gebruiken koopstarters vaak ook eigen geld (vaak schenkingen) en doorstromers de overwaarde op hun vorige woning zodat ze in de praktijk in staat zijn hogere bedragen te betalen dan hun maximale leen capaciteit toe staat.</p>"
    },
    "wm-hoog-inkomen": {
        title: "Wat kan je kopen met een (zeer) hoog inkomen?",
        credit: "CBS, Kadaster, TU Delft",
        html: "<p>De maximale leencapaciteit wordt elk kwartaal berekend op basis van de leennormen van het NIBUD, rekening houdend met de huidige rentestanden.<br/>De gemiddelde verkoopprijzen is een ongewogen gemiddelde op basis van alle transacties in dat woningtype geregistreerd door het Kadaster. Er wordt dus niet gecorrigeerd voor bijvoorbeeld regio of grootte.</p>" +
            "<p>Het figuur geeft vooral een goede indicatie van de betaalbaarheid van woningen over de tijd heen. In de praktijk gebruiken koopstarters vaak ook eigen geld (vaak schenkingen) en doorstromers de overwaarde op hun vorige woning zodat ze in de praktijk in staat zijn hogere bedragen te betalen dan hun maximale leen capaciteit toe staat.</p>"
    },

    "wm-krapte-indicator": {
        title: "Woningen: Krapte-indactor bestaande en nieuwe koopwoningen",
        credit: "TU Delft, Monitor Nieuwe Woningen, NVM",
        html: "<p>Een waarde onder de 5 duidt op een krappe markt, een waarde boven de 6 duidt op een ruimte markt waarbij de consument gemakkelijk een woning kan kopen.</p>"
    },
    "wm-verkooptijd": {
        title: "Woningen: Gemiddelde verkooptijd van een koopwoning",
        credit: "TU Delft, Monitor Nieuwe Woningen, NVM",
        html: ""
    },
    "wm-bevolkingsgroei-ytd": {
        title: "Bevolkingsgroei (YTD)",
        credit: "CBS",
        html: "CBS <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/37230ned/table?dl=68FBD' target='blank'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/37230ned/table?dl=68FBD</a>"
    }
}
