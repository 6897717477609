const component = {
    type: 'Highcharts',
    cell: 'gm-nm-bouwvergunningen-naar-opdrachtgever',
    connector: {
        id: 'bouwvergunningen-naar-opdrachtgever_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'totaal opdrachtgevers': 'y',
        'overheid of woningcorporatie': 'y',
        'bouwers voor de markt': 'y',
        'andere particuliere opdrachtgevers': 'y',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Bouwvergunningen naar opdrachtgever'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true
        }
    }
};

export default component;