<script setup lang="ts">
import {ref} from "vue";
import BaseEnvironmentLoggedIn from "./shared/BaseEnvironmentLoggedIn.vue";

const tabs = ref([
  {name: 'Woningmarkt', path: '/woningmarkt', active: false, hasParam: false},
  {name: 'Economie', path: '/economie', active: false, hasParam: false},
  {name: 'Nieuwbouwmarkt', path: '/nieuwbouwmarkt', active: false, hasParam: false},
  {name: 'Gemeentelijke pagina\'s ', path: '/gemeente', active: false, hasParam: false},
]);

</script>

<template>
    <BaseEnvironmentLoggedIn :tabs="tabs">
      <div class="pb-24">
        <slot />
      </div>
    </BaseEnvironmentLoggedIn>
</template>


<style lang="scss" scoped>

</style>