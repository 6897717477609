<script setup lang="ts">
import {AccountStore} from "../../../stores/accountStore.ts";

const username = AccountStore.username;

</script>

<template>
  <div class="flex flex-col flex-1">
    <h1 class="text-3xl font-semibold text-slate-800 ">
      Welkom {{ username }}
    </h1>
    <span class="mt-4 mb-2 text-sm text-slate-600">
      Welkom op de <b class="font-medium">WoningBouwersNL </b>account omgeving. <br>
      Hier kunt u uw gegevens inzien en wijzigen.
    </span>
  </div>
</template>

<style scoped>

</style>