const component = {
    type: 'Highcharts',
    cell: 'nm-verkopen-per-provincie',
    connector: {
        id: 'verkopen_per_provincie_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        '2023': 'y',
        '2024': 'y',
        provincie: 'x',
    },
    title: {
        useHTML: true,
        text: 'Verkopen per provincie'
    },
    chartOptions: {
        chart: {
            type: 'bar',
            //type: 'column',
        },
        plotOptions: {
            series: {
                //pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
                //colorByPoint: true
            },
        },
        xAxis: {
            type: 'category',
            accessibility: {
                description: 'Provincies'
            }
        },
        yAxis: {
            gridLineWidth: 1,
            lineWidth: 0
        },
        tooltip: {
            //xDateFormat: 'Q%Q %Y',
            shared: true
        }
    }
};

export default component;