const component = {
    cell: 'gm-nm-aanbod',
    type: 'MapPoints',
    connector: {
        id: 'projecten_pool',
    },
    mapOptions: {
        title: 'Huidig aanbod van nieuwbouwprojecten',
        dataName: 'Aantal woningen',
        mapZoom: 6, //Optioneel
        pointColor: '#ed6f36', //Optioneel
        fields: {
            lat: 'x',
            lng: 'y',
            pointLocation: 'gemeentenaam',
            pointName: 'project_titel',
            pointValue: 'koopwoningen',
        },
        allowZoom: false,
        allowPan: true,
    }
};

export default component;
