import {createVNode, render} from 'vue';
import VueMapComponent from '../components/map/MapChoropleth.vue';
import {AccountStore} from "../stores/accountStore.ts";
import {GemeenteStore} from "../stores/gemeenteStore.ts";

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class MapChoroplethComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        //We need to load the data from the datapool
        this.initConnector().then(() => {
            this.load();
        });

        this.chartType = 'map'; // important, all chartType===map have custom callback
        this.type = 'MapChoropleth';
        this.options = options;
        this.filterElement = document.createElement('div');
        this.filterElement.style.height = '98%';
        this.isDataReady = false;
        this.isLoaded = false;

        if (options.mapOptions === undefined) {
            console.error('Choropleth component has no options')
        }

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.poolname = this.cell.mountedComponent.options.connector.id;
            this.load();
            this.isLoaded = true;
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueMapComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    stopRender() {
        this.cell.setLoadingState(true)
        this.parentElement.removeChild(this.element);
        this.cell.setLoadingState(false)
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }

    //Nice that nothingh actually updates when we change the data
    onTableChanged() {


        //
        // MAYBE LOOK AT: https://github.com/highcharts/highcharts/blob/85555313f7abc7dc2df17efa5d771434cde539e1/ts/Dashboards/Plugins/DataGridComponent.ts#L52
        //


        //Visualize that we update the component
        this.cell.setLoadingState(true)
        //unrender the component
        this.stopRender();


        const connector = this.connector;

        //Send new data via event (becaus it is not highcharts but custom)
         window.dispatchEvent(new CustomEvent('mapDataChanged' + this.options.cell, {detail: connector}));

        //ReRender the component
        this.load();
        return;
    }


    load() {
        this.emit({type: 'load'});

        return new Promise((resolve, reject) => {
            this.cell.setLoadingState(true)
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {

                super.load()
                    .then(() => {
                        this.cell.setLoadingState(true)

                        let formattedDexieKey = this.options.mapOptions.dexieKey || 'corop';
                        if (formattedDexieKey.includes('%gmcode%')) {
                            formattedDexieKey = formattedDexieKey.replace('%gmcode%', GemeenteStore.gmcode);
                        }

                        this.renderComponent(this.filterElement, {
                            id: this.options.cell,
                            title: this.options.mapOptions.title,
                            matchSettings: this.options.mapOptions.matchSettings,
                            dataName: this.options.mapOptions.dataName || 'data',
                            connectors: this.connector,
                            colorScale: this.options.mapOptions.colorScale || ['#ef895b', '#ec9670', '#e9a383', '#e7b096', '#e4bca9', '#e7c8b9', '#f1d3c2'],
                            useMinMaxColorScale: this.options.mapOptions.useMinMaxColorScale || false,
                            showLegend: this.options.mapOptions.showLegend,
                            jwt: AccountStore.token,
                            dexieKey: formattedDexieKey,
                            smoothLegend: this.options.mapOptions.smoothLegend || false,
                            legendValueFormatter: this.options.mapOptions.legendFormatter || ((value) => value),
                            mapZoom: this.options.mapOptions.mapZoom || 5.7,
                            fitBounds: this.options.mapOptions.fitBounds || false,
                            showMap: this.options.mapOptions.showMap || false,
                        });


                        this.contentElement.appendChild(this.filterElement);
                        this.parentElement.appendChild(this.element);

                        this.cell.setLoadingState(false)
                    })
                resolve(this);
            } catch (error) {
                reject(error);
            }
        });

    }
}

ComponentRegistry.registerComponent('MapChoropleth', MapChoroplethComponent);