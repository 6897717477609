const component = {
    type: 'Highcharts',
    cell: 'gm-nm-woz-waarde',
    connector: {
        id: 'woz_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'woz waarde': 'woz_waarde',
        'nederland gemiddelde': 'nederland_gemiddelde',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'WOZ-waarde'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: '%Y',
            shared: true
        }
    }
};

export default component;