<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

//Tab imports and setup
import NewAccount from "./tabs/NewAccount.vue";
import NewPassword from "./tabs/NewPassword.vue";
import AccountIndex from "./tabs/AccountIndex.vue";
import AccountEnvironment from "../../components/layouts/AccountEnvironment.vue";

//icons
import {KeyIcon, UserIcon, UsersIcon, CloudArrowUpIcon} from "@heroicons/vue/24/outline";

import {AccountStore} from "../../stores/accountStore.ts";
import AccountsList from "./tabs/AccountsList.vue";
import {PlusIcon} from "@heroicons/vue/24/solid";
import ImportAccount from "./tabs/ImportAccount.vue";

const accessLevel = ref(0);

onMounted(async () => {
  accessLevel.value = await AccountStore.getAccessLevel();
  console.log(accessLevel.value);
});

const accountCategories = ref([
  {
    id: 'default',
    name: '',
    accessLevel: 0,
  },
  {
    id: 'management',
    name: 'Beheer',
    accessLevel: 50,
  },
  {
    id: 'admin',
    name: 'Admin (alleen Xitres)',
    accessLevel: 100,
  }
]);

const accountTabs = ref([
  {
    id: 'overview',
    name: 'Mijn account',
    heroIcon: UserIcon,
    page: AccountIndex,
    category: 'default',
    active: false,
  },
  {
    id: 'new-password',
    name: 'Wachtwoord wijzigen',
    heroIcon: KeyIcon,
    page: NewPassword,
    category: 'default',
    active: false,
  },
  {
    id: 'new-account',
    name: 'Account maken',
    heroIcon: PlusIcon,
    page: NewAccount,
    category: 'management',
    active: false,
  },
  {
    id: 'overview-accounts',
    name: 'Accounts beheren',
    heroIcon: UsersIcon,
    page: AccountsList,
    category: 'management',
    active: false,
  },
  {
    id: 'import-accounts',
    name: 'Accounts importeren',
    heroIcon: CloudArrowUpIcon,
    page: ImportAccount,
    category: 'admin',
    active: false,
  }
]);

const route = useRoute();
const router = useRouter();

const getActiveTabPage = () => {
  const activeTab = accountTabs.value.find((tab) => tab.active);
  return activeTab ? activeTab.page : null;
}

onMounted(() => {
  //We first check this before entering the page. Otherwise, we get a flicker of the page before redirecting.
  if (!AccountStore.isLoggedIn) {
    AccountStore.logout();
    router.push({
      path: '/auth/login',
    });
  }

  if (!route.params.tab) {
    router.push({
      path: '/account',
      params: {tab: 'overview'}
    });
  }
  const tab = route.params.tab ? route.params.tab : 'overview';
  accountTabs.value.forEach((tabItem) => {
    tabItem.active = tabItem.id === tab;
  });
})


const changeTab = (tab: string) => {
  //update url
  window.history.pushState({}, '', `/account/${tab}`);
  //update active tab
  accountTabs.value.forEach((tabItem) => {
    tabItem.active = tabItem.id === tab;
  });
}

</script>

<template>
  <AccountEnvironment>
    <div class="h-full">
      <div class="flex h-full">
        <!-- Sidebar -->
        <div class="flex flex-col w-120 h-full px-8 py-8 bg-white border-r sticky">
          <h2 class="text-3xl font-semibold text-slate-800">Mijn profiel</h2>
          <div class="flex flex-col justify-between flex-1 mt-6">
            <nav>
              <template v-for="category in accountCategories" :key="category.id">
                <div v-show="accessLevel >= category.accessLevel">
                  <!-- Categorie Titel -->
                  <div v-if="category.name" class="px-4 py-2">
                    <h3 class="text-xl font-semibold text-slate-800">{{ category.name }}</h3>
                  </div>
                  <template v-for="tab in accountTabs" :key="tab.id">
                    <template v-if="tab.category === category.id">
                      <template v-if="tab.active">
                        <button class="flex items-center px-4 py-2 rounded mb-1.5 text-slate-800 bg-slate-200 w-full">
                          <component :is="tab.heroIcon" class="w-5 h-5" aria-hidden="true"/>
                          <span class="mx-4 font-medium">{{ tab.name }}</span>
                        </button>
                      </template>
                      <template v-else>
                        <button
                            class="flex w-full items-center px-4 py-2 rounded mb-1.5 text-slate-600 transition-colors duration-200 transform hover:bg-slate-100 hover:text-slate-700"
                            @click="changeTab(tab.id)"
                        >
                          <component :is="tab.heroIcon" class="w-5 h-5" aria-hidden="true"/>
                          <span class="mx-4 font-medium">{{ tab.name }}</span>
                        </button>
                      </template>
                    </template>
                  </template>
                  <!-- Scheidingslijn tussen categorieën -->
                  <hr class="my-6"/>
                </div>

              </template>
            </nav>
          </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-1 p-10">
          <component :is="getActiveTabPage()"/>
        </div>
      </div>
    </div>
  </AccountEnvironment>
</template>


<style scoped>

</style>