const component = {
    cell: 'nm-bouwvergunningen-woonruimten-filter',
    className: '',
    type: 'DateRangeFilter',
    filterOptions: {
        dataPools: ['bouwvergunningen_pool'],
        minSelected: '2018-01-01',
        column: 'datum'
    }
};

export default component;