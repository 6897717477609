<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {Fetcher, FetcherResponse} from "../../utils/fetcher.ts";
import {useRouter} from "vue-router";

const router = useRouter();

const magicCode = ref('');
const magicCodeWasProvidedByUrl = ref(false);
const page = ref(1);

const showErrorMessage = ref(false);
const errorMessage = ref('');

const showSuccessMessage = ref(false);

onMounted(() => {
  // Get the redirect URL from the query string
  const url = new URL(window.location.href);
  //set magic code if it is in the url ?token=
  const token = url.searchParams.get('token');

  if (token !== null) {
    magicCode.value = token;
    magicCodeWasProvidedByUrl.value = true;
    if (validateMagicCode()) {
      page.value = 2;
    } else {
      showErrorMessage.value = true;
      errorMessage.value = 'De url is niet geldig!\n Kopieer de code opnieuw vanuit de e-mail.';
      magicCode.value = '';
      magicCodeWasProvidedByUrl.value = false;
    }
  }
});

const form = ref({
  password: '',
  confirmPassword: ''
});

const validateCodeAndNextpage = () => {
  //remove all spaces from the magic code
  magicCode.value = magicCode.value.replace(/\s/g, '');
  if (!validateMagicCode()) {
    showErrorMessage.value = true;
    errorMessage.value = 'De code is niet geldig!\n Kopieer de code opnieuw vanuit de e-mail.';
    return;
  }

  //add ?token= to the url
  router.push({query: {token: magicCode.value}});

  //reset error message
  showErrorMessage.value = false;
  errorMessage.value = '';
  page.value = 2;
}

/**
 * Returns true if the magic code is valid
 */
const validateMagicCode = () => {
  //check if the magic code is valid, starts with mg_ and than a valid uuidv4
  if (!magicCode.value.startsWith('mg_')) {
    return false;
  }
  const uuidv4 = magicCode.value.split('_')[1];
  if (!uuidv4) {
    return false;
  }

  //if magic code is not 39 (mg_ + 36 uuidv4) characters long
  return magicCode.value.length === 39;

}

const submit = async () => {
  const { password, confirmPassword } = form.value;
  //reset error message
  showErrorMessage.value = false;
  errorMessage.value = '';

  //Password match
  if (password !== confirmPassword) {
    showErrorMessage.value = true;
    errorMessage.value = 'De wachtwoorden komen niet overeen!';
    return;
  }

  //password min 8 characters
  if (password.length < 8) {
    showErrorMessage.value = true;
    errorMessage.value = 'Het wachtwoord moet minimaal 8 karakters bevatten!';
    return;
  }

  if(!validateMagicCode()) {
    showErrorMessage.value = true;
    errorMessage.value = 'De code is niet geldig!\n Kopieer de code opnieuw vanuit de e-mail.';
    return;
  }

  const fetcher = new Fetcher(false);

  const res: FetcherResponse<{ msg: string }> = await fetcher.post('/auth/set-password', {
    password,
    confirmPassword,
    'token': magicCode.value
  })

  if (res.ok) {
    showErrorMessage.value = false;
    showSuccessMessage.value = true;
  } else {
    showErrorMessage.value = true;
    errorMessage.value = res.data.msg;
  }
}

</script>

<template>
  <div class="min-h-screen flex items-center justify-center pattern-background">
    <div class="bg-white p-8 rounded-lg shadow-md w-96">
      <img src="../../assets/woningbouwers_logo.svg" alt="logo" class="w-[50%] mb-4 hidden lg:block">
      <h1 class="text-2xl font-bold mb-4">Uw wachtwoord instellen</h1>
      <p class="text-gray-600 mb-4 text-xs" v-if="!magicCodeWasProvidedByUrl">U heeft een wachtwoord instel code ontvangen. Vul deze in samen met uw nieuwe wachtwoord.</p>
      <p class="text-gray-600 mb-4 text-xs" v-else>U kunt nu een wachtwoord instellen voor uw account.</p>

      <div class="my-5">
        <div v-if="showErrorMessage" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong class="font-bold">Let op! </strong> <br>
          <span class="block text-sm sm:inline">{{ errorMessage }}</span>
        </div>
        <div v-if="showSuccessMessage" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative cursor-pointer" role="alert">
          <strong class="font-bold">Gelukt! </strong> <br>
          <span class="block text-sm sm:inline">Uw wachtwoord is ingesteld. <br>  U kunt nu inloggen. </span>
          <a class="underline block text-sm sm:inline" @click="router.push('/auth/login')">klik hier</a>
        </div>

      </div>
      <form @submit.prevent="submit">
        <div v-if="page == 1">
          <h4 class="text-gray-600 font-bold mb-2">
            Wachtwoord token <br><span class="text-xs">Ontvangen via e-mail</span>
          </h4>
          <div class="mb-4" v-if="!magicCodeWasProvidedByUrl">
            <label for="magic_code" class="block text-sm font-medium text-gray-600">Uw token:</label>
            <input type="text" id="magic_code" v-model="magicCode" class="mt-1 p-2 w-full border rounded-md">
          </div>
        </div>
        <div v-if="page == 2 && !showSuccessMessage">
          <h4 class="text-gray-600 font-bold mb-2">
            Uw nieuwe wachtwoord instellen
          </h4>
          <div class="mb-4">
            <label for="password" class="block text-sm font-medium text-gray-600">Wachtwoord</label>
            <input type="password" id="password" v-model="form.password" class="mt-1 p-2 w-full border rounded-md">
          </div>
          <div class="mb-4">
            <label for="passwordConfirm" class="block text-sm font-medium text-gray-600">Bevestig Wachtwoord</label>
            <input type="password" id="passwordConfirm" v-model="form.confirmPassword" class="mt-1 p-2 w-full border rounded-md">
          </div>
        </div>
        <button type="button" @click="validateCodeAndNextpage" class="w-full p-2 bg-wbnl-blue hover:bg-wbnl-blue-dark rounded-md text-white font-bold" v-if="page == 1">
          Naar wachtwoord instellen
        </button>
        <button type="submit" class="w-full p-2 bg-wbnl-orange hover:bg-wbnl-orange-light rounded-md text-white font-bold" v-if="page == 2 && !showSuccessMessage">
          Wachtwoord instellen
        </button>
        <div class="mt-4">
          <router-link to="/login" class="text-sm text-gray-600 hover:text-gray-500 hover:underline">Heb je al een account? Log dan hier in.</router-link>
        </div>
      </form>
    </div>
  </div>
</template>


<style scoped>

</style>