const component = {
    cell: 'ec-woninghypotheken-staatslening-filter',
    className: '',
    type: 'MonthRangeFilter',
    filterOptions: {
        dataPools: ['hypotheekrente_staatslening_pool'],
        minSelected: '2020-01-01',
        column: 'datum'
    }
};

export default component;