export default {
    layouts: [{
        id: 'layout-1',
        rows: [
            //1e rij - kaart en qf
            {
                id: 'row-1',
                cells: [
                    {
                        id: 'gm-nm-aanbod',
                        height: '560px',
                        width: '4/5'
                    }, {
                        id: 'gm-nm-quickfact',
                        height: '560px',
                        width: '1/5'
                    }]
            },
            //2e rij 1 bar chart
            {
                id: 'row-2',
                cells: [
                    {
                        id: 'gm-nm-objecten-per-project',
                        height: '560px',
                        width: '1/2'
                    }, {
                        id: 'gm-nm-bouwvergunningen-naar-opdrachtgever',
                        width: '1/2',
                        height: '560px'
                    }]
            },
            //4e rij - buurtinformatie
            {
                id: 'row-4',
                cells: [
                    {width: '1/2'},
                    {
                        id: 'gm-nm-woningbezit-grafiek-filter',
                        width: '1/2',
                        height: '70px'
                    },
                    {
                        id: 'gm-nm-woningbezit-kaart',
                        width: '1/2',
                        height: '560px'
                    }, {
                        id: 'gm-nm-woningbezit-grafiek',
                        width: '1/2',
                        height: '560px'
                    }
                ]
            },
            //5e rij
            {
                id: 'row-5',
                cells: [
                    {
                        id: 'gm-nm-woningvoorraad-filter',
                        width: '2/4',
                        height: '70px'
                    },
                    {width: '1/4'},
                    {
                        id: 'gm-nm-woz-waarde-buurt-filter',
                        width: '1/4',
                        height: '70px'
                    },
                    {
                        id: 'gm-nm-woningvoorraad',
                        width: '2/4',
                        height: '560px'
                    },
                    {
                        id: 'gm-nm-woz-waarde',
                        width: '1/4',
                        height: '560px'
                    }, {
                        id: 'gm-nm-woz-waarde-buurt',
                        width: '1/4',
                        height: '560px'
                    }]
            }
        ]
    }]
}
