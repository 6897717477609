const component = {
    type: 'Highcharts',
    cell: 'nm-verkooptijden-verkochte-appartementen',
    connector: {
        id: 'verkooptijden_verkochte_appartementen'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        goedkoop: 'y',
        middelduur: 'y',
        duur: 'y',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Verkooptijden verkochte appartementen'
    },
    chartOptions: {
        subtitle: {
            text: 'In dagen, nieuwbouw naar prijsklassen',
            align: 'left',
            y: -15,
            x: -8
        },
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true
        }
    }
};

export default component;