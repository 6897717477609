const component = {
    cell: 'wm-krapte-indicator',
    connector: {
        id: 'krapte_indicator_pool'
    },
    columnAssignment: {
        datum: 'x',
        "nieuwe woningen": 'y',
        "bestaande woningen": 'y',
        "evenwichtsituatie": 'y',
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Krapte-indicator bestaande en nieuwe koopwoningen'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true,
            valuePrefix: '€ ',
        }
    }
};

export default component;