export default {
    "ec-quickfact-1": {
        title: "Inflatie",
        credit: "CBS",
        html: "<p>Voor gedetailleerde data en uitleg, zie: <a target='_blank' href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83131NED/table'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/83131NED/table</a></p>"
    },
    "ec-quickfact-2": {
        title: "Werkloosheid",
        credit: "CBS",
        html: "<p>Het werkloosheid percentage geeft een goede indicatie voor zowel de staat van de economie als de potentiele doelgroep voor nieuwbouw koopwoningen. Nuttig is ook om hier dieper in te duiken, bijvoorbeeld door de ontwikkeling van het aantal vaste banen te analyseren. Dat kan via: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82201' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82201</a></p>"
    },
    "ec-quickfact-3": {
        title: "Hypotheekrente",
        credit: "DNB",
        html: "<p>Dit betreft de gemiddelde hypotheekrente over alle afgesloten hypotheken voor de koop van een nieuwe woning.<br/>Voor actuele rentestanden zie: <a href='https://www.independer.nl/hypotheek/rente.aspx' target='_blank'>https://www.independer.nl/hypotheek/rente.aspx</a><br/>Voor een actuele analyse van de hypotheek aanvragen, zie: <a href='https://www.hdn.nl/marktcijfers/' target='_blank'>https://www.hdn.nl/marktcijfers/</a></p>"
    },

    "ec-bbp-werkloosheid": {
        title: "Economie en werkloosheid",
        credit: "CBS",
        html: "<p><strong>Economie</strong><br/>Dit betreft de economische groei/krimp op basis van het bbp vanuit de productie (gecorrigeerd voor werkdagen). Verandering volume ten opzichte van zelfde periode vorig jaar. Voor gedetailleerde data en uitleg, zie: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=81209' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=81209</a></p>" +
            "<br/><p><strong>Werkloosheidspercentage</strong><br/>Het werkloosheid percentage (seizoen gecorrigeerd) geeft een goede indicatie voor zowel de staat van de economie als de potentiele doelgroep voor nieuwbouw koopwoningen. Nuttig is ook om hier dieper in te duiken, bijvoorbeeld door de ontwikkeling van het aantal vaste banen te analyseren. Dat kan via: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82201' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82201</a></p>"
    },
    "ec-consumenten-woningmarkt_vertrouwen": {
        title: "Consumentenvertrouwen en woningmarktvertrouwen",
        credit: "CBS, Vereniging Eigen Huis",
        html: "<p><strong>Consumentenvertrouwen</strong><br/>Elke maand stelt het CBS een groot aantal vragen aan consumenten. Het samenvattende cijfer van deze enquête is het consumentenvertrouwen. Ziet de meerderheid van de consumenten het positief in, dan is het consumentenvertrouwen positief.  En, andersom, ziet de meerderheid van de consumenten het negatief in, dan is het consumentenvertrouwen negatief. Andere vragen van deze enquête kunnen ook erg interessant zijn om de economie beter te begrijpen, deze zijn beschikbaar via <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/zoeken?searchKeywords=consumentenvertrouwen&page=1&theme%5B%5D=177' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/zoeken?searchKeywords=consumentenvertrouwen&page=1&theme%5B%5D=177</a></p>" +
            "<br/><p><strong>Woningmarktvertrouwen</strong><br/>In opdracht van de Vereniging Eigen Huis zet de TU Delft elke maand een vragenlijst uit waarbij het vertrouwen van consumenten in de woningmarkt gemeten wordt. Het samenvattende cijfer van deze enquête is het woningmarktvertrouwen. Om deze indicator vergelijkbaar te maken met het consumentenvertrouwen hebben we 100 afgetrokken van de officiële VEH-woningmarktindicator. Dit betekent dat wanneer de meerderheid van de consumenten het positief inziet, het woningmarktvertrouwen positief is.  En, andersom, ziet de meerderheid van de consumenten het negatief in, dan is het woningmarktvertrouwen negatief. Voor meer informatie zie: <a href='https://www.eigenhuis.nl/woningmarkt/eigen-huis-marktindicator#' target='_blank'>https://www.eigenhuis.nl/woningmarkt/eigen-huis-marktindicator#</a></p>"
    },
    "ec-woninghypotheken-staats-lening": {
        title: "Rentestanden",
        credit: "DNB",
        html: "<p><strong>Hypotheekrente</strong><br/>Dit betreft de gemiddelde hypotheekrente over alle afgesloten hypotheken voor de koop van een nieuwe woning.<br/>Voor actuele rentestanden zie: <a href='https://www.independer.nl/hypotheek/rente.aspx' target='_blank'>https://www.independer.nl/hypotheek/rente.aspx</a><br/>Voor een actuele analyse van de hypotheek aanvragen, zie: <a href='https://www.hdn.nl/marktcijfers/' target='_blank'>https://www.hdn.nl/marktcijfers/</a></p>" +
            "<br /><p><strong>Rente op nieuwe staatsleningen</strong><br/>Dit betreft de rente op door de Nederlandse staat afgesloten obligaties met een looptijd van 10 jaar.<br/>Zowel een Nederlandse hypotheek als een Nederlandse staatsobligatie wordt door beleggers gezien als een (zeer) veilige belegging. Wanneer het verschil tussen beide rente standen dus groot is, is er in theorie ruimte voor lagere hypotheekrentes.</p>"
    }
}
