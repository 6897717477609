import werkloosheidChart from "./components/werkloosheid.chart.ts";
import opleidingGrafiek from "./components/opleiding-grafiek.chart.ts";
import opleidingGrafiekFilter from "./components/opleiding-grafiek.filter.ts";
import huishoudenWoningzoekende from "./components/huishouden-woningzoekende.chart.ts";
import inschrijversPerPrijsklasse from "./components/inschrijvers-per-prijsklasse.chart.ts";
import inschrijversPerWoningtype from "./components/inschrijvers-per-woningtype.chart.ts";
import koopOfHuur from "./components/koop-of-huur.chart.ts";
import BevolkingsdichtheidPerBuurtChart from "./components/bevolkingsdichtheid-per-buurt.chart.ts";
import OpleidingNaarBuurtKaartChart from "./components/opleiding-naar-buurt-kaart.chart.ts";


export const importAll = () => {
    return [
        werkloosheidChart,
        opleidingGrafiek,
        opleidingGrafiekFilter,
        huishoudenWoningzoekende,
        inschrijversPerPrijsklasse,
        inschrijversPerWoningtype,
        koopOfHuur,
        BevolkingsdichtheidPerBuurtChart,
        OpleidingNaarBuurtKaartChart
    ]
}