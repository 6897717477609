const component = {
    cell: 'wm-landkaart-bevolkingsgroei',
    connector: {
        id: 'bevolkingsgroei_kaart_pool',
    },
    type: 'MapChoropleth',
    mapOptions: {
        title: 'Bevolkingsgroei',
        dataName: 'Bevolkingsgroei %',
        minDefault: -1, //-2.5 oude default
        maxDefault: 1, //5 oude default
        matchSettings: {
            poolKey: "regio", //DataPool key
            topoKey: "gemeentecode", //TopoJson key (geojson properties)
            valueRow: "percentage", // The datapool column that contains the values (needed for the legend and min/max values)
            locationRow: "gemeentenaam" // The datapool column that contains the location names
        },
        dexieKey: 'gemeenten',
        useMinMaxColorScale: true, // Automatically calculates a color scale based on the min/max values in the datapool
        showLegend: true,
        smoothLegend: false,
        legendFormatter: (value: number) => {
            return value;
            //return `${value / 1000}K`;
        }
    }
};

export default component;