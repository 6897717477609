<script setup lang="ts">
import { ref } from 'vue';
import {Fetcher} from "../../utils/fetcher.ts";
import MainEnvironment from "../../components/layouts/MainEnvironment.vue";

const email = ref('');

const showError = ref(false);
const showSuccess = ref(false);

const handleReset = async () => {
  const fetcher = new Fetcher(false);
  //send forget password request
  const res = await fetcher.post('/auth/reset-password', {
    email: email.value,
  });

  if (res.ok) {
    //Show success message
    showSuccess.value = true;
  } else {
    showError.value = true;
  }
}
</script>

<template>
  <MainEnvironment>
    <div class="flex items-center justify-center pattern-background">
      <div class="bg-white p-8 rounded-lg shadow-md w-96">
        <img src="../../assets/woningbouwers_logo.svg" alt="logo" class="w-[50%] mb-4 hidden lg:block">
        <h1 class="text-2xl font-bold mb-4">Uw wachtwoord herstellen</h1>
        <p class="text-gray-600 text-xs mb-4">
          Vul uw e-mail adres in en ontvang een reset link om uw wachtwoord te wijzigen.
        </p>

        <div class="my-5">
          <div v-if="showError" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong class="font-bold">Let op! </strong> <br>
            <span class="block text-sm sm:inline">
            Er is iets misgegaan. Probeer het opnieuw.
          </span>
          </div>
          <div v-if="showSuccess" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative cursor-pointer" role="alert">
            <strong class="font-bold">Gelukt! </strong> <br>
            <span class="block text-sm sm:inline">
            Als uw e-mail adres bekend is, ontvangt u binnen enkele minuten een e-mail met een link om uw wachtwoord te resetten.
          </span>
          </div>

        </div>
        <form @submit.prevent="handleReset">
          <div class="mb-4">
            <label for="email" class="block text-sm font-medium text-gray-600">Uw email:</label>
            <input type="email" id="email" v-model="email" class="mt-1 p-2 w-full border rounded-md">
          </div>
          <button type="submit" class="w-full p-2 bg-wbnl-orange hover:bg-wbnl-orange-light rounded-md text-white font-bold">
            Wachtwoord herstellen
          </button>
        </form>
        <div class="mt-4 flex justify-between">
          <router-link to="/auth/login" class="text-sm text-gray-600 hover:text-gray-500 hover:underline">
            Wilt u inloggen?
          </router-link>

        </div>
      </div>
    </div>
  </MainEnvironment>
</template>


<style scoped>

</style>