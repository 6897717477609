const component = {
    cell: 'ec-bbp-werkloosheid-filter',
    className: '',
    // type: 'DateRangeFilter',
    type: 'MonthRangeFilter',
    filterOptions: {
        dataPools: ['bbp_werkloosheid_pool'],
        minSelected: '2015-01-01',
        column: 'datum'
    }
};

export default component;