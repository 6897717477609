export default {
    "gm-vn-werkloosheid": {
        title: "Werkloosheid ",
        credit: "CBS",
        html: "<p>Werkloosheidspercentage. Het werkloosheid percentage (seizoen gecorrigeerd) geeft een goede indicatie voor zowel de staat van de economie als de potentiele doelgroep voor nieuwbouw koopwoningen. Nuttig is ook om hier dieper in te duiken, bijvoorbeeld door de ontwikkeling van het aantal vaste banen te analyseren. Dat kan via: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=52180' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=52180</a></p>"
    },
    "gm-vn-potentiele-doelgroep": {
        title: "Huishoudens: vermogen en inkomen",
        credit: "CBS",
        html: "<p>Via de CBS data is het mogelijk om op verschillende manieren het inkomen en vermogen van huishoudens te analyseren. Een voor Woningbouwers erg nuttige manier is om huishoudens onder te verdelen naar huiseigenaren en huurders (met/zonder huurtoeslag). Deze grafiek geeft daarmee een goed gevoel bij de koopkracht van de potentiele doelgroep voor nieuwbouwwoningen. <br/><br/>" +
            "<br/><br/>" +
            "De data is gebaseerd op de belasting-aangiften en loopt daardoor altijd wat ‘achter’. Echter, over het algemeen veranderen inkomens en vermogens niet heel hard over de jaren heen. <br/><br/>" +
            "Verdere uitleg (en de mogelijkheid om met andere variabelen analyses te maken: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84866NED/table?dl=48C4B'>https://opendata.cbs.nl/statline/#/CBS/nl/dataset/84866NED/table?dl=48C4B</a>)</p>"
    },
    "gm-vn-opleiding-naar-buurt-kaart": {
        title: "Opleidingsniveau per buurt",
        credit: "CBS",
        html: "<p></p>"
    },
    "gm-vn-verdeling-opleidingsniveau": {
        title: "Opleidingsniveau naar buurt",
        credit: "CBS",
        html: "<p></p>"
    },
    "gm-vn-huishoudensamenstelling": {
        title: "Huishoudensamenstelling: wie wonen er nu in deze gemeente?",
        credit: "CBS",
        html: "<p>Dit figuur laat de huidige bevolking zien verdeeld naar leeftijd (horizontale as) en soort huishoudens (verticale as). Dit maakt het mogelijk om beter inzicht te krijgen in de vraag naar (koop)woningen. Hoe groot is bijvoorbeeld de groep thuiswonende 30plussers? Is deze groep groot genoeg voor projecten met betaalbare starterswoningen? </p>"
    },
    "gm-vn-huishoudensgroei-prognose": {
        title: "Huishoudens prognose: hoe denkt het CBS dat het aantal huishoudens zich in de gemeente gaat ontwikkelen?",
        credit: "CBS",
        html: "<p>Niemand kan in de toekomst kijken, en met open Europese grenzen is de bevolkingsgroei onmogelijk te voorspellen. Toch doet het CBS dit wel elk jaar, en zit er ook elk jaar weer (volledig) naast, vooral omdat de migratie verkeerd wordt ingeschat. Desalniettemin spelen deze bevolkingsprognoses een grote rol in bijvoorbeeld de woningbouwplannen van lokale overheden. En deze woningbouwplannen bepalen vervolgens hoeveel/weinig huishoudens in een gemeente kunnen wonen, zeker in tijden van een groot woningtekort en lage woningbouwproductie. <br/><br/>" +
            "Wij raden dan ook aan om altijd kritisch naar deze huishoudensprognoses op gemeenteniveau te kijken en ze zeker niet als waarheid te nemen. De actuele situatie, de huidige kwalitatieve en kwantitatieve behoefte aan (koop)woningen dient veel zwaarder te wegen.  De prognose is ook in meer detail beschikbaar met meer uitleg over de methoden van het CBS via; <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=84605' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=84605</a></p>"
    },
    "gm-vn-demografische-kerngetallen": {
        title: "Hoe heeft de bevolking zich ontwikkeld?",
        credit: "CBS",
        html: "<p>Deze figuur laat de bevolkingsontwikkeling zien. Het voordeel van deze data is dat deze het meest actueel en gedetailleerd is. Het laat ook mooi de dynamiek zien binnen de gemeente; verhuizen er bijvoorbeeld veel 30ers en 40ers naar de gemeente dan is deze waarschijnlijk (erg) aantrekkelijk voor gezinnen. Ook kan het interessant zijn om de trends op lange termijn in beeld te brengen. Dat kan via: <a href='https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82387' target='_blank'>https://opendata.cbs.nl/statline/#/CBS/nl/navigatieScherm/thema?themaNr=82387</a> <br/><br/>" +
            "<br/><br/>" +
            "Het nadeel is dat deze bewegingen niet  1 op 1 te vertalen zijn naar de vraag naar (nieuwe) woningen. Immers, in een extreem geval kan de bevolking krimpen maar de vraag naar woningen juist toenemen door huishoudensverdunning. Dit is een extreem geval, maar toch moeten deze demografische cijfers altijd kritisch ge-analyseerd worden en toegepast op de lokale situatie. <br/><br/>" +
            "<br/><br/>" +
            "<b>Groei/krimp</b><br/><br/>" +
            "Berekend door de stand van de totale bevolking van de gemeente aan het eind van de periode af te trekken van de stand van de totale bevolking van de gemeente aan het begin van de periode <br/><br/>" +
            "            <br/><br/>" +
            "<b>Saldo geboorte/sterfte</b> <br/><br/>" +
            "Het aantal geboorten over de periode minus het aantal sterfgevallen.</p>"
    },
    "gm-vn-bevolkingsdichtheid-per-buurt": {
        title: "Bevolkingsdichtheid per buurt",
        credit: "CBS",
        html: "<p></p>"
    },
    "gm-vn-inschrijvers-per-prijsklasse": {
        title: "Inschrijvingen voor nieuwbouwprojecten Naar welke prijsklassen zijn actieve woningzoekende op zoek? ",
        credit: "Nieuwbouw Nederland",
        html: "<p>Dit figuur is gebaseerd op het totaal aantal inschrijvingen op het actuele aanbod van nieuwbouwprojecten in deze gemeente via websites van Nieubouw Nederland. Niet alle nieuwbouw koopwoningen worden verkocht via websites van Nieuwbouw Nederland. De dekking wisselt sterk per gemeente maar over heel Nederland is ongeveer 2/3e  van het aantal verkochte nieuwbouw koopwoningen aangeboden geweest op een Nieuwbouw Nederland website. <br/><br/>" +
            "<br/><br/>" +
            "Op de websites van Nieuwbouw Nederland vullen woningzoekenden een dertigtal vragen in. Dit maakt het mogelijk om ze in te delen in verschillende scoiaal economische groepen. Ook is het mogelijk om te kijken naar welk budget woning ze zoeken en wat hun opleidingsniveau is. Op deze manier wordt de actuele vraag naar nieuwbouwwoningen zeer nauwkeurig in beeld gebracht. </p>"
    },
    "gm-vn-inschrijvers-per-woningtype": {
        title: "Inschrijvingen voor nieuwbouwprojecten Naar welke woningtypen zijn woningzoekende op zoek? ",
        credit: "Nieuwbouw Nederland",
        html: "<p>Dit figuur is gebaseerd op het totaal aantal inschrijvingen op het actuele aanbod van nieuwbouwprojecten in deze gemeente via websites van Nieubouw Nederland. Niet alle nieuwbouw koopwoningen worden verkocht via websites van Nieuwbouw Nederland. De dekking wisselt sterk per gemeente maar over heel Nederland is ongeveer 2/3e  van het aantal verkochte nieuwbouw koopwoningen aangeboden geweest op een Nieuwbouw Nederland website. <br/><br/>" +
            "<br/><br/>" +
            "Op de websites van Nieuwbouw Nederland vullen woningzoekenden een dertigtal vragen in. Dit maakt het mogelijk om ze in te delen in verschillende scoiaal economische groepen. Ook is het mogelijk om te kijken naar welk budget woning ze zoeken en wat hun opleidingsniveau is. Op deze manier wordt de actuele vraag naar nieuwbouwwoningen zeer nauwkeurig in beeld gebracht. </p>"
    },
    "gm-vn-huur-of-koop": {
        title: "",
        credit: "Nieuwbouw Nederland",
        html: "<p></p>"
    },
    "gm-vn-huishouden-woningzoekende": {
        title: "",
        credit: "Nieuwbouw Nederland",
        html: "<p></p>"
    }
}