const component = {
    type: 'Highcharts',
    cell: 'nm-bouwvergunningen-woonruimten',
    connector: {
        id: 'bouwvergunningen_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'aantal afgelopen 12 maand': 'y',
        datum: 'x'
    },
    legend: {
        enabled: false
    },
    title: {
        useHTML: true,
        text: 'Bouwvergunningen woonruimten|Woningen'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',

        },
        tooltip: {
            shared: true
        }
    }
};

export default component;