const component = {
    cell: 'wm-landkaart-nieuwbouw-cumulatief',
    connector: {
        id: 'nieuwbouw_cumulatief_pool',
    },
    type: 'MapChoropleth',
    mapOptions: {
        title: 'Nieuwbouw (cumulatief komende 5 jaren)',
        dataName: 'Aantal woningen',
        matchSettings: {
            poolKey: "corop", //DataPool key
            topoKey: "coropnaam", //TopoJson key (geojson properties)
            valueRow: "aantal", // The datapool column that contains the values (needed for the legend and min/max values)
            locationRow: "coropnaam" // The datapool column that contains the location names
        },
        dexieKey: 'corop',
        colorScale: ['#f1d3c2', '#e7c8b9', '#e4bca9', '#e7b096', '#e9a383', '#ec9670', '#ef895b'],
        showLegend: true,
        smoothLegend: true,
        legendFormatter: (value: number) => {
            if (value >= 1000) {
                return `${value / 1000}k`;
            } else if (value <= -1000) {
                return `${value / 1000}k`;
            } else {
                return value;
            }
        }
    }
};

export default component;
