const component = {
    type: 'Highcharts',
    cell: 'gm-vn-huur-of-koop',
    connector: {
        id: 'huur_of_koop_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        categorie: 'x',
        percentage: 'y'
    },
    title: {
        useHTML: true,
        text: 'Willen de woningzoekers een huur- of koopwoning?'
    },
    chartOptions: {
        chart: {
            type: 'bar',
        },
        yAxis: {
            labels: {
                format: '{text}%'
            },
        },
        xAxis: {
            type: 'category'
        },
        tooltip: {
            valueSuffix: '%',
        }
    }
};

export default component;