import {Parsers} from "../../utils/parsers.ts";
import {baseUri} from "../../config/config.ts";

//
// ℹ️ Information about %gmcode%:
// %gmcode% is a placeholder that will be replaced by the current gmcode
// so "/charts/gm-nm/quickfacts?gmcode=%gmcode%" will become /charts/gm-nm/quickfacts?gmcode=GM0014 for example
//


export default [
    {
        type: 'JSONAuth',
        id: 'quickfacts_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/quickfacts?gmcode=%gmcode%')
        }
    },
    {
        type: 'JSONAuth',
        id: 'projecten_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/projecten?gmcode=%gmcode%')
        }
    },
    {
        type: 'JSONAuth',
        id: 'bouwvergunningen-naar-opdrachtgever_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/bouwvergunningen-naar-opdrachtgever?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woningbezit_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/woningbezit?gmcode=%gmcode%'),
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'categorie',
                    minValue: 'Koopwoningen',
                    maxValue: 'Koopwoningen'
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woningbezit_categorie_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/woningbezit-categorie?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woz_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/woz?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woz_naar_buurten_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/woz-naar-buurten?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                //Replace jaar_
/*
                dataset[0] = dataset[0].map((field: any) => {
                    return field.replace(/jaar_/g, '');
                });
*/

                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'buurtcode',
                    minValue: '',
                    maxValue: ''
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woningvoorraad_pool',
        options: {
            dataUrl: (baseUri + '/charts/gm-nm/woningvoorraad?gmcode=%gmcode%'),
            beforeParse: function (dataset: any) {
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'woningtype',
                    minValue: 'Totaal woningen',
                    maxValue: 'Totaal woningen'
                }]
            }
        }
    }
];