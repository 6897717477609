const component = {
    type: 'Highcharts',
    cell: 'gm-nm-objecten-per-project',
    connector: {
        id: 'projecten_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        project_titel: 'x',
        koopwoningen: 'y'
    },
    title: {
        useHTML: true,
        text: 'Aantal te koop staande woningen in nieuwbouwprojecten'
    },
    chartOptions: {
        chart: {
            type: 'bar',
        },
        xAxis: {
            type: 'category'
        }
    },
    tooltip: true,
};

export default component;