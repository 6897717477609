const component = {
    type: 'Highcharts',
    cell: 'ec-bbp-werkloosheid',
    connector: {
        id: 'bbp_werkloosheid_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        bbp: 'y',
        werkloosheid: 'y',
        datum: 'x'
    },
    title: {
        text: 'BBP en werkloosheidspercentage'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        yAxis: {
            labels: {
                formatter: function (): string {
                    // @ts-ignore
                    return this.axis.defaultLabelFormatter.call(this) + '%';
                }
            }
        },
        xAxis: {
            type: 'datetime',
            //tickInterval: 7 * 24 * 36e5, // one week
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true,
            valueSuffix: '%',
        }
    }
};

export default component;