<script setup>
import Loader from 'vue-spinner/src/GridLoader.vue';

//Iets te veel tijd over terwijl ik op mike wachtte, dus ik heb een motd loader gemaakt

const motd = [
  "Grafieken worden gegenereerd en geoptimaliseerd...",
  "Geografische data inladen en verwerken...",
  "Data visualisatie wordt opgestart...",
  "Databronnen worden gekoppeld...",
  "Berekeningen worden uitgevoerd...",
  "Charts worden gekoppeld...",
  "Geografische data aan kaart koppelen...",
];


const randomMotd = motd[Math.floor(Math.random() * motd.length)]

</script>

<template>
<div class="loading-container">
  <h1 class="title">
    {{ randomMotd }}
  </h1>
    <Loader color="#ed6f36" size="32px" margin="16px"></Loader>
</div>

</template>

<style lang="scss" scoped>
.loading-container {
  background-color: #f5f5f5;
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #65615f;
}


</style>