<script setup lang="ts">
import {useRouter} from "vue-router";
import {Fetcher} from "../../../utils/fetcher.ts";
import {computed, onMounted, ref} from "vue";
import {AccountStore} from "../../../stores/accountStore.ts";
import ConfirmModal from "../../../components/modals/ConfirmModal.vue";
import {ConfirmType} from "../../../utils/ConfirmModal.ts";

type User = {
  id: number,
  username: string,
  archived: boolean,
}

const router = useRouter();
const fetcher = new Fetcher(true);
const search = ref('');

const users = ref<User[]>([]);
const filteredUsers = computed(() => {
  return users.value.filter(user => user.username.includes(search.value));
});

onMounted(async () => {
  const access: number = await AccountStore.getAccessLevel();
  if (access < 50) {
    await router.push('/404');
  }
});

const getUsers = async () => {
  const response = await fetcher.get('/users');
  if (response.status === 200) {
    users.value = response.data as User[];
  }
}

/**
 * fetch users
 */
const reloadUsers = async () => {
  users.value = [];
  await getUsers();
}

/**
 * Reset password
 */

//Vars
const showResetModal = ref(false);
const resetUserId = ref(0);
/**
 * Reset password modal show
 */
const reset = (userId: number) => {
  showResetModal.value = true;
  resetUserId.value = userId;
}
/**
 * Reset password fetch
 */
const resetConfirm = (id: number) => async () => {
  await fetcher.post('/users/' + id + '/reset-password', {});
}


/**
 * Deactivate account
 */
const showDeactivateModal = ref(false);
const deactivateUserId = ref(0);
/**
 * Deactivate account modal show
 */
const deactivate = (userId: number) => {
  showDeactivateModal.value = true;
  deactivateUserId.value = userId;
}
/**
 * Deactivate account fetch
 */
const deactivateConfirm = (id: number) => async () => {
  await fetcher.post('/users/' + id + '/deactivate', {});
  await reloadUsers();
}

/**
 * Re-active account
 */
const showReactivateModal = ref(false);
const reactivateUserId = ref(0);
/**
 * Reactivate account modal show
 */
const reactivate = (userId: number) => {
  showReactivateModal.value = true;
  reactivateUserId.value = userId;
}
/**
 * Reactivate account fetch
 */
const reactivateConfirm = (id: number) => async () => {
  await fetcher.post('/users/' + id + '/activate', {})
  await reloadUsers();
}


getUsers();

</script>

<template>
  <ConfirmModal :open="showResetModal" :confirm-action="resetConfirm(resetUserId)" confirm-name="Reset wachtwoord"  :type="ConfirmType.info"
                @close="args => showResetModal = args">
    <template #title>
      Wachtwoord resetten
    </template>
    <template #content>
      Weet u zeker dat u het wachtwoord van deze gebruiker wilt resetten? <br> <br>
      De gebruiker ontvangt dan een email met een nieuw wachtwoord. U hoeft verder niets te doen.
    </template>
  </ConfirmModal>

  <ConfirmModal :open="showDeactivateModal" :confirm-action="deactivateConfirm(deactivateUserId)" :type="ConfirmType.alert" confirm-name="Maak account inactief"
                @close="args => showDeactivateModal = args">
    <template #title>
      Account deactiveren
    </template>
    <template #content>
      Weet u zeker dat u dit account wilt deactiveren? <br> <br>
      De gebruiker kan dan niet meer inloggen op het Dashboard.
    </template>
  </ConfirmModal>

  <ConfirmModal :open="showReactivateModal" :confirm-action="reactivateConfirm(reactivateUserId)" confirm-name="Opnieuw activeren" :type="ConfirmType.success"
                @close="args => showReactivateModal = args">
    <template #title>
      Account opnieuw activeren
    </template>
    <template #content>
      Weet u zeker dat u dit account weer wilt activeren? <br> <br>
      De gebruiker kan dan weer inloggen op het Dashboard.
    </template>
  </ConfirmModal>


  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-3xl font-semibold text-slate-800 ">
          Overzicht accounts
        </h1>
        <span class="mt-4 mb-2 text-sm text-slate-600">
          Hieronder vindt u een overzicht van alle accounts van dit Dashboard. <br>
          <span class="font-medium text-xl mt-3">Wachtwoord herstellen</span> <br>
          U kunt hier de wachtwoorden van de accounts resetten. De gebruiker ontvangt dan een email met een nieuw wachtwoord. <br> <br>

          <span class="font-medium text-xl mt-3">Account deactiveren</span> <br>
          Als u een account inactief maakt, kan de gebruiker niet meer inloggen op het Dashboard. <br>
          Als u een account weer actief maakt, kan de gebruiker weer zoals gebruikelijk met de gewenste gegevens inloggen op het Dashboard. <br>
          <span class="font-medium">
          Voor verwijderingsverzoeken neem contact op met <a href="mailto:data@xitres.nl" class="text-indigo-600">data@xitres.nl</a>
          </span>

        </span>
      </div>
    </div>
    <div class="mt-6 flow-root">
      <div class="mb-3">
        <h4 class="text-sm font-medium text-gray-900 mb-1 ml-1">
          Zoek een account
        </h4>
        <label for="search" class="sr-only">Zoek op email</label>
        <input type="text" name="search" id="search" class="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
               placeholder="Zoek op email of domein..." aria-describedby="search-description" v-model="search">
      </div>
      <div class="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-[5rem] overflow-scroll" style="height: 58vh">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  ID
                </th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Email
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">Acties</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="user in filteredUsers" :key="user.username">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {{ user.id }}
                </td>


                <!-- If inactive strike through -->
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <span :class="{
                  'line-through text-red-800': user.archived
                }">
                  {{ user.username }}
                  </span>
                  <span v-if="user.archived" class="text-red-800"> (inactief account)</span>
                </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex flex-row justify-end">
                  <div class="mx-2" v-if="!user.archived && user.username != AccountStore.username" >
                    <button @click="reset(user.id)" class="text-white hover:text-gray-50 bg-slate-600 hover:bg-indigo-600 p-1 rounded">
                      Reset wachtwoord
                    </button>
                  </div>
                  <div class="mx-2" v-show="user.username != AccountStore.username">
                    <button v-if="!user.archived" @click="deactivate(user.id)" class="text-white hover:text-gray-50 bg-slate-500 hover:bg-red-600 p-1 rounded transition-all">
                      Account inactief maken
                    </button>
                    <button v-else @click="reactivate(user.id)" class="text-white hover:text-gray-50 bg-slate-500 hover:bg-green-700 p-1 rounded transition-all">
                      Account weer activeren
                    </button>
                  </div>
                  <div class="mx-2" v-show="user.username == AccountStore.username">
                    <span class="text-slate-500 text-xs">
                      Dit is je eigen account. <br>
                      <span class="text-[10px]">Neem contact op met
                        <a :href="'mailto:data@xitres.nl?subject=Admin%20account%20wijzigen&body=Account%20ID%3A%20' + user.id" class="text-indigo-600"> Xitres </a>
                        voor account wijzigingen.</span>
                    </span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>