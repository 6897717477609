import quickfact1Chart from './components/quickfact-1.chart.ts';
import quickfact2Chart from './components/quickfact-2.chart.ts';
import quickfact3Chart from './components/quickfact-3.chart.ts';
import quickfact4Chart from './components/quickfact-4.chart.ts';
import verkochteWoningenChart from './components/verkochte-woningen.chart.ts';
import verkochteWoningenFilter from './components/verkochte-woningen.filter.ts';
import gemiddeldeVerkoopprijsChart from './components/gemiddelde-verkoopprijs.chart.ts';
import woningenOntwikkelingVraagEnAanbod from './components/woningen-ontwikkeling-vraag-en-aanbod.chart.ts';
import landkaartTekortOverschot from './components/landkaart-tekort-overschot.chart.ts';
import landkaartTekortOverschotFilter from './components/landkaart-tekort-overschot.filter.ts';
import landkaartBevolkingsgroei from './components/landkaart-bevolkingsgroei.chart.ts';
import landkaartNieuwbouwCumulatief from './components/landkaart-nieuwbouw-cumulatief.chart.ts';
import kopenModaalInkomen from './components/kopen-modaal-inkomen.chart.ts';
import kopenHoogInkomen from './components/kopen-hoog-inkomen.chart.ts';
import krapteIndicator from './components/krapte-indicator.chart.ts';
import gemiddeldeVerkooptijd from './components/gemiddelde-verkooptijd.chart.ts';
import bevolkingsgroeiYtd from './components/bevolkingsgroei-ytd.chart.ts';
import bevolkingsgroeiYtdFilter from './components/bevolkingsgroei-ytd.filter.ts';

/**
 * This function will return all charts, so you don't need to import them on the page itself.
 * Its just one step less work.
 *
 * @returns {object} All charts
 */
export const importAll = () => {
    return [
        quickfact1Chart,
        quickfact2Chart,
        quickfact3Chart,
        quickfact4Chart,
        verkochteWoningenChart,
        verkochteWoningenFilter,
        gemiddeldeVerkoopprijsChart,
        woningenOntwikkelingVraagEnAanbod,
        landkaartTekortOverschot,
        landkaartTekortOverschotFilter,
        landkaartBevolkingsgroei,
        landkaartNieuwbouwCumulatief,
        kopenModaalInkomen,
        kopenHoogInkomen,
        krapteIndicator,
        gemiddeldeVerkooptijd,
        bevolkingsgroeiYtd,
        bevolkingsgroeiYtdFilter
    ]
}