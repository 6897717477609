import { Parsers } from "../../utils/parsers.ts";
import {baseUri} from "../../config/config.ts";

export default [
    {
        type: 'JSONAuth',
        id: 'quickfacts_1_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/quickfacts?fact=1')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_2_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/quickfacts?fact=2')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_3_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/quickfacts?fact=3')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_4_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/quickfacts?fact=4')
        }
    },
    {
        type: 'JSONAuth',
        id: 'verkochte_woningen_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/verkochte-woningen'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'soort',
                    minValue: 'bestaande',
                    maxValue: 'bestaande'
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'gemiddelde_verkoopprijs_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/gemiddelde-verkoopprijs'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'soort',
                    minValue: 'bestaande',
                    maxValue: 'bestaande'
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'woningtekort_overschot_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/woning-tekort-overschot'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'jaar',
                    minValue: '2023',
                    maxValue: '2023'
                }]
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'bevolkingsgroei_kaart_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/bevolkingsgroei-kaart?year=2023'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'nieuwbouw_cumulatief_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/nieuwbouw-cumulatief'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'vraag_en_aanbod_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/vraag-en-aanbod'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'kopen_modaal_inkomen_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/kopen-modaal-inkomen'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'kopen_hoog_inkomen_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/kopen-hoog-inkomen'),
            beforeParse: function(dataset: any){
                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'krapte_indicator_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/krapte-indicator'),
            beforeParse: function(dataset: any){
                dataset[0] = Parsers.nameParser(dataset[0]);
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'gemiddelde_verkooptijd_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/gemiddelde-verkooptijd'),
            beforeParse: function(dataset: any){
                dataset[0] = Parsers.nameParser(dataset[0]);
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    },
    {
        type: 'JSONAuth',
        id: 'bevolkingsgroei_pool',
        options: {
            dataUrl: (baseUri + '/charts/wm/bevolkingsgroei'),
            beforeParse: function(dataset: any){
                dataset[0] = Parsers.nameParser(dataset[0]);
                dataset = Parsers.dateParser(dataset);
                return dataset;
            },
            dataModifier: {
                type: 'Range',
                ranges: [{
                    column: 'regio titel',
                    minValue: 'Drenthe (PV)',
                    maxValue: 'Drenthe (PV)'
                }]
            }
        }
    }
]