<script setup lang="ts">
import {onMounted, ref} from "vue";
import BaseEnvironmentLoggedIn from "./shared/BaseEnvironmentLoggedIn.vue";
import {GemeenteStore} from "../../stores/gemeenteStore.ts";

//TODO: Check if needed
defineProps({
  gmcode: {
    type: String,
    required: true,
  },
});

const tabs = ref([
  {name: 'Nieuwbouwmarkt', path: '/gemeente/nieuwbouwmarkt', active: false, hasParam: true},
  {name: 'Vraag naar nieuwbouw', path: '/gemeente/vraag-naar-nieuwbouw', active: false, hasParam: true},
  {name: 'Terug naar landelijk', path: '/', active: false, hasParam: false},
]);

const gemeenteNaam = ref(' ')

onMounted(() => {
//Fetch gemeente naam online
  GemeenteStore.getGemeenteNaam().then((naam) => {
    gemeenteNaam.value = naam;
  });
});



</script>

<template>
  <BaseEnvironmentLoggedIn :tabs="tabs" :param="gmcode">
    <template #extra-title>
      <div class="flex h-full ms-3">
        <div class="inline-flex items-center pt-1 ">
          <span class="text-lg text-wbnl-blue-dark font-medium">Gemeente {{ gemeenteNaam }}</span>
        </div>
        <div class="ms-8 me-2 my-4 border-r-2 border-wbnl-gray-200"></div>
      </div>

    </template>
    <div class="pb-24">
      <slot/>
    </div>
  </BaseEnvironmentLoggedIn>
</template>


<style lang="scss" scoped>

</style>