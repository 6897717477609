<script setup lang="ts">
// Vue imports
import {onBeforeMount, onBeforeUnmount} from "vue";
import {useRoute} from "vue-router";

// Store imports
//@ts-ignore
import {useTooltipStore} from '/src/stores/pinia/tooltipStore';
import {GemeenteStore} from "../stores/gemeenteStore.ts";
//@ts-ignore
import {useSubtitleStore} from '/src/stores/pinia/subtitleStore.ts';

// Component imports
import HighchartsDashboard from "../components/highcharts/HighchartsDashboard.vue";
import DashboardGemeenteEnvironment from "../components/layouts/DashboardGemeenteEnvironment.vue";

// Data and GUI imports
import datapools from "../charts/gm-vn/gm-vn.datapools.ts";
import gui from "../charts/gm-vn/gm-vn.gui.ts";
import * as Charts from '../charts/gm-vn/charts';
import Tooltips from '../charts/gm-vn/gm-vn.tooltips.ts';

const dashboardConfig = {
  dataPool: {
    connectors: datapools,
  },
  gui: gui,
  components: Charts.importAll(),
}

const route = useRoute();
onBeforeMount(() => {
  // @ts-ignore (Dit werkt wel)
  GemeenteStore.setGmcode(route.params.gemeente)
  GemeenteStore.initGemeenteGeoData()
})

onBeforeUnmount(() => {
  GemeenteStore.unsetGmcode()
})

const tooltipStore = useTooltipStore()
tooltipStore.setTooltips(Tooltips)

// Subtitle Store init
const subtitleStore = useSubtitleStore()
subtitleStore.setSubtitles('gm-vraagnaarnieuwbouw')

</script>

<template>
  <DashboardGemeenteEnvironment :gmcode="GemeenteStore.gmcode">
    <HighchartsDashboard page-id="gemeente-vraag-naar-nieuwbouw" :dashboard-options="dashboardConfig"/>
  </DashboardGemeenteEnvironment>
</template>

<style scoped>

</style>