const component = {
    cell: 'nm-verkopen-per-provincie-filter',
    className: 'test2',
    type: 'SelectFilter',
    label: 'Type woning:',
    filterOptions: {
        filters: [
            {
                key: 'Appartement',
                label: 'Appartement'
            },
            {
                key: 'Eengezinswoning',
                label: 'Eengezinswoning'
            },

        ],
        dataPools: ['verkopen_per_provincie_pool'],
        column: 'woningtype'
    }
};

export default component;