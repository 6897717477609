//! DO NOT CHANGE THIS FILE, if you forget to change it back CI/CD will fail!
//
// READ ME FIRST
// If you want to use LOCAL API:
// 1. Set VITE_APP_USE_LOCAL_API=true in .env file
// 2. Set VITE_APP_LOCAL_API_ENDPOINT=http://localhost:3030/api in .env file
//

const useLocal = import.meta.env.VITE_APP_USE_LOCAL_API === "true" || false;

export const baseUri = useLocal ? import.meta.env.VITE_APP_LOCAL_API_ENDPOINT : "https://api.data.xitres.io/api";