<script setup>
import { defineProps, defineEmits } from 'vue';
import Slider from '@vueform/slider';

// Props
const props = defineProps({
    min: {
        type: Number,
        required: true
    },
    max: {
        type: Number,
        required: true
    },
    step: {
        type: Number,
        required: false,
        default: 1
    },
    selected: {
        type: Array,
        required: true
    },
    format: {
        type: Function,
        required: false,
        default: (v) => v
    },
    merge: {
        type: Number,
        required: false,
        default: -1
    }
});

const emit = defineEmits(['update']);

const onSliderInput = (value) => {
    emit('update', { min: value[0], max: value[1] });
};
</script>

<template>
    <div class="slider-container pt-8 px-12">
        <Slider
            :inline="true"
            :min="min"
            :max="max"
            :merge="merge"
            :value="selected"
            :format="format"
            @input="onSliderInput"
        />
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
.slider-container {
    height: 40px;
    margin: 0 16px;
}
</style>