const component = {
    cell: 'nm-quickfact-1',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_1_pool',
    },
    className: 'highcharts-dashboards-component h-auto quickfact',
    tableOptions: {
        title: 'Verkooptijd appartement',
        th: 'datum_tekst',
        td: 'waarde',
        reverse: false
    }
};

export default component;