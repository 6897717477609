//Vue router configuration
import {createRouter, createWebHistory, Router, RouteRecordRaw} from "vue-router";
import LoginPage from "../pages/auth/LoginPage.vue";
import NotFoundPage from "../pages/NotFoundPage.vue";
import WoningmarktPage from "../pages/WoningmarktPage.vue";
import EconomiePage from "../pages/EconomiePage.vue";
import NieuwbouwmarktPage from "../pages/NieuwbouwmarktPage.vue";
import GemeenteSelectPage from "../pages/GemeenteSelectPage.vue";
import AccountIndexPage from "../pages/account/AccountIndexPage.vue";
import GemeenteNieuwbouwmarktPage from "../pages/GemeenteNieuwbouwmarktPage.vue";
import GemeenteVraagNaarNieuwbouwPage from "../pages/GemeenteVraagNaarNieuwbouwPage.vue";
import PasswordSetupPage from "../pages/auth/PasswordSetupPage.vue";

// @ts-ignore (We need to import the store to check if the user is logged in)
import {AccountStore} from "../stores/accountStore.ts";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        alias: [
            ''
        ],
        name: 'Home',
        redirect: {name: 'Woningmarkt'},
        meta: {requiresAuth: true}
    },
    {
        path: '/auth/login',
        alias: [
            '/auth',
            '/login'
        ],
        name: 'Login',
        component: LoginPage,
        meta: {checkAlreadyLoggedIn: true, requiresAuth: false}
    },
    {
      path: '/auth/reset-password',
        name: 'Wachtwoord vergeten',
        component: ResetPasswordPage,
        meta: {checkAlreadyLoggedIn: true, requiresAuth: false}
    },
    {
        path: '/auth/create-password',
        name: 'Wachtwoord instellen',
        component: PasswordSetupPage,
        meta: {checkAlreadyLoggedIn: true, requiresAuth: false}

    },
    {
        path: '/account/:tab?',
        name: 'Account',
        component: AccountIndexPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/woningmarkt',
        name: 'Woningmarkt',
        component: WoningmarktPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/economie',
        name: 'Economie',
        component: EconomiePage,
        meta: {requiresAuth: true}
    },
    {
        path: '/nieuwbouwmarkt',
        name: 'Nieuwbouwmarkt',
        component: NieuwbouwmarktPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/gemeente',
        name: 'Gemeente Selectie',
        component: GemeenteSelectPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/gemeente/nieuwbouwmarkt/:gemeente',
        name: 'Gemeente Nieuwbouwmarkt',
        component: GemeenteNieuwbouwmarktPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/gemeente/vraag-naar-nieuwbouw/:gemeente',
        name: 'Gemeente vraag naar nieuwbouw',
        component: GemeenteVraagNaarNieuwbouwPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/:pathMatch(.*)*', //catch all 404
        name: 'NotFound',
        component: NotFoundPage
    }
];

const router: Router = createRouter({
    history: createWebHistory(),
    routes: routes
});

//@ts-ignore (ignore unused from param)
router.beforeEach((to, from, next) => {

    // Check if the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if NO token is set
        if (!AccountStore.isTokenSet) {
            next({
                path: '/auth/login',
            });
        } else {
            next(); // Go to the route
        }
    } else if (to.matched.some(record => record.meta.checkAlreadyLoggedIn)) {
        // if someone is already logged in, redirect to home (we don't want to show the login page if someone is already logged in)
        if (AccountStore.isTokenSet) {
            next({
                path: '/',
            });
        } else {
            next(); // Go to the route
        }
    } else {
        next(); // Go to the route
    }
});


export default router;