const component = {
    cell: 'ec-quickfact-3',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_3_pool',
    },
    className: 'highcharts-dashboards-component quickfact',
    tableOptions: {
        title: 'Hypotheekrente',
        th: 'datum_tekst',
        td: 'waarde',
        reverse: false,
        keyFormatter: (k: string): string => {
            return k.replace('-', ' ').replace(/^./, str => str.toUpperCase());
        }
    },
};

export default component;