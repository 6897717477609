const component = {
    cell: 'wm-verkochte-woningen-filter',
    className: 'test2',
    type: 'SelectFilter',
    label: 'Soort bouw:',
    filterOptions: {
        filters: [
            {
                key: 'bestaande',
                label: 'Bestaande'
            },
            {
                key: 'nieuwbouw',
                label: 'Nieuwbouw'
            }
        ],
        dataPools: ['verkochte_woningen_pool', 'gemiddelde_verkoopprijs_pool'],
        column: 'soort'
    }
};

export default component;