import { createVNode, render } from 'vue';
import VueMapPointsComponent from '../components/map/MapPoint.vue';

const {
    Component,
    ComponentRegistry,
} = Dashboards;


class MapPointsComponent extends Component {
    constructor(cell, options) {
        super(cell, options);
        //We need to load the data from the datapool
        this.initConnector().then(() => {
            this.load();
        });

        this.chartType = 'map'; // important, all chartType===map have custom callback
        this.type = 'MapPoints';
        this.options = options;
        this.filterElement = document.createElement('div');
        this.filterElement.style.height = '98%';
        this.isDataReady = false;
        this.isLoaded = false;

        if (options.mapOptions === undefined) {
            console.error('Point map component has no options')
        }

        this.init

        //we load the data when the event is triggered
        window.addEventListener('dashboardDatapoolReady', () => {
            if (this.isDataReady) return;
            this.isDataReady = true;
            this.load();
            this.isLoaded = true;
        });


        return this;
    }

    renderComponent(el, props) {
        const vnode = createVNode(VueMapPointsComponent, props);
        render(vnode, el);

        return () => {
            // Cleanup wanneer nodig
            render(null, el);
        };
    }

    resize(width, height) {
        super.resize.call(this, width, height);
        this.filterElement.setAttribute('width', width - 10);
        this.filterElement.setAttribute('height', height - 10);
    }



    load() {
        return new Promise((resolve, reject) => {
            this.cell.setLoadingState(true)
            if (!this.isDataReady) return resolve("no render needed yet!");
            try {

                super.load()
                    .then(() => {

                        this.cell.setLoadingState(true);

                        this.renderComponent(this.filterElement, {
                            id: this.options.cell,
                            connectors: this.connector,
                            title: this.options.mapOptions.title || 'Map Points',
                            dataName: this.options.mapOptions.dataName || 'data',
                            mapZoom: this.options.mapOptions.mapZoom || 5.7,
                            mapCenter: this.options.mapOptions.mapCenter || [5.2913, 52.1326],//[lng, lat]
                            fields: this.options.mapOptions.fields || {
                                lat: 'x',
                                lng: 'y',
                                pointLocation: 'pointLocation',
                                pointName: 'pointName',
                                pointValue: 'pointValue',
                            },
                            pointColor: this.options.mapOptions.pointColor || '#ed6f36',
                            allowZoom: this.options.mapOptions.allowZoom || false,
                            allowPan: this.options.mapOptions.allowPan || false,
                            fitPoints: this.options.mapOptions.fitPoints,
                        });


                        this.contentElement.appendChild(this.filterElement);
                        this.parentElement.appendChild(this.element);

                        this.cell.setLoadingState(false);
                    })
                resolve(this);
            } catch (error) {
                reject(error);
            }
        });

    }
}

ComponentRegistry.registerComponent('MapPoints', MapPointsComponent);