<script setup>
import {onMounted, ref} from "vue";
import {GeolocateControl, LngLatBounds, Map, NavigationControl, Popup} from "maplibre-gl";
import {dataPoolToGeoJson} from "../../utils/mapHelper.ts";
import {useTooltipStore} from "../../stores/pinia/tooltipStore.ts";
import * as mapboxgl from "maplibre-gl";

const tooltipStore = useTooltipStore()


const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  dataName: {
    type: String,
    required: true
  },
  connectors: {
    type: Object,
    required: true
  },
  mapZoom: {
    type: Number,
    default: 7
  },
  fields: {
    type: Object,
    required: true,
    default: () => {
      return {
        lat: [],
        lng: [],
        pointLocation: [],
        pointName: [],
        pointValue: []
      }
    }
  },
  pointColor: {
    type: String,
    default: '#ef895b'
  },
  allowZoom: {
    type: Boolean,
    default: true
  },
  allowPan: {
    type: Boolean,
    default: true
  },
  mapCenter: {
    type: Array,
    default: () => [5.2913, 52.1326]
  },
  fitPoints: {
    type: Boolean,
    default: true
  }
})


const mapContainerId = ref('point-map-' + Math.random().toString(36).substr(2, 9));
const map = ref(null);

const featureCollection = ref(dataPoolToGeoJson(props.connectors.table.columns, props.fields));

const componentTitle = ref(null)


//Empty info
const isEmpty = ref(false)


onMounted(() => {
  if (mapContainerId.value === null) return;

  //check if container is findable, if not, wait a bit
  if (document.getElementById(mapContainerId.value) === null) {
    setTimeout(() => {
      if (document.getElementById(mapContainerId.value) === null) {
        console.warn('Map container not found, maybe it is not ready yet?', mapContainerId.value)
        return;
      }
    }, 1000)
  }

  // setup tooltips
  tooltipStore.setupTooltipDom(componentTitle.value, props.id)

  //wait short for all refs to be set
  setTimeout(() => {

    map.value = new Map({
      container: mapContainerId.value,
      // style: 'empty.json', // style URL //TODO: Get style from Xitres Style Service (vps13 ofzo)
      style: 'https://maps2.nbo.nl/styles/xitres-bright-light/style.json',
      center: props.mapCenter, // starting position [lng, lat]
      zoom: props.mapZoom // starting zoom
    });

    map.value.scrollZoom.disable();

    //disable zooming
    if (!props.allowZoom) {
      map.value.addControl(new mapboxgl.NavigationControl({
        showCompass: false
      }), 'top-right');
    }

    //disable moving map with mouse and any other interaction
    if (!props.allowPan) {
      map.value.dragPan.disable();
      map.value.dragRotate.disable();
      map.value.doubleClickZoom.disable();
      map.value.touchZoomRotate.disable();
      map.value.touchPitch.disable();
      map.value.keyboard.disable();
    }


    //max bounds is NL
    map.value.setMaxBounds([
      [2.5, 50.5],
      [8.5, 54.5]
    ]);


    //After fetching data we can add the source and layer

    map.value.on('load', () => {

      // Add a geojson point source.
      map.value.addSource('points', {
        'type': 'geojson',
        'data': featureCollection.value
      });

      // Add a symbol layer.
      map.value.addLayer({
        'id': 'points',
        'type': 'circle',
        'source': 'points',
        'paint': {
          'circle-radius': 5,
          'circle-color': props.pointColor,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': '#eeeeee'
        }
      });

      // Add a layer showing the places.
      map.value.addLayer({
        'id': 'places',
        'type': 'symbol',
        'source': 'points',
        'layout': {
          'icon-image': 'marker-15',
          'icon-allow-overlap': true
        }
      });

      //BBOX to fit all points
      if (props.fitPoints) {
        if(featureCollection.value.features[0].geometry.coordinates == ',') {
          isEmpty.value = true
        } else {
          const bounds = featureCollection.value.features.reduce((bounds, feature) => {
            return bounds.extend(feature.geometry.coordinates);
          }, new LngLatBounds(
              featureCollection.value.features[0].geometry.coordinates,
              featureCollection.value.features[0].geometry.coordinates
          ));

          // Fit map to bounds
          map.value.fitBounds(bounds, {
            padding: 40,
            maxZoom: 13
          });
        }
      }

      // Create a popup, but don't add it to the map yet.
      const popup = new Popup({
        closeButton: false,
        closeOnClick: false
      });

      //show popup on click
      map.value.on('mousemove', (e) => {
        const features = map.value.queryRenderedFeatures(e.point, {
          layers: ['points']
        });

        if (!features.length) {
          map.value.getCanvas().style.cursor = '';
          popup.remove();
          return;
        }

        const feature = features[0];
        if (feature.properties['name'] === undefined) return;

        popup.setLngLat(e.lngLat).setHTML(`
            <div class="point-popup">
              <h6 class='popup-title'>${feature.properties['name']}</h6>
              <span class='popup-location'>${feature.properties['location']}</span>
              <br>
              <span class="popup-key">${props.dataName}</span> <span class="popup-value">${feature.properties['value']}</span>
            </div>
          `).addTo(map.value);
        //set cursor to cursor-pointer
        map.value.getCanvas().style.cursor = features.length ? 'default' : '';

      })

    });

  }, 10);
});

</script>

<template>
  <div class="map-container-dashboard highcharts-dashboards-component px-4 h-100">
    <h2 class="highcharts-dashboards-component-title flex justify-between items-center w-full" ref="componentTitle">
      {{ props.title }}
    </h2>
    <div v-if="isEmpty" class="max-width">
      <div class="empty-info">
        <h3>Er zijn geen punten op de map gevonden</h3>
      </div>
    </div>
    <div :id="mapContainerId" class="map">
    </div>
  </div>
</template>

<style scoped lang="scss">
//! ALL POPUP STYLES are in styles/_map.scss (needs global scope)

.map {
  width: 100%;
  height: 100%;
}

.max-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.map-container-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: .5rem;
  z-index: 1;

  //dont allow selection
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  h3 {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }
}

</style>
