<script setup>
import {ref, defineProps, defineEmits, onMounted} from 'vue';
import {Fetcher} from "../../utils/fetcher.ts";
import {GemeenteStore} from "../../stores/gemeenteStore.ts";

// Props
const props = defineProps({
  label: {
    type: String,
    required: false
  },
  options: {
    type: Array,
    required: true
  },
  selected: {
    required: false
  },
  enableApi: {
    type: Boolean,
    required: false,
    default: false
  },
  apiOptions: {
    type: Object,
    required: false,
  },
});

const selectedValue = ref(props.selected);

const selectOptions = ref(props.options);

const emit = defineEmits(['change']);

const onUpdateSelect = (event) => {
  selectedValue.value = event.target.value;
  emit('update', selectedValue.value);
};


onMounted(async () => {
  if (props.enableApi) {
    const fetcher = new Fetcher(true);

    let url = props.apiOptions.url;

    if (url.includes('%gmcode%')) {
      url = url.replace('%gmcode%', GemeenteStore.gmcode);
    }

    const res = await fetcher.get(url);

    if (res.ok) {

      res.data.forEach((item) => {
        console.log("item", item)
        selectOptions.value.push({
          key: item[props.apiOptions.key],
          label: item[props.apiOptions.label]
        });
      });
    }
  }
});
</script>

<template>
    <div v-if="options.length" class="select-container">
        <label v-if="label"
               class="block ml-1 text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
        <select class="bg-white rounded-md border-0 ml-1 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                @change="onUpdateSelect">
            <template v-for="(opt) in selectOptions">
                <option :value="opt.key">{{ opt.label }}</option>
            </template>
        </select>
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" scoped>
.slider-container {
    height: 40px;
    margin: 0 16px;
}
</style>