export default {
    layouts: [{
        id: 'layout-1',
        rows: [
            //1e rij (4 KPI's)
            {
                id: 'row-1',
                cells: [
                    {
                        id: 'ec-quickfact-1',
                        height: '186px'
                    }, {
                        id: 'ec-quickfact-2',
                        height: '186px'
                    }, {
                        id: 'ec-quickfact-3',
                        height: '186px'
                    }]
            },
            //2e rij (2 grote charts)
            {
                id: 'row-2',
                cells: [
                    {
                        id: 'ec-bbp-werkloosheid-filter',
                        width: '33%',
                        height: '55px'
                    },
                    {
                        id: 'ec-consumenten-woningmarkt-vertrouwen-filter',
                        width: '33%',
                        height: '55px'
                    },
                    {
                        id: 'ec-woninghypotheken-staatslening-filter',
                        width: '33%',
                        height: '55px'
                    },
                    {
                        id: 'ec-bbp-werkloosheid',
                        width: '33%',
                        height: '460px'
                    },
                    {
                        id: 'ec-consumenten-woningmarkt_vertrouwen',
                        width: '33%',
                        height: '460px'
                    },
                    {
                        id: 'ec-woninghypotheken-staats-lening',
                        width: '33%',
                        height: '460px'
                    },

                ]
            }
        ]
    }]
}
