export default {
    "nm-quickfact-1": {
        title: "Verkooptijd, appartement ",
        credit: "TU Delft, Monitor Nieuwe Woningen",
        html: "<p>Als start van de verkoop van een project nemen we de datum waarop de eerste woning van dat project wordt verkocht (we weten niet wanneer woningen feitelijk te koop zijn, maar als er één verkocht is moeten ze te koop staan). Als verkoopdatum geldt de datum waarop de koopovereenkomst (of de koop/aannemingsovereenkomst) van een woning wordt getekend. De tijd tussen start verkoop en de verkoopdatum is de verkooptijd.  <br/><br/>" +
            "In de Thermometer worden uitgebreidere, regionale en historische analyses gegeven met o.a. de verkooptijden.</p>"
    },
    "nm-quickfact-2": {
        title: "Verkooptijd, eengezinswoning",
        credit: "TU Delft, Monitor Nieuwe Woningen",
        html: "<p>Als start van de verkoop van een project nemen we de datum waarop de eerste woning van dat project wordt verkocht (we weten niet wanneer woningen feitelijk te koop zijn, maar als er één verkocht is moeten ze te koop staan). Als verkoopdatum geldt de datum waarop de koopovereenkomst (of de koop/aannemingsovereenkomst) van een woning wordt getekend. De tijd tussen start verkoop en de verkoopdatum is de verkooptijd.  <br/><br/>" +
            "In de Thermometer worden uitgebreidere, regionale en historische analyses gegeven met o.a. de verkooptijden.</p>"
    },
    "nm-quickfact-3": {
        title: "Aantal verkochte appartementen",
        credit: "Bouwgarant, SWK en Woningborg",
        html: "<p>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>"
    },
    "nm-quickfact-4": {
        title: "Aantal verkochte eengezinswoningen",
        credit: "Bouwgarant, SWK en Woningborg",
        html: "<p>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>"
    },
    "nm-verkopen-per-provincie": {
        title: "Aantal verkochte nieuwbouw koopwoningen, YTD",
        credit: "Bouwgarant, SWK en Woningborg",
        html: "<p>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>"
    },
    "nm-nieuwbouwverkopen-naar-woningtype": {
        title: "Aantal verkochte nieuwbouw koopwoningen, historische reeks ",
        credit: "Bouwgarant, SWK en Woningborg ",
        html: "<p>Om een hypotheek te krijgen voor het bouwen van een nieuwbouw woning is een afbouwgarantie verplicht. Bij faillissement weet de koper/bank van de koper zo zeker dat de woning ook wordt afgebouwd. In Nederland domineren vooral SWK en Woningborg de markt voor afbouw garanties. Samen met Bouwgarant zijn ze goed voor circa 95% van alle afgegeven afbouwgaranties. Maandelijks delen zij hun data met WoningBouwersNL zodat deze grafiek een zeer actueel overzicht geeft over het aantal verkochte nieuwbouw koopwoningen wat sowieso afgebouwd wordt. Deze data is een stuk actueler en nauwkeuriger dan de data over nieuwbouw van het CBS aangezien het CBS zich op enquêtes onder projectontwikkelaars baseert.</p>"
    },
    "nm-kaart-huidig-aanbod": {
        title: "Actueel nieuwbouw aanbod: Nieuwbouwprojecten aangeboden via Nieuwbouw Nederland ",
        credit: "Nieuwbouw Nederland",
        html: "<p>Op deze kaart zie je alle projecten die momenteel via websites van Nieuwbouw Nederland worden aangeboden. Deze projecten bevinden zich in verschillende fases, van eerste melding van het plan tot afgeronde verkoop. <br/><br/>" +
            "<br/><br/>" +
            "Niet alle nieuwbouw koopwoningen worden verkocht via websites van Nieuwbouw Nederland. De dekking wisselt sterk per gemeente maar over heel Nederland is ongeveer 2/3e  van het aantal verkochte nieuwbouw koopwoningen aangeboden geweest op een Nieuwbouw Nederland website</p>"
    },
    "nm-bouwvergunningen-woonruimten": {
        title: "Bouwvergunningen woonruimte",
        credit: "CBS",
        html: "<p>CBS code: CBS_83668NED</p>"
    },
    "nm-huidig-aanbod-naar-woningtype": {
        title: "Huidige aanbod naar woningtype",
        credit: "Nieuwbouw Nederland",
        html: ""
    },
    "nm-huidig-aanbod-naar-projectgrootte": {
        title: "Huidige aanbod naar projectgrootte",
        credit: "Nieuwbouw Nederland",
        html: ""
    },
    "nm-huidig-aanbod-naar-prijscategorie": {
        title: "Huidige aanbod naar prijscategorie",
        credit: "Nieuwbouw Nederland",
        html: ""
    },
    "nm-verkooptijden-verkochte-appartementen": {
        title: "Verkooptijden verkochte appartementen",
        credit: "TU Delft, Monitor Nieuwe Woningen",
        html: ""
    },
    "nm-verkooptijden-verkochte-eengezinswoningen": {
        title: "Verkooptijden verkochte Eengezinswoning",
        credit: "TU Delft, Monitor Nieuwe Woningen",
        html: ""
    }
}