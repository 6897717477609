import {GeoDataManager} from '../utils/geoDataManager.ts'

const geoDataManager = new GeoDataManager();

const dexieLoader = async () => {

    geoDataManager.addGeoDataItem('gemeenten', '/topo/gemeenten.geo.json?url');
    geoDataManager.addGeoDataItem('corop', '/topo/corop.geo.json?url');

    geoDataManager.loadAllData();
}

/**
 * Init gemeente geo data
 * @param gemeenteCode Gemeente code
 *
 * @return {boolean} True if added, false if already exists
 */
const initGemeente = async (gemeenteCode: string) : Promise<boolean> => {
    return geoDataManager.addGeoDataBuurten(gemeenteCode);
}

export {dexieLoader, initGemeente};