const component = {
    cell: 'wm-quickfact-2',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_2_pool',
    },
    className: 'highcharts-dashboards-component h-auto quickfact',
    tableOptions: {
        title: 'Verkochte bestaande koopwoningen',
        th: 'datum_tekst',
        td: 'waarde',
        reverse: false,
    }
};

export default component;