const component = {
    cell: 'wm-bevolkingsgroei-ytd',
    connector: {
        id: 'bevolkingsgroei_pool'
    },
    columnAssignment: {
        'jaar': 'x',
        'geboorte': 'y',
        'sterfte': 'y',
        'immigratie': 'y',
        'emigratie': 'y',
        'verhuizingen uit de provincie naar de provincie': 'y'
        //bevolkingsgroei: 'y'
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Bevolkingsgroei'
    },
    chartOptions: {
        chart: {
            type: 'column'
        },
        xAxis: {
            type: 'category',
            accessibility: {
                description: 'Jaar'
            }
        },
        yAxis: {
            stackLabels: {
                enabled: false // totalen boven/onder tonen
            }
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },

    }
};

export default component;