<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon, InformationCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import {ConfirmType} from "../../utils/ConfirmModal.ts";

const props = defineProps({
  open: Boolean,
  confirmAction: Function,
  confirmName: String,
  type: {
    type: String,
    default: ConfirmType.alert
  }
})
const open = ref(props.open);

const emit = defineEmits(['close'])

const executeConfirm = () => {
  props.confirmAction?.();
  close();
}

const close = () => {
  emit('close', false);
  open.value = false;
}

watch(() => props.open, (value) => open.value = value);

// Deze computed property bepaalt de stijl en het icoon op basis van het type
const typeStyles = computed(() => {
  switch (props.type) {
    case ConfirmType.alert:
      return { color: 'red', Icon: ExclamationTriangleIcon };
    case ConfirmType.warning:
      return { color: 'yellow', Icon: ExclamationTriangleIcon }; // Aanpassen indien een specifiek waarschuwingsicoon beschikbaar is
    case ConfirmType.info:
      return { color: 'indigo', Icon: InformationCircleIcon };
    case ConfirmType.success:
      return { color: 'green', Icon: CheckCircleIcon };
    default:
      return { color: 'gray', Icon: ExclamationTriangleIcon };
  }
});
</script>


<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute top-0 right-0 hidden pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="close">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10" :class="`bg-${typeStyles.color}-100`">
                  <component :is="typeStyles.Icon" :class="`h-7 w-7 text-${typeStyles.color}-600`" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    <slot name="title"/>
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      <slot name="content"/>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="inline-flex justify-center rounded-md px-4 py-2 text-base font-medium shadow-sm" :class="`bg-${typeStyles.color}-600 text-white hover:bg-${typeStyles.color}-700`" @click="executeConfirm">{{ props.confirmName }}</button>
                <button type="button" class="mt-3 mr-2 inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto" @click="close">Annuleer</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>


<style scoped>

</style>