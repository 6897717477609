const component = {
    cell: 'gm-vn-verdeling-opleidingsniveau-filter',
    className: 'test2',
    type: 'MultiSelectFilter',
    label: 'Buurt(en):',
    filterOptions: {
        filters: [
            {key: 'SHOWALL', label: 'Alles'},
        ],
        enableApi: true,
        apiOptions: {
            url: '/utils/buurten?gmcode=%gmcode%',
            key: 'buurtcode',
            label: 'buurtnaam'
        },
        dataPools: ['opleiding_categorie_pool'],
        column: 'buurtcode'
    }
};

export default component;