<script setup lang="ts">
import {ref} from "vue";
import BaseEnvironmentLoggedIn from "./shared/BaseEnvironmentLoggedIn.vue";

const tabs = ref([
  {name: 'Mijn Account', path: '/account', active: false, hasParam: false},
  {name: 'Terug naar dashboard', path: '/woningmarkt', active: false, hasParam: false},
]);

</script>

<template>
  <BaseEnvironmentLoggedIn :tabs="tabs">
    <slot/>
  </BaseEnvironmentLoggedIn>
</template>


<style lang="scss" scoped>

</style>