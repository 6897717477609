import {baseUri} from "../config/config.ts";
import {AccountStore} from "../stores/accountStore.ts";

export interface FetcherResponse<T> {
    status: number;
    ok: boolean;
    data: T ;
}

export class Fetcher {

    //constructor
    private headers: Headers;
    private isAuthRequest: boolean;

    constructor(isAuthRequest: boolean = false) {
        this.isAuthRequest = isAuthRequest;
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
        if (isAuthRequest) {
            // @ts-ignore
            const token = AccountStore.token
            this.headers.append('Authorization', `Bearer ${token}`);
        }
    }

    async get<T>(path: string): Promise<FetcherResponse<T>> {
        if (this.isAuthRequest) {
            while (!AccountStore.isInit) {
                await new Promise(r => setTimeout(r, 500));
            }

            if (!await AccountStore.loadTokenCheck()) {
                const fake: T = {} as T;
                return {
                    status: 803,
                    ok: false,
                    data: fake
                };
            }
        }

        const response = await fetch(baseUri + path, {
            method: 'GET',
            headers: this.headers
        });

        const data = await response.json();
        return {
            status: response.status,
            ok: response.ok,
            data: data,
        }
    }


    async post<T>(path: string, body: any): Promise<FetcherResponse<T>> {
        if (this.isAuthRequest) {
            while (!AccountStore.isInit) {
                await new Promise(r => setTimeout(r, 500));
            }

            if (!await AccountStore.loadTokenCheck()) {
                const fake: T = {} as T;
                return {
                    status: 803,
                    ok: false,
                    data: fake
                };
            }
        }

        const response = await fetch(baseUri + path, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: this.headers
    })
        ;
        const data = await response.json();
        return {
            status: response.status,
            ok: response.ok,
            data: data
        };
    }

    async put<T>(path: string, body: any): Promise<FetcherResponse<T>> {
        if (this.isAuthRequest) {
            while (!AccountStore.isInit) {
                await new Promise(r => setTimeout(r, 500));
            }

            if (!await AccountStore.loadTokenCheck()) {
                const fake: T = {} as T;
                return {
                    status: 803,
                    ok: false,
                    data: fake
                };
            }
        }
        const response = await fetch(baseUri + path, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: this.headers
        });
        const data = await response.json();
        return {
            status: response.status,
            ok: response.ok,
            data: data
        };
    }

    async delete<T>(path: string): Promise<FetcherResponse<T>> {
        if (this.isAuthRequest) {
            while (!AccountStore.isInit) {
                await new Promise(r => setTimeout(r, 500));
            }

            if (!await AccountStore.loadTokenCheck()) {
                const fake: T = {} as T;
                return {
                    status: 803,
                    ok: false,
                    data: fake
                };
            }
        }

        const response = await fetch(baseUri + path, {
            method: 'DELETE',
            headers: this.headers
        });
        const data = await response.json();
        return {
            status: response.status,
            ok: response.ok,
            data: data
        };
    }

}