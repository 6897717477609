<script setup>
import {computed} from "vue";

const props = defineProps({
  dataName: {
    type: String,
    required: true
  },
  colorScale: {
    type: Array,
    required: true
  },
  hoverValue: {
    type: Number,
    required: true
  },
  hover: {
    type: Boolean,
    required: true
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  smoothScale: {
    type: Boolean,
    required: false,
    default: false
  },
  legendValueFormatter: {
    type: Function,
    required: false,
    default: (value) => {
      return value;
    }
  }
})

const percentage = computed(() => {
  const min = props.min;
  const max = props.max;
  const hoverValue = props.hoverValue;
  const percentage = (hoverValue - min) / (max - min) * 100;
  return percentage;
})

const cssColorGradient = computed(() => {
  const colorScale = props.colorScale;
  //if smoothscale we want smooth gradient otherwise we want a gradient with steps
  if (props.smoothScale) {
    const gradient = colorScale.map((color, index) => {
      const percentage = index / (colorScale.length - 1) * 100;
      return `${color} ${percentage}%`;
    }).join(', ');
    return `background: linear-gradient(to right, ${gradient})`;
  } else {
    const gradient = colorScale.map((color, index) => {
      const percentage = index / (colorScale.length) * 100;
      let nextPercentage = (index + 1) / (colorScale.length) * 100;
      // Zorg ervoor dat de laatste kleur volledig wordt weergegeven
      if (index === colorScale.length) {
        nextPercentage = 100;
      }
      return `${color} ${percentage}%, ${color} ${nextPercentage}%`;
    }).join(', ');

    return `background: linear-gradient(to right, ${gradient})`;
  }

})

//My date min and max is -48990 and -30
//[ -48990, -39198, -29406, -19614, -9822, -30 ]
// i want -50000, -40000, -30000, -20000, -10000, 0
const breaks = computed(() => {
  if(props.max < 10000 && props.min > -10000){
    // Even alternatieve methode hier
    // TODO: netjes maken
    const minRounded = props.min;
    const maxRounded = props.max;

    const step = (maxRounded - minRounded) / 5;
    const breaks = [];
    for (let i = 0; i < 6; i++) {
      let breakValue = minRounded + i * step;
      breakValue = +breakValue.toFixed(2); //shorthand parsefloat met tofixed 2
      breaks.push(props.legendValueFormatter(breakValue));
    }

    return breaks;
  }

  const minRounded = Math.floor(props.min / 10000) * 10000;
  const maxRounded = Math.ceil(props.max / 10000) * 10000;

  const step = (maxRounded - minRounded) / 5;
  const breaks = [];
  for (let i = 0; i < 6; i++) {
    breaks.push(props.legendValueFormatter(minRounded + i * step));
  }

  return breaks;
});

</script>

<template>
<!--  {{breaks}}-->
  <div class="legend-wrapper">
    <span class='fw'>{{dataName}}</span>
    <div class="legend">
    <span class="val">
      {{breaks[0]}}
    </span>
      <div class="bar" :style="cssColorGradient">
        <div v-show="hover" class="pointer" :style="`left: calc(${percentage}% - 0.5rem)`"></div>
        <div class="bar-underlay">
          <template v-for="(breakValue, index) in breaks">
            <div class="bar-underlay-item" :style="`left: calc(${index / 5 * 100}% - 0.5rem)`">
              <div class="bar-underlay-item-line" v-if="smoothScale">&nbsp;</div>
              <span class="bar-underlay-item-val">{{breakValue}}</span>
            </div>
          </template>
        </div>
      </div>

      <span class="val">
      {{breaks[breaks.length - 1]}}
    </span>
    </div>
  </div>

</template>

<style lang="scss" scoped>

</style>