import { defineStore } from 'pinia'

interface MainState {
    loaded: {
        [key: string]: boolean;
    };
    dashboard: any;
    export: {
        showDataLabels: boolean;
    }
}

export const useMainStore = defineStore('main', {
    state: (): MainState => {
        return {
            loaded: {
                geo: false,
                board: false
            },
            dashboard: null,
            export: {
                showDataLabels: false
            }
        }
    },
    getters: {
        isLoaded(state) {
            return Object.values(state.loaded).every(v => v) && state.dashboard !== null    ;
        },
        getDashboard(state): any {
            return state.dashboard;
        },
        dashboardDatapoolLoaded(state): boolean {
            if(null === state.dashboard) return false;
            if(undefined === state.dashboard.options.dataPool || 0 === state.dashboard.options.dataPool.connectors.length) return true; // zijn er wel connectors?
            return (Object.values(state.dashboard.dataPool.waiting).length === 0 && Object.values(state.dashboard.dataPool.connectors).length > 0)
        },
        showDataLabels(state): boolean {
            return state.export.showDataLabels;
        }
    },
    actions: {
        setDashboard(dashboard: any) {
            this.dashboard = dashboard;
        },
        setLoaded(key: string, value: boolean) {
            if (key in this.loaded) {
                this.loaded[key] = value;
            } else {
                console.warn(`Trying to set an unknown loaded state: ${key}`);
            }
        },
        toggleDataLabels() {
            this.export.showDataLabels = !this.export.showDataLabels;
        }
    }
})