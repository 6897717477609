interface PreProcessData {
    lat: number[] | null
    lng: number[] | null,
    pointLocation: string[] | null,
    pointName: string[] | null,
    pointValue: number[] | null
}

interface PostProcessData {
    lat: number,
    lng: number,
    pointLocation: string,
    pointName: string,
    pointValue: string
}

interface GeoJson {
    type: string,
    features: GeoJsonFeature[]
}

interface GeoJsonFeature {
    type: string,
    properties: GeoJsonFeatureProperties,
    geometry: GeoJsonFeatureGeometry
}

interface GeoJsonFeatureProperties {
    name: string,
    value: string,
    location: string
}

interface GeoJsonFeatureGeometry {
    type: string,
    coordinates: number[]
}


/**
 * Converts a datapool to an array of objects with key value pairs
 * [[array all val of row 1],[array all val of row 3],[array all val of row 3]] => [{key1: val1, key2: val2, key3: val3}, {key1: val1, key2: val2, key3: val3}, {key1: val1, key2: val2, key3: val3}]
 *
 * @param input - [[array all val of row 1],[array all val of row 3],[array all val of row 3]]
 * @param filterHeader - Filter on a specific header, "" = no filter
 * @param filterValue - Filter on a specific value, "" = no filter
 */
export const datapoolToObjectKeyValueArray = (input: any, filterHeader = "", filterValue = "" ) => {
    let result = [];
    let keys = Object.keys(input);

    if (input[keys[0]] === undefined) {
        input[keys[0]] = [""];
    }


    for (let i = 0; i < input[keys[0]].length; i++) {
        let newItem: any = {};


        keys.forEach(key => {
            //if key = "value" log input[key][i] to console
            if (key === "value") {
               // console.log("Key: ",input[key][i]);
                newItem[key] = input[key][i];
            }else if (input[key] !== undefined) {
                newItem[key] = input[key][i];
            }
        });

        //if no set filter, push all items
        if (filterHeader === "" || filterValue === "") {
            result.push(newItem);
        }else if (newItem[filterHeader] === filterValue) {
            result.push(newItem);
        }

    }

    return result;
}

/**
 * Converts a datapool to a geoJson object
 * [[array all val of row 1],[array all val of row 3],[array all val of row 3]] => {type: "FeatureCollection", features: [{type: "Feature", properties: {name: "name", value: "value", location: "location"}, geometry: {type: "Point", coordinates: [lng, lat]}}]
 *
 * @param input - [[array all val of row 1],[array all val of row 3],[array all val of row 3]]
 * @param fields - {lat: 'x', lng: 'y', pointLocation: 'gemeentenaam', pointName: 'project_titel', pointValue: 'koopwoningen'}
 */
export const dataPoolToGeoJson = (input: any, fields: any) => {
    let preProcessData: PreProcessData = {
        lat: null,
        lng: null,
        pointLocation: null,
        pointName: null,
        pointValue: null
    };

    //Preprocess data so it is a generic object
    preProcessData.lat = input[fields.lat];
    preProcessData.lng = input[fields.lng];
    preProcessData.pointLocation = input[fields.pointLocation];
    preProcessData.pointName = input[fields.pointName];
    preProcessData.pointValue = input[fields.pointValue];

    const postProcessData: PostProcessData[] = datapoolToObjectKeyValueArray(preProcessData);

    let geoJson: GeoJson = {
        type: "FeatureCollection",
        features: []
    };

    if (postProcessData.length === 0) {
        return geoJson;
    }

    postProcessData.forEach((item) => {

        geoJson.features.push({
            type: "Feature",
            properties: {
                name: item.pointName,
                value: item.pointValue,
                location: item.pointLocation
            },
            geometry: {
                type: "Point",
                coordinates: [item.lat, item.lng]
            },
        });
    });



    return geoJson;
}

//@ts-ignore
export const getBoundsFromGeoJson = (geojson) => {
    if (!geojson || !geojson.features || !geojson.features.length) {
        throw new Error('Invalid GeoJSON object');
    }

    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    //@ts-ignore
    geojson.features.forEach(feature => {
        //@ts-ignore
        feature.geometry.coordinates.forEach(coord => {
            if (feature.geometry.type === 'Point') {
                minLng = Math.min(minLng, coord[0]);
                minLat = Math.min(minLat, coord[1]);
                maxLng = Math.max(maxLng, coord[0]);
                maxLat = Math.max(maxLat, coord[1]);
            } else {
                //@ts-ignore
                coord.forEach(c => {
                    //@ts-ignore
                    c.forEach(point => {
                        minLng = Math.min(minLng, point[0]);
                        minLat = Math.min(minLat, point[1]);
                        maxLng = Math.max(maxLng, point[0]);
                        maxLat = Math.max(maxLat, point[1]);
                    });
                });
            }
        });
    });

    return [[minLng, minLat], [maxLng, maxLat]];
}