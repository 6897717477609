import Dexie from 'dexie';
import {Fetcher} from "./fetcher.ts";

interface GeoData {
    id?: number;
    key: string;
    value: any;
    timestamp: number;
}

export class GeoDataManager extends Dexie {
    public geodata: Dexie.Table<GeoData, number>;
    private geoDataItems: { key: string, url: string }[] = [];

    // zet dexie db klaar
    constructor() {
        super('DataDashboard');
        this.version(1).stores({
            geodata: '++id, key, value, timestamp'
        });

        this.geodata = this.table('geodata');
    }

    // voeg dataset toe om op te halen
    addGeoDataItem(key: string, url: string) {
        // console.info('DexieDB Added: ' + key)

        //check if already exists
        this.geoDataItems.push({ key, url });
    }

    /**
     * Init gemeente geo data
     *
     * @param gemeenteCode Gemeente code
     *
     * @return {boolean} True if added, false if already exists
     */
    async addGeoDataBuurten(gemeenteCode: string) {

        //check if already exists
        this.checkKeyExists(gemeenteCode).then((exists) => {
            if (exists) {
                //Gemeente exist
                return false;
            }
            //key = gemeenteCode
            //value = geojson from api

            const url = '/utils/buurtgeojson?gmcode=' + gemeenteCode;
            this.addGeoDataItem(gemeenteCode, url);

            //load data
            this.loadData(gemeenteCode, url, true);
            return true;
        });
        return false;
    }

    /**
     * Helper function to check if key exists
     *
     * @param key Key to check
     */
    async checkKeyExists(key: string): Promise<boolean> {
        const count = await this.geodata.where('key').equals(key).count();
        return count > 0;
    }

    // laad enkele data
    async loadData(key: string, url: string, api: boolean = false) {

        const count = await this.geodata.where('key').equals(key).count();
        if (count < 1) {

            if (api) {
                const fetcher = new Fetcher(true);
                const res = await fetcher.get(url);
                if (res.ok) {
                    await this.geodata.add({ key, value: res.data, timestamp: Date.now() });
                }
            } else {
                const res = await fetch(url);
                if (res.ok) {
                    await this.geodata.add({ key, value: await res.json(), timestamp: Date.now() });
                }
            }


        }
    }

    // laad alle klaargezette data
    async loadAllData() {
        await Promise.all(
            this.geoDataItems.map(item => this.loadData(item.key, item.url))
        );
    }

    // data ophalen
    async getData(key: string) {
        const data = await this.geodata.where('key').equals(key).first();
        return data?.value;
    }
}