<script setup lang="ts">

</script>

<template>
  <div class="min-h-screen flex items-center justify-center pattern-background">
    <div class="bg-white p-12 rounded-lg shadow-md w-100">
      <div class="text-center">
        <h1 class="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <h2 class="text-2xl font-semibold text-gray-600 mb-4">Pagina niet gevonden</h2>
        <p class="text-gray-500">De pagina die je probeert te bezoeken bestaat niet.</p>
        <router-link to="/" class="text-xitres-darkblue hover:text-xitres-blue hover:underline">Terug naar de
          homepagina
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>