import {defineStore} from 'pinia'
import {GemeenteStore} from "../gemeenteStore.ts";

interface TooltipState {
    tooltips: Record<string, any>;
    activeTooltip: string | null;
    referenceElements: Record<string, Element>;
    stayopen: Boolean;
}


const hasParentWithClass = (element: Element | null, className: string): boolean => {
    if (!element) return false;
    if (element.classList && element.classList.contains(className)) {
        return true;
    } else if (element.parentNode && element.parentNode instanceof Element) {
        return hasParentWithClass(element.parentNode, className);
    } else {
        return false;
    }
};


export const useTooltipStore = defineStore('tooltip', {
    state: (): TooltipState => {
        return {
            tooltips: {},
            activeTooltip: null,
            referenceElements: {},
            stayopen: false
        }
    },
    getters: {
        getActiveTooltip: (state) => {
            return state.activeTooltip ? state.tooltips[state.activeTooltip] : null;
        },
        getReferenceElement: (state) => {
            if (null === state.activeTooltip) return null;
            return state.referenceElements[state.activeTooltip] ? state.referenceElements[state.activeTooltip] : null;
        }
    },
    actions: {
        setTooltips(tooltips: Record<string, any>) {
            this.tooltips = tooltips;
        },
        setActiveTooltip(key: string) {
            this.activeTooltip = key;
        },
        unsetActiveTooltip() {
            this.activeTooltip = null;
        },

        // functie die dom klaarzet in titels
        setupTooltipButtons(dashboard: any) {
            dashboard.mountedComponents.forEach((component: any) => {
                if (Object.keys(this.tooltips).includes(component.cell.id)) {
                    const title = component.cell.container.querySelector('.highcharts-dashboards-component-title');
                    if (title) {
                        this.setupTooltipDom(title, component.cell.id)
                    } else {
                        console.warn('no element found for tooltip: ' + component.cell.id)
                    }
                } else {
                    if (!component.cell.id.includes('filter')) {
                        console.warn('no tooltip found for element: ' + component.cell.id)
                    }
                }
            })

            // Voeg een globale click event listener toe
            document.addEventListener('click', (event: MouseEvent) => {
                const target = event.target as Element;

                if (this.activeTooltip && this.stayopen && !hasParentWithClass(target, 'tooltip-container')) {
                    this.stayopen = false
                    this.unsetActiveTooltip()
                }
            });
        },

        async setupTooltipDom(titleContainer: Element, id: string) {
            if (Object.keys(this.tooltips).includes(id)) {
                const span = document.createElement('span');
                if (!titleContainer.textContent) return;

                if (titleContainer.textContent.includes('%gemeente%')) {
                    titleContainer.textContent = titleContainer.textContent.replace('%gemeente%', await GemeenteStore.getGemeenteNaam());
                }

                span.textContent = titleContainer.textContent;

                const button = document.createElement('button');
                button.setAttribute('type', 'button');
                button.classList.add('tooltip-button');
                button.classList.add('print:hidden');
                button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'

                titleContainer.innerHTML = '';
                titleContainer.appendChild(span);
                titleContainer.appendChild(button);

                //titleContainer.classList.add('flex', 'justify-between', 'items-center')

                button.addEventListener('mouseover', () => {
                    if (id !== this.activeTooltip) {
                        this.stayopen = false
                    }
                    this.setActiveTooltip(id);
                });

                button.addEventListener('mouseleave', () => {
                    if (!this.stayopen) {
                        this.unsetActiveTooltip()
                    }
                });

                button.addEventListener('click', (event) => {
                    this.stayopen = true
                    event.stopPropagation();
                    this.setActiveTooltip(id);
                });

                this.referenceElements[id] = button;
            }
        }

    }
})
