const component = {
    type: 'Highcharts',
    cell: 'gm-vn-werkloosheid',
    connector: {
        id: 'werkloosheid_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        gemeente: 'gemeente',
        nederland: 'nederland',
        datum: 'x'
    },
    title: {
        useHTML: true,
        text: 'Werkloosheid (seizoens gecorrigeerd) - Nederland en %gemeente%'
    },
    chartOptions: {
        chart: {
            type: 'line',
        },
        plotOptions: {
            series: {
                pointWidth: 4,
                borderWidth: 0,
                pointPadding: 0,
            },
            column: {
                stacking: 'normal',
            }
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            min: 0,
            labels: {
                formatter: function (): string {
                    // @ts-ignore
                    return this.axis.defaultLabelFormatter.call(this) + '%';
                }
            }
        },
        tooltip: {
            shared: true,
            valueSuffix: '%',
        }
    }
};

export default component;