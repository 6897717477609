const component = {
    cell: 'wm-vraag-en-aanbod',
    connector: {
        id: 'vraag_en_aanbod_pool'
    },
    columnAssignment: {
        'datum': 'x',
        'verwachte nieuwbouwproductie': 'y',
        'woningtekort': 'y',
        'groei woningvoorraad': 'y',
        'groei huishoudens': 'y',
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Woningen ontwikkeling vraag en aanbod'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime'
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true,
        }
    }
};

export default component;