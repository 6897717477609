const component = {
    cell: 'gm-vn-opleiding-naar-buurt-kaart',
    type: 'MapChoropleth',
    connector: {
        id: 'opleiding_pool'
    },
    mapOptions: {
        title: 'Opleidingsniveau naar buurt',
        dataName: 'Percentage',
        matchSettings: {
            poolKey: "buurtcode",
            topoKey: "buurtcode",
            valueRow: "value", // The datapool column that contains the values (needed for the legend and min/max values)
            locationRow: "buurtnaam" // The datapool column that contains the location names
        },
        dexieKey: '%gmcode%',
        colorScale: ['#89c7cf', '#c8e3e7', '#e7c8b9', '#e4bca9', '#e7b096', '#e9a383', '#ec9670', '#ef895b'],
        showLegend: true,
        smoothLegend: true,
        legendFormatter: (value: number) => {
            return value.toFixed(0) + '%';
        },
        mapZoom: 5.7, //Optioneel maar handig
        fitBounds: true, //Optioneel maar handig
        showMap: true, //Toont de kaart zelf
    }
};

export default component;
