const component = {
    cell: 'gm-nm-woningvoorraad-filter',
    className: 'test2',
    type: 'SelectFilter',
    label: 'Woningtype:',
    filterOptions: {
        filters: [
            {key: 'Totaal woningen', label: 'Totaal woningen'},
            {key: 'Eengezinswoningen', label: 'Eengezinswoningen'},
            {key: 'Percentage meergezinswoning', label: 'Meergezinswoning'}
        ],
        dataPools: ['woningvoorraad_pool'],
        column: 'woningtype'
    }
};

export default component;