import {defineStore} from 'pinia'
//@ts-ignore
import {GemeenteStore} from "../gemeenteStore.ts";
//@ts-ignore
import {Fetcher} from "../../utils/fetcher.ts";

interface SubtitleState {
    activePage: string | null;
    subtitles: Record<string, any>;
    referenceElements: Record<string, Element>;
}


const hasParentWithClass = (element: Element | null, className: string): boolean => {
    if (!element) return false;
    if (element.classList && element.classList.contains(className)) {
        return true;
    } else if (element.parentNode && element.parentNode instanceof Element) {
        return hasParentWithClass(element.parentNode, className);
    } else {
        return false;
    }
};


export const useSubtitleStore = defineStore('subtitle', {
    state: (): SubtitleState => {
        return {
            activePage: null,
            subtitles: {},
            referenceElements: {}
        }
    },
    actions: {
        setSubtitles(page: string) {
            this.activePage = page;
            if (page !== undefined && !this.subtitles.hasOwnProperty(page)) {
                // Get subtitles for specific page
                // Get data date periods
                const fetcher = new Fetcher(true);
                fetcher.get(`/utils/data-perioden?page=${page}`).then((res: any) => {
                    if (res.ok) {
                        this.subtitles[page] = {};
                        //@ts-ignore
                        res.data.forEach(subtitle => {
                            this.subtitles[page][subtitle.grafiek] = subtitle.periode ?? '';
                        });
                    }
                });
            }
        },
        // functie die dom klaarzet in titels
        setupSubtitles(dashboard: any) {
            //@ts-ignore
            const pageSubtitles = this.subtitles[this.activePage];
            dashboard.mountedComponents.forEach((component: any) => {
                if (Object.keys(pageSubtitles).includes(component.cell.id)) {
                    const title = component.cell.container.querySelector('.highcharts-dashboards-component-title');
                    if (title) {
                        this.setupSubtitleDom(title, component.cell.id)
                    } else {
                        console.warn('no element found for subtitle: ' + component.cell.id)
                    }
                }
            })
        },

        async setupSubtitleDom(titleContainer: Element, id: string) {
            //@ts-ignore
            const pageSubtitles = this.subtitles[this.activePage];
            if (Object.keys(pageSubtitles).includes(id)) {
                if (!titleContainer.textContent) return;

                const subtitleTextElement = document.createElement('span');
                subtitleTextElement.classList.add('subtitle');
                subtitleTextElement.textContent = pageSubtitles[id];
                titleContainer.appendChild(subtitleTextElement);
                this.referenceElements[id] = subtitleTextElement;
            }
        }
    }
})
