const component = {
    cell: 'gm-nm-woz-waarde-buurt-filter',
    className: 'test2',
    type: 'MultiSelectFilter',
    label: 'Buurt(en):',
    filterOptions: {
        filters: [
            {key: 'NONE', label: 'Selecteer een buurt'},
        ],
        enableApi: true,
        apiOptions: {
            url: '/utils/buurten?gmcode=%gmcode%',
            key: 'buurtcode',
            label: 'buurtnaam'
        },
        dataPools: ['woz_naar_buurten_pool'],
        column: 'buurtcode'
    }
};

export default component;