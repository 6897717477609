<script setup lang="ts">
import {computed, onMounted} from "vue";

// Vue Components
import Tooltip from "../Tooltip.vue";
import Loader from '../Loader.vue'

// Vue Store
//@ts-ignore
import {useMainStore} from '/src/stores/pinia/mainStore'

const store = useMainStore()
//@ts-ignore
import {useTooltipStore} from '/src/stores/pinia/tooltipStore'
//@ts-ignore
import {useSubtitleStore} from "../../stores/pinia/subtitleStore.ts";

const tooltipStore = useTooltipStore()
const subtitleStore = useSubtitleStore()


// alle highcharts imports
import * as Dashboards from '@highcharts/dashboards';
import * as Highcharts from 'highcharts';
import HighchartsPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin';
import DataGrid from '@highcharts/dashboards/es-modules/DataGrid/DataGrid';
import DataGridPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';

// utils
import {DateHelpers} from '../../utils/dateHelpers.ts'

/**
 * Defines the props for a component.
 *
 * @param {Object} props - The props object.
 * @param {String} props.pageId - The page ID.
 * @param {Object} props.dashboardOptions - The dashboard options object.
 * @param {String} props.dashboardOptions.type - The type of dashboard options.
 * @param {Boolean} props.dashboardOptions.required - Whether the dashboard options are required.
 * @returns {Object} The defined props object.
 */
const props = defineProps({
  pageId: {
    type: String,
    required: true
  },
  dashboardOptions: {
    type: Object,
    required: true
  }
})


/**
 * Recursively merges two objects.
 *
 * @param {Record<string, any>} obj1 - The first object to merge.
 * @param {Record<string, any>} obj2 - The second object to merge.
 * @returns {Record<string, any>} - The merged object.
 */
function deepMerge(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> {
  return Object.entries(obj2).reduce((output, [key, value]) => {
    //We use a ternary operator to check if the value is an object and not null or an array
    output[key] = (typeof value === 'object' && value !== null && !Array.isArray(value) && obj1[key])
        ? deepMerge(obj1[key], value)
        : value;
    return output;
  }, {...obj1});
}

/**
 * Merge the provided options with the default chart options and return the result.
 *
 * @param {Object} options - The options to merge with default chart options.
 * @return {Object} - The merged chart options.
 */
const setOptions = (options: Object) => {

  return deepMerge({
    chartOptions: {
      accessibility: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
        // @ts-ignore
        filename: "export-" + options.title.text.replace(/ /g, "-").toLowerCase(),
        //Only Img and PDF
        menuItemDefinitions: {
          downloadPNG: {
            text: 'Download PNG'
          },
          downloadPDF: {
            text: 'Download PDF'
          },
          viewFullscreen: {
            text: 'Bekijk in volledig scherm',
          },
        },
        buttons: {
          contextButton: {
            symbol: 'download',
            menuItems: [
              'viewFullscreen',
              'separator',
              'toggleLabels',
              'downloadPNG',
              'downloadPDF'
            ]
          }
        },
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: store.showDataLabels,
              }
            }
          },
          title: {
            // @ts-ignore
            text: options.title.text
          },
          chart: {
            width: 1000,
            height: 700,
          },
          xAxis: {
            width: 900,
            title: true,
          },
        }
      },
      chart: {
        spacing: 24
      },
      title: false,

      // @ts-ignore
      legend: options.chartConstructor === "mapChart" ? {
        verticalAlign: 'bottom',
      } : {
        floating: false,
        enabled: true,
        align: 'left',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
          fontWeight: 'light'
        }
      },
      yAxis: {
        title: false,
      },
      editing: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        }
      },

    }
  }, options);
}

Highcharts.SVGRenderer.prototype.symbols.download = function (x: number, y: number, w: number, h: number) {
  const path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9
  ];
  return path;
};


// Add (W)eek and (Q)uarter to highcharts label formatter
// https://api.highcharts.com/class-reference/Highcharts#.dateFormats
Highcharts.dateFormats.W = DateHelpers.getWeekFromTimestamp;
Highcharts.dateFormats.Q = DateHelpers.getQuarterFromTimestamp;

// Override language
Highcharts.setOptions({
  lang: {
    months: [
      'Januari', 'Februari', 'Maart', 'April',
      'Mei', 'Juni', 'Juli', 'Augustus',
      'September', 'Oktober', 'November', 'December'
    ],
    weekdays: [
      'Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag',
      'Vrijdag', 'Zaterdag'
    ],
    shortMonths: [
      'Jan', 'Feb', 'Mrt', 'Apr',
      'Mei', 'Jun', 'Jul', 'Aug',
      'Sep', 'Okt', 'Nov', 'Dec'
    ]
  }
});

HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsMore(Highcharts);

// Connect Highcharts and DataGrid to the HighchartsPlugin and DataGridPlugin respectively
HighchartsPlugin.custom.connectHighcharts(Highcharts);
DataGridPlugin.custom.connectDataGrid(DataGrid);

// Add the DataGridPlugin and HighchartsPlugin to the Dashboards PluginHandler
//@ts-ignore
Dashboards.PluginHandler.addPlugin(DataGridPlugin);
//@ts-ignore
Dashboards.PluginHandler.addPlugin(HighchartsPlugin);

// Prepare options
const dashboardOptions = {
  tooltips: undefined,
  ...props.dashboardOptions,
  editMode: {
    enabled: false
  }
}

//@ts-ignore
dashboardOptions.components.forEach((component, index, array) => {
  if (component.type === 'Highcharts') {
    array[index] = setOptions(component);
  }
});

const showBoard = computed(() => {
  return store.isLoaded
})

onMounted(() => {
  // Render the dashboard to the specified element
  const renderTo = 'dashboard' + props.pageId;
  // @ts-ignore
  store.setDashboard(Dashboards.board(renderTo, dashboardOptions, true).then(board => {
    // Flow:
    // 1) Datapol loading
    // 2) Mounted component(s) loading
    // 3) Maps component(s) loading
    // 4) Build tooltips
    const dashboardDatapoolLoadingInterval = setInterval(async () => {
      // @ts-ignore
      if ((board.dataPool === undefined) || (Object.keys(board.dataPool.waiting).length)) {
        console.log('dashboardDatapoolLoadingInterval not ready - datapool waiting');
        return; // not ready (yet)
      }
      clearInterval(dashboardDatapoolLoadingInterval) // ready
      window.dispatchEvent(new CustomEvent('dashboardDatapoolReady'))
      store.setLoaded('board', true)

      // Second layer: loading for mounted components
      const dashboardMountedComponentLoadingInterval = setInterval(async () => {
        if (board.dataPool.options.connectors.length === 0 || board.mountedComponents.length === 0) {
          console.log('dashboardLoadingInterval not ready - mounted components 0');
          return;
        }
        clearInterval(dashboardMountedComponentLoadingInterval) // ready

        // Third layer: loading for maps. This is done seperate since they're heavy
        const dashboardMapsLoadingInterval = setInterval(async () => {
          board.mountedComponents.forEach((component: any) => {
            if (component.component.chartType === 'map') {
              if (component.component.isLoaded === false) {
                return; // not ready yet
              }
            }
          });
          clearInterval(dashboardMapsLoadingInterval) // ready
          tooltipStore.setupTooltipButtons(board)     // Setup tooltips
          subtitleStore.setupSubtitles(board)     // Setup subtitles

        }, 100);
      }, 100);
    }, 100)
  }))
})

const tooltipProps = computed(() => {
  if (tooltipStore.getActiveTooltip) {
    const tooltipData = tooltipStore.getActiveTooltip;
    return {
      referenceElement: tooltipStore.getReferenceElement,
      data: tooltipData,
    };
  }
  return null;
});


</script>

<template>
  <div v-show="showBoard" class="highcharts-light" :id="'dashboard' + pageId"></div>

  <Loader v-if="!showBoard"></Loader>

  <Tooltip
      v-if="tooltipProps"
      :referenceElement="tooltipProps.referenceElement"
      :data="tooltipProps.data"
  />
</template>

<style lang="scss" scoped>

</style>