const component = {
    type: 'Highcharts',
    cell: 'gm-vn-inschrijvers-per-woningtype',
    connector: {
        id: 'woning_voorkeur_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        categorie: 'x',
        percentage: 'y'
    },
    title: {
        useHTML: true,
        text: 'Inschrijvers per woningtype'
    },
    chartOptions: {
        chart: {
            type: 'bar',
        },
        yAxis: {
            labels: {
                format: '{text}%'
            },
        },
        xAxis: {
            type: 'category'
        },
        tooltip: {
            valueSuffix: '%',
        },
    }
};

export default component;