<script setup lang="ts">
import DashboardEnvironment from "../components/layouts/DashboardEnvironment.vue";
import HighchartsDashboard from "../components/highcharts/HighchartsDashboard.vue";
import * as Charts from "../charts/nm/charts.ts";
import nmDatapools from "../charts/nm/nm.datapools.ts";
import nmGui from "../charts/nm/nm.gui.ts";


const dashboardConfig = {
  dataPool: {
    connectors: nmDatapools,
  },
  gui: nmGui,
  components: Charts.importAll()
}

//Tooltips?
import Tooltips from '../charts/nm/nm.tooltips.ts'
// store
//@ts-ignore
import {useTooltipStore} from '/src/stores/pinia/tooltipStore'
//@ts-ignore
import {useSubtitleStore} from '/src/stores/pinia/subtitleStore.ts';

const tooltipStore = useTooltipStore()
tooltipStore.setTooltips(Tooltips)

// Subtitle Store init
const subtitleStore = useSubtitleStore()
subtitleStore.setSubtitles('nieuwbouwmarkt')

</script>

<template>
  <DashboardEnvironment>
    <HighchartsDashboard page-id="nieuwbouwmarkt" :dashboard-options="dashboardConfig"/>
  </DashboardEnvironment>
</template>

<style scoped>

</style>