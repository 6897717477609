const component = {
    cell: 'wm-gemiddelde-verkoopprijs',
    connector: {
        id: 'gemiddelde_verkoopprijs_pool'
    },
    columnAssignment: {
        eengezinswoningen: 'eengezinswoningen',
        appartement: 'appartement',
        //totaal: 'totaal',
        datum: 'x'
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Gemiddelde verkoopprijs'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        yAxis: {
            labels: {
                formatter: function (): string {
                    // @ts-ignore
                    return '€ ' + this.axis.defaultLabelFormatter.call(this);
                }
            }
        },
        xAxis: {
            type: 'datetime',
            //tickInterval: 7 * 24 * 36e5, // one week
            labels: {
                format: '{value:%Y Q%Q}',
                align: 'right',
                rotation: -30
            }
        },
        tooltip: {
            xDateFormat: 'Q%Q %Y',
            shared: true,
            valuePrefix: '€ ',
        }
    }
};

export default component;