import {Fetcher, FetcherResponse} from "../utils/fetcher.ts";

export class AccountStore {
    private static _token: string = localStorage.getItem('token') || '';
    private static _tokenValidUntil: Date | null = localStorage.getItem('tokenValidUntil') ? new Date(localStorage.getItem('tokenValidUntil') || '') : null;
    private static _username: string = localStorage.getItem('username') ? JSON.parse(localStorage.getItem('username') || '') : '';
    private static _init: boolean = false;

    static get isTokenSet(): boolean {
        return AccountStore._token !== '';
    }

    static get isTokenValid(): boolean {
        if (!AccountStore.isTokenSet) {
            return false;
        }

        if (!AccountStore._tokenValidUntil) {
            return false;
        }
        return AccountStore._tokenValidUntil > new Date();
    }

    static get isInit(): boolean {
        return AccountStore._init;
    }

    static get isLoggedIn(): boolean {
        return AccountStore.isTokenSet && AccountStore.isTokenValid;
    }

    static async loadTokenCheck(): Promise<boolean> {
        //is Page login?
        if (window.location.pathname.includes('/auth')) {
            return false;
        }

        return AccountStore.isTokenValid;
    }

    static async initTokenCheck(): Promise<void> {

        if (window.location.pathname.includes('/auth')) {
            this._init = true;
            return;
        }

        //online check
        //is toke a valid token?
        const fetcher = new Fetcher(false);
        const res: FetcherResponse<{msg: string}> = await fetcher.post('/auth/validate', {
            token: AccountStore.token
        });

        //Is token valid?
        if (!res.ok) {
            AccountStore.logout(true);
        }

        this._init = true;
    }

    static get token(): string {
        if (!AccountStore.isTokenSet || !AccountStore.isTokenValid) {
            if (window.location.pathname === '/auth/login') {
                return '';
            }

            AccountStore.logout(true);
            return '';
        }
        return AccountStore._token;
    }

    static set token(value: string) {
        AccountStore._token = value;
        //set 1 hour expiration
        AccountStore._tokenValidUntil = new Date();
        AccountStore._tokenValidUntil.setHours(AccountStore._tokenValidUntil.getHours() + 1);

        //store token in local storage
        localStorage.setItem('token', value);
        localStorage.setItem('tokenValidUntil', AccountStore._tokenValidUntil.toISOString());

        window.dispatchEvent(new CustomEvent('tokenChanged', {detail: {token: value}}));
    }

    static get username(): string {
        return AccountStore._username;
    }

    static set username(value: string) {
        AccountStore._username = value;
        localStorage.setItem('username', JSON.stringify(value));

        window.dispatchEvent(new CustomEvent('usernameChanged', {detail: {username: value}}));
    }

    static async getAccessLevel(): Promise<number> {
        const fetcher = new Fetcher(true);
        const res: FetcherResponse<{ accessLevel: string; }> = await fetcher.get('/auth/access');
        if (res.ok && res.data) {
            //check if res.data is object
            return parseInt(res.data.accessLevel);
        } else {
            AccountStore.logout(true);
            return 0;
        }
    }

    static logout(isExpired: boolean = false): void {
        AccountStore._token = '';
        AccountStore._tokenValidUntil = null;
        AccountStore._username = '';
        localStorage.removeItem('token');
        localStorage.removeItem('tokenValidUntil');
        localStorage.removeItem('username');
        let param = "";
        if (isExpired) {
            param = "?expired=true";
        }
        window.location.href = "/auth/login" + param;
        window.dispatchEvent(new CustomEvent('tokenChanged', {detail: {token: ''}}));
    }
}