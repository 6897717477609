const component = {
    cell: 'gm-nm-woningbezit-grafiek',
    connector: {
        id: 'woningbezit_categorie_pool'
    },
    columnAssignment: {
        'eigendom overige verhuurders': 'y',
        'eigendom onbekend': 'y',
        'koopwoningen': 'y',
        'huurwoning in bezit woningcorporatie': 'y',
        'buurtnaam': 'x'
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Woningbezit - 2023'
    },
    chartOptions: {
        chart: {
            type: 'column'
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            min: 0,
            max: 100,
            stackLabels: {
                enabled: false
            }
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },

    }
};

export default component;