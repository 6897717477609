<script setup lang="ts">
// Vue Components
import DashboardEnvironment from "../components/layouts/DashboardEnvironment.vue";
import HighchartsDashboard from "../components/highcharts/HighchartsDashboard.vue";

// Vue Stores
//@ts-ignore
import { useMainStore } from '/src/stores/pinia/mainStore';
//@ts-ignore
import { useTooltipStore } from '/src/stores/pinia/tooltipStore.ts';
//@ts-ignore
import { useSubtitleStore } from '/src/stores/pinia/subtitleStore.ts';

// Charts related
import * as Charts from '../charts/wm/charts';
import wmDatapools from "../charts/wm/wm.datapools.ts";
import wmGui from "../charts/wm/wm.gui.ts";
import Tooltips from '../charts/wm/wm.tooltips.ts';

// Configurations
const dashboardConfig = {
  dataPool: {
    connectors: wmDatapools,
  },
  gui: wmGui,
  components: Charts.importAll(),
}

// Tooltip Store Initialization
const tooltipStore = useTooltipStore()
tooltipStore.setTooltips(Tooltips)

// Subtitle Store init
const subtitleStore = useSubtitleStore()
subtitleStore.setSubtitles('woningmarkt')

</script>


<template>
    <DashboardEnvironment>
        <HighchartsDashboard page-id="woningmarkt" :dashboard-options="dashboardConfig"/>
    </DashboardEnvironment>
</template>

<style scoped>

</style>