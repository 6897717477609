const component = {
    cell: 'gm-vn-verdeling-opleidingsniveau',
    connector: {
        id: 'opleiding_categorie_pool'
    },
    columnAssignment: {
        buurtnaam: 'x',
        'opleidingsniveau hoog': 'y',
        'opleidingsniveau laag': 'y',
        'opleidingsniveau middelbaar': 'y',
    },
    type: 'Highcharts',
    title: {
        useHTML: true,
        text: 'Verdeling opleidingsniveau'
    },
    chartOptions: {
        chart: {
            type: 'column'
        },
        xAxis: {
            type: 'category',
            accessibility: {
                description: 'Jaar'
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            stackLabels: {
                enabled: false
            }
        },
        tooltip: {
            shared: true,
            valueSuffix: '%',
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },

    }
};

export default component;