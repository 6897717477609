const component = {
    cell: 'ec-quickfact-2',
    type: 'Quickfact',
    connector: {
        id: 'quickfacts_2_pool',
    },
    className: 'highcharts-dashboards-component quickfact',
    tableOptions: {
        title: 'Werkloosheid',
        th: 'datum',
        td: 'waarde',
        reverse: false,
        keyFormatter: (k: string): string => {
            return new Date(k).toLocaleDateString('nl-NL', {month: 'short', year: 'numeric'}).replace(/^./, str => str.toUpperCase());
        }
    }
};

export default component;