import {Parsers} from './../../utils/parsers.js'
import {baseUri} from "../../config/config.ts";

export default [
    {
        type: 'JSONAuth',
        id: 'quickfacts_1_pool',
        options: {
            dataUrl: (baseUri + '/charts/ec/quickfacts?fact=1')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_2_pool',
        options: {
            dataUrl: (baseUri + '/charts/ec/quickfacts?fact=2')
        }
    },
    {
        type: 'JSONAuth',
        id: 'quickfacts_3_pool',
        options: {
            dataUrl: (baseUri + '/charts/ec/quickfacts?fact=3')
        }
    }, {
        type: 'JSONAuth',
        id: 'bbp_werkloosheid_pool',
        options: {
            firstRowAsNames: true,
            dataUrl: baseUri + '/charts/ec/bbp-werkloosheid',
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    }, {
        type: 'JSONAuth',
        id: 'consumenten_woningmarkt_vertrouwen_pool',
        options: {
            firstRowAsNames: true,
            dataUrl: baseUri + '/charts/ec/consumenten-woningmarkt-vertrouwen',
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                return dataset;
            }
        }
    }, {
        type: 'JSONAuth',
        id: 'hypotheekrente_staatslening_pool',
        options: {
            firstRowAsNames: true,
            dataUrl: baseUri + '/charts/ec/hypotheekrente-staatslening',
            beforeParse: function (dataset: any) {
                dataset = Parsers.dateParser(dataset);
                dataset[0] = Parsers.nameParser(dataset[0]);
                return dataset;
            }
        }
    }
];