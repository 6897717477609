const component = {
    type: 'Highcharts',
    cell: 'ec-consumenten-woningmarkt_vertrouwen',
    connector: {
        id: 'consumenten_woningmarkt_vertrouwen_pool'
    },
    sync: {
        crossfilter: true
    },
    columnAssignment: {
        'woningmarktvertrouwen': 'y',
        'consumentenvertrouwen': 'y',
        datum: 'x'
    },
    title: {
        text: 'Consumentenvertrouwen & Woningmarktvertrouwen'
    },
    chartOptions: {
        chart: {
            type: 'line'
        },
        xAxis: {
            type: 'datetime'
        },
        tooltip: {
            shared: true
        }
    }
};

export default component;